<template>
    <div class="page-wrap">
        <div class="rate-info" v-if="step==1">
            
            
            <div class="block-item">
                <div class="title">
                    <span>商品信息</span>
                </div>
                <div class="order-product-list">
                    <template v-for="item in info.products" >
                        <div class="order-product-item" :key="item.id">
                            <img :src="$store.state.assetUrl + item.img" alt="">
                            <div class="info">
                                <div class="name text-ellipsis">{{item.name}}</div>
                                <div class="spec-desc">{{item.spec_desc}}</div>
                                <div class="price">
                                    <div class="text-left"><small>{{item.currency.code}}</small>{{item.currency.amount}} </div>
                                    <div class="text-right">x{{item.count}}</div>
                                </div>
                            </div>   
                        </div>

                        <div v-if="item.gift_products.length > 0" :key="item.id + 'gift'" class="order-product-combos-list">
                            <div class="order-product-item combos" v-for="gift in item.gift_products" :key="gift.id">
                                <img :src="$store.state.assetUrl+gift.img" alt="">
                                <div class="info">
                                    <div class="name text-ellipsis"><md-tag  shape="circle"  fill-color="#37e492" font-color="#37e492">赠品</md-tag> {{gift.name}}</div>
                                    <div class="spec-desc">{{gift.spec_desc}} x1</div>
                                </div>
                            </div>
                        </div>

                        <div v-if="item.combo_products.length > 0" :key="item.id + 'combo'" class="order-product-combos-list">
                            <div class="order-product-item combos" v-for="combo in item.combo_products" :key="combo.id">
                                <img :src="$store.state.assetUrl+combo.img" alt="">
                                <div class="info">
                                    <div class="name text-ellipsis"><md-tag  shape="circle"  fill-color="#fff7e6" font-color="#fa8c16">组合</md-tag> {{combo.name}}</div>
                                    <div class="spec-desc">{{combo.spec_desc}}</div>
                                    <div class="price">
                                        <div class="text-left"><small>{{combo.currency.code}}</small>{{combo.currency.amount}}</div>
                                        <div class="text-right">x1</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="block-item">
                <div class="bill-box small">
                    <div class="item">
                        <div class="label">订单编号</div>
                        <div class="value">{{info.order_sn}}</div>
                    </div>
                    <div class="item" v-for="item in info.subjects" :key="item.id">
                        <div class="label">{{item.desc}}</div>
                        <div class="value">{{item.type == 'decr' ? '-':''}} {{item.currency.code}} {{item.currency.amount}}</div>
                    </div>
                    <div class="item">
                        <div class="label">订单总价</div>
                        <div class="value color-danger">{{info.total_currency.code}} {{info.total_currency.amount}}</div>
                    </div>
                </div>
            </div>
            <div class="block-item">
                <md-field>
                    <md-cell-item title="评价">
                        <div slot="right" class="rate-box">
                            <span v-for="i in 5" :key="i" :class="{active:i <= form.rate}" @click="setRate(i)">
                                <svg viewBox="64 64 896 896" data-icon="star" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z"></path></svg>
                            </span>
                        </div>
                    </md-cell-item>
                  
                    <md-textarea-item
                        :autosize="true"
                        v-model="form.content"
                        :max-length="150"
                        :max-height="150"
                        placeholder="请输入评价内容"
                    >
                        <template slot="footer">
                        <p class="text-right">
                            <span>{{ form.content.length }}/150</span>
                        </p>
                        </template>
                    </md-textarea-item>
                </md-field>
            </div>

            <div class="container"><md-button class="md" round type="primary" @click="rateOrder" :disabled="disabled">提交评价</md-button></div>
        </div>
        <div class="success-box" v-if="step == 2">
            <div class="desc-icon">
                <md-icon name="success"></md-icon>
            </div>
            <div class="desc">
                感谢您的评价，祝您购物愉快！
            </div>
            <div class="btns">
                <md-button type="default" round class="md" @click="$helper.goPage('/search', false, true)">去购物</md-button>
                <md-button type="primary" round class="md" @click="$router.back()">返回订单</md-button>
            </div>
        </div>
    </div>
</template>

<script>
let self;
export default {
    name: "orderComment",
    components: {},
    data() {
        return {
           info:{
               total_currency:{}
           },
           order_sn:'',
           step:1,
           form:{
               content:'',
               rate:5
           },
           disabled:false,
        };
    },
    mounted(){
        self = this;
        document.title="订单评价";
        this.$store.dispatch('hideBottomBar');
        this.order_sn = this.$route.params.order_sn
        this.getOrder();
    },
    beforeDestroy(){
        
    },
    methods:{
        getOrder(){
            this.$http.get('user/getOrder?order_sn='+this.order_sn).then((res)=>{
                self.info = res.data;
                if(self.info.status != 2 || self.info.is_comment == 1){
                    self.$toast.failed('当前订单不能评价');
                    self.disabled = true;
                }
                
            });
        },
        rateOrder(){
            this.form.order_id = self.info.id;
            this.form.order_sn = self.info.order_sn;
            this.$http.post('user/rateOrder',this.form).then(()=>{
                self.step = 2;
            });
        },
        setRate(rate){
            this.form.rate = rate;
        }
    }
};
</script>

<style scoped lang='less' >
@import "../assets/reset.less";

.page-wrap{
    padding-bottom: 70px;
    background: rgba(0, 0, 0, 0.01);
}
.status{
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    padding: 1rem;
}
.block-item {
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.04), 0 5px 10px rgba(0, 0, 0, 0.02);
  margin: 0.7rem;
  padding: 1rem;
  background: #fff;
}
.block-item .title {
  position: relative;
  margin-bottom: 1rem;
}

.block-item .title > span:first-child {
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 14px;
}
.block-item .title .more-view {
  position: absolute;
  right: -4px;
  top: 0;
  font-size: 12px;
  color: #999;
  display: flex;
  line-height: 20px;
}
.block-item .title .more-view .md-icon {
  font-size: 14px;
  line-height: 20px;
}
.account .title{
    margin-bottom: 0.7rem;
}
.account .name{
    font-weight: 500;
    color: #111;
}
.account .email{
    font-size: 13px;
    color: rgba(0, 0, 0, 0.8);
}
.order-product-item{
    display: flex;
    padding-top: 1rem;
}
.order-product-item:first-child{
    padding-top:0;
}
.order-product-item img{
    height: 50px;
    width: 50px;
}
.order-product-item .info{
    padding-left: 0.5rem;
    font-size: 12px;
    width: calc(100% - 50px);
}
.order-product-item .spec-desc{
    color: rgba(0, 0, 0, 0.4);
}
.order-product-item .price{
    overflow: hidden;
    font-size: 12px;
    display: flex;
    font-weight: 500;
}
.order-product-item .price .text-left,.order-product-item .price .text-right{
    flex: 1;
}
.order-product-combos-list{
    background: rgba(0, 0, 0, 0.01);
    margin-top: 10px;
}
.order-product-item.combos{    
    padding: 10px;
    border-radius: 4px;
}

.order-product-item.combos img{
    height: 40px;
    width: 40px;
}
.order-product-item .name{
    font-size: 14px;
}

.order-product-item.combos .info{
    padding-left: 0.5rem;
    font-size: 12px;
    width: calc(100% - 40px);
}

.bottom-box{
    padding: 1rem 0.7rem;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}

.success-box,.expire-box{
    text-align: center;
    padding: 3rem;
}
.success-box .desc-icon{
    color: @success-color;
    margin-bottom: 0.5rem;
}
.success-box .desc-icon .md-icon, .expire-box .desc-icon .md-icon{
    font-size: 64px;
}
.success-box  .btns, .expire-box  .btns{
    display: flex;
    margin-top: 3rem;
}
.success-box  .btns .md-button, .expire-box  .btns .md-button{
    flex: 1;
}
.success-box  .btns .md-button:first-child,.expire-box  .btns .md-button:first-child{
    margin-right: 0.5rem;
}
.md-field{
    padding: 0;
}
.rate-box span{
    font-size: 22px;
    margin-left: 5px;
    color: #ccc;
    transition: all 0.2s;
}
.rate-box span.active{
    color: @primary-color;
}
</style>
