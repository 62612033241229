<template>
    <div class="page-wrap">
        <div class="stat-box">
            <div class="item">
                <div class="value">{{doingList.length}}<small>期</small></div>
                <div class="label">进行中</div>
            </div>
            <div class="item">
                <div class="value">{{waitList.length}}<small>期</small></div>
                <div class="label">未开始</div>
            </div>
            <div class="item">
                <div class="value">{{endList.length}}<small>期</small></div>
                <div class="label">已结束</div>
            </div>
        </div>
        <div class="cates-box">
            <a class="item" :class="{active:1 == cate}" @click="selectCate(1)">拍卖进行中</a>
            <a class="item" :class="{active:2 == cate}" @click="selectCate(2)">即将开始</a>
            <a class="item" :class="{active:3 == cate}" @click="selectCate(3)">已结束</a>
        </div>
        <template v-if="cate == 1">
        <div class="empty-tip" v-if="doingList.length == 0">暂无拍卖</div>
        <div class="auction-item" v-for="item in doingList" :key="item.id" @click="$helper.goPage('/auction/'+item.id)">
            <div class="img-name">
                <div class="info">
                    <div class="text-ellipsis-2 name">{{item.product.name}}</div>
                    <div class="spec-desc">{{item.sku_info.spec_desc}}</div>
                    <div class="spec-desc issue"><span>#{{item.current_issue}}期</span></div>
                </div>
                <div class="img"><img :src="$store.state.assetUrl + item.product.img" alt=""></div>
            </div>
            <div class="item-content">
                <div class="detail">
                    <div class="left">
                        <div class="desc">共有{{item.count}}人参与</div>
                        <div class="desc">最高出价：￥{{item.hight_price}}</div>
                    </div>
                    <div class="right">
                        <div class="countdown-panel">
                            <div class="label">剩余</div>
                            <countdown :value="item.deadline"></countdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </template>
        <template v-if="cate == 2">
        <div class="empty-tip" v-if="waitList.length == 0">暂无拍卖</div>
        <div class="auction-item" v-for="item in waitList" :key="item.id" @click="$helper.goPage('/auction/'+item.id)">
            <div class="img-name">
                <div class="info">
                    <div class="text-ellipsis-2 name">{{item.product.name}}</div>
                    <div class="spec-desc">{{item.sku_info.spec_desc}}</div>
                    <div class="spec-desc issue"><span>#{{item.current_issue}}期</span></div>
                </div>
                <div class="img"><img :src="$store.state.assetUrl + item.product.img" alt=""></div>
            </div>
            <div class="item-content">
                <div class="detail">
                    <div class="left">
                        <div class="desc">共有{{item.count}}人参与</div>
                        <div class="desc">最高出价：￥{{item.hight_price}}</div>
                    </div>
                    <div class="right">
                        <div class="countdown-panel">
                            <div class="label">未开始</div>
                            <countdown :value="item.start_deadline"></countdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </template>
 
        <template v-if="cate == 3">
        <div class="empty-tip" v-if="endList.length == 0">暂无拍卖</div>
        <div class="auction-item" v-for="item in endList" :key="item.id" @click="$helper.goPage('/auction/'+item.id)">
            <div class="img-name">
                <div class="info">
                    <div class="text-ellipsis-2 name">{{item.product.name}}</div>
                    <div class="spec-desc">{{item.sku_info.spec_desc}}</div>
                    <div class="spec-desc issue"><span>#{{item.current_issue}}期</span></div>
                </div>
                <div class="img"><img :src="$store.state.assetUrl + item.product.img" alt=""></div>
            </div>
            <div class="item-content">
                <div class="detail">
                    <div class="left">
                        <div class="desc">共有{{item.count}}人参与</div>
                        <div class="desc">最高出价：￥{{item.hight_price}}</div>
                    </div>
                    <div class="right">
                        <span class="expired-tip">本期已结束</span>
                    </div>
                </div>
            </div>
        </div>
        </template>

        <div class="load-more" v-if="isLoading">正在努力加载中...</div>
        <div class="load-more" v-if="isNoMore">全部加载完了</div>
    </div>
</template>

<script>
import countdown from '../components/countdown.vue';
let self;
export default {
    name: "auctions",
    components: {countdown},
    data() {
        return {
            page:1,
            auctions:[],
            doingList:[],
            waitList:[],
            endList:[],
            isLoading:false,
            isNoMore:false,
            cate:1
        };
    },
    mounted(){
        self = this;
        document.title = '拍卖中心';
        this.$store.dispatch('showBottomBar',3);
        this.getAuctions();
    },
    beforeDestroy(){
        
    },
    methods:{
        selectCate(cate){
            this.cate = cate
        },
        getAuctions(){
            var param = {
                page : this.page,
                cate : this.cate,
                keyword: this.keyword
            };
            this.$http.get('listAuctions',{params:param}).then((res)=>{
                let auctions = res.data
                let doingList = [];
                let waitList = [];
                let endList = [];
                auctions.map((v)=>{
                    if(v.is_expired == 0){
                        if(v.is_start){
                            doingList.push(v)
                        }else{
                            waitList.push(v)
                        }
                    }else{
                        endList.push(v)
                    }
                })
                self.doingList = doingList
                self.waitList = waitList
                self.endList = endList
                self.auctions = auctions
                
            }).finally(()=>{
                self.isLoading = false;
            });
        },
        endReached(){
            let top = document.documentElement.scrollTop || document.body.scrollTop;
            //触底
            if(top + window.innerHeight  > document.getElementsByClassName('page-wrap')[0].offsetHeight - 90){
                if(this.isLoading){
                    return;
                }
                if(this.page >= this.auctions.last_page ){
                    self.isNoMore = true;
                    return;
                }
                this.page++;
                this.isLoading = true;
                this.getAuctions();
            }
        },
    }
};
</script>

<style scoped lang='less' >
@import "../assets/reset.less";
.page-wrap{
    padding:10px 10px 70px 10px;
}

.cates-box{
    white-space: nowrap;
    overflow-x: auto;
    margin-bottom: 1.5rem;
}
.cates-box .item{
    display: inline-block;
    padding: 4px 10px;
    border-radius: 20px;
    color: rgba(0, 0, 0, 0.5);
    background: rgba(0, 0, 0, 0.03);
    border: 1px solid rgba(0, 0, 0, 0);
    margin-right: 8px;
}
.cates-box .item.active{
    background: rgba(37, 198, 252, 0.1);
    color: rgba(37, 198, 252, 1);
    border: 1px solid rgba(37, 198, 252, 1);
}

.stat-box{
    display: flex;
    margin-bottom: 10px;
    padding: 10px 0;
}
.stat-box .item{
    width: 25%;
    border-right: 1px dashed rgba(0, 0, 0, 0.1);
    text-align: center;
}
.stat-box .item:last-child{
    border-right: none;
}
.stat-box .item .label{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.55);
    line-height: 18px;
}
.stat-box .item .value{
    font-size: 22px;
    font-weight: 500;
    line-height: 22px;
}
.stat-box .item .value small{
    font-size: 12px;
    font-weight: normal;
}

.empty-tip{
    border-radius: 5px;
    border: 1px dashed rgba(0, 0, 0, 0.1);
    padding: 5px;
    text-align: center;
    color: rgba(0, 0, 0, 0.55);
}

.item-title{
    margin: 1.5rem 0 1rem 0;
    font-size: 16px;
    color: #000;
    font-weight: 500;
}

.auction-item{
    background: rgba(0, 0, 0, 0.035);
    margin-bottom: 1rem;
    height: 190px;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
}

.auction-item .item-content{
    padding: 10px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.03);
    border-radius: 15px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: calc(100% - 20px);
}
.auction-item .item-content::after{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #fff;
    
}
.auction-item .img-name{
    display: flex;
    position: absolute;
    left: 10px;
    top: 10px;
    width: calc(100% - 20px);
}
.auction-item .info{
    padding-left: 10px;
    width: calc(100% - 110px);
    padding-right: 5px;
    padding-top: 5px;
    font-size: 15px;
}
.auction-item .spec-desc{
    font-weight: normal;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.55);
}
.auction-item .desc{
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: rgba(0, 0, 0, 1);
}
.auction-item .issue{
    margin-top: 5px;
}
.auction-item .issue span{
    background: #fff;
    border-radius: 10px;
    padding: 0 8px;
}
.auction-item .img {
    width: 110px;
    text-align: right;
    position: relative;
    padding-right: 5px;
    padding-top: 5px;
}
.auction-item .img img{
    height: 70px;
    width: 70px;
    
}

.auction-item .detail{
    display: flex;
}
.auction-item .detail .left, .auction-item .detail .right{
    flex: 1;
}
.auction-item .detail .left .desc{
    line-height: 21px;
}
.auction-item .detail .right{
    text-align: right;
    
}
.auction-item .detail .right .label{
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
}
.auction-item .detail .right .countdown-panel{
    display: inline-block;
    text-align: center;
}
.auction-item .detail .right .countdown-box{
    color: #fff;
    background: rgba(252, 73, 86, 0.8);
    border-radius: 15px;
    padding: 1px 8px;
    font-size: 11px;
}
.auction-item .expired-tip{
    font-size: 12px;
    width: 82px;
    text-align: center;
    display: inline-block;
    line-height: 44px;
    color: rgba(0, 0, 0, 0.55);
}
</style>
