<template>
    <div class="page-wrap">
        <div class="status">
            <div>
                <md-icon name="checked" class="color-success" v-if="info.status == 2" size="lg"></md-icon>
                <md-icon name="time" v-if="info.status > -1 && info.status < 2" size="lg"></md-icon>
                <md-icon name="check-disabled" class="color-warning" v-if="info.status == -1" size="lg"></md-icon>
            </div>
            {{info.status_desc}}
        </div>
        <div class="block-item" v-if="info.order_logs.length > 0">
            <div class="remark-item"  v-for="item in info.order_logs" :key="item.id">
                <div class="date">{{item.created_at}}</div>
                {{item.content}}
            </div>
        </div>
        <div class="block-item account" v-if="info.product_type == 0">
            <div class="title"><span>授权</span></div>
            <div class="name">
                {{info.first_name}} {{info.last_name}}
            </div>
            <div class="email">{{info.email}}</div>
        </div>
        <div class="block-item account" v-if="info.product_type == 1">
            <div class="title"><span>收货地址</span></div>
            <div class="name">
                {{info.address.consignee}} {{info.address.mobile}}
            </div>
            <div class="email">{{info.address.province}} {{info.address.city}} {{info.address.area}} {{info.address.address}}</div>
        </div>

        <div class="block-item" v-if="info.invoice && info.product_type == 0">
            <div class="title">
                <span>发票信息</span>
                <span class="more-view">
                    <md-tag type="fill" fill-color="#37e492" font-color="#fff" shape="circle" v-if="info.invoice.status == 1">已开票</md-tag>
                    <md-tag type="fill" fill-color="#eee" font-color="#333" shape="circle" v-if="info.invoice.status == 0">待开票</md-tag>
                    <md-tag type="fill" fill-color="#eee" font-color="#333" shape="circle" v-if="info.invoice.status == -1">已驳回</md-tag>
                </span>
            </div>
            <div class="bill-box with-head">
                <div class="item">
                    <div class="label">
                        发票类型
                    </div>
                    <div class="value">
                        <span v-if="info.invoice.invoice_type == 0">普通发票</span>
                        <span v-if="info.invoice.invoice_type == 1">增值专用发票</span>
                    </div>
                </div>
                <div class="item">
                    <div class="label">
                        抬头类型
                    </div>
                    <div class="value">
                        <span v-if="info.invoice.head_type == 0">个人</span>
                        <span v-if="info.invoice.head_type == 1">企业</span>
                    </div>
                </div>
                <div class="item">
                    <div class="label">
                        抬头
                    </div>
                    <div class="value">
                        {{info.invoice.head}}
                    </div>
                </div>
                <div class="item" v-if="info.invoice.head_type == 1">
                    <div class="label">
                        单位税号
                    </div>
                    <div class="value">
                        {{info.invoice.taxes_no}}
                    </div>
                </div>
                <template v-if="info.invoice.invoice_type == 1">
                <div class="item">
                    <div class="label">
                        注册地址
                    </div>
                    <div class="value">
                        {{info.invoice.reg_address}}
                    </div>
                </div>
                <div class="item">
                    <div class="label">
                        注册电话
                    </div>
                    <div class="value">
                        {{info.invoice.reg_phone}}
                    </div>
                </div>
                <div class="item">
                    <div class="label">
                        开户银行
                    </div>
                    <div class="value">
                        {{info.invoice.bank_name}}
                    </div>
                </div>
                <div class="item">
                    <div class="label">
                        银行账号
                    </div>
                    <div class="value">
                        {{info.invoice.bank_account}}
                    </div>
                </div>
                </template>
            </div>
        </div>
        <div class="block-item" v-if="info.invoice && info.invoice.invoice_type == 1 && info.product_type == 0">
            <div class="title">
                <span>收件地址</span>
            </div>
            <div>{{info.address.consignee}} {{info.address.mobile}} 邮编：{{info.address.zipcode}}</div>
            <div class="small">{{info.address.province}} {{info.address.city}} {{info.address.area}} {{info.address.address}}  </div>
        </div>
        
        <div class="block-item">
            <div class="title">
                <span>商品信息</span>
            </div>
            <div class="order-product-list">
                <template v-for="item in info.products" >
                    <div class="order-product-item" :key="item.id">
                        <img :src="$store.state.assetUrl + item.img" alt="">
                        <div class="info">
                            <div class="name text-ellipsis"> <md-tag  shape="circle" v-if="item.is_blind_box" font-color="#eb2f96">魔盒</md-tag>{{item.name}}</div>
                            <div class="spec-desc">{{item.spec_desc}}</div>
                            <div class="price">
                                <div class="text-left"><small>{{item.currency.code}}</small>{{item.currency.amount}} </div>
                                <div class="text-right">x{{item.count}}</div>
                            </div>
                        </div>   
                    </div>

                    <div v-if="item.gift_products.length > 0" :key="item.id + 'gift'" class="order-product-combos-list">
                        <div class="order-product-item combos" v-for="gift in item.gift_products" :key="gift.id">
                            <img :src="$store.state.assetUrl+gift.img" alt="">
                            <div class="info">
                                <div class="name text-ellipsis"><md-tag  shape="circle" font-color="#37e492">赠品</md-tag> {{gift.name}}</div>
                                <div class="spec-desc">{{gift.spec_desc}} x{{ gift.count }}</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="item.combo_products.length > 0" :key="item.id + 'combo'" class="order-product-combos-list">
                        <div class="order-product-item combos" v-for="combo in item.combo_products" :key="combo.id">
                            <img :src="$store.state.assetUrl+combo.img" alt="">
                            <div class="info">
                                <div class="name text-ellipsis"><md-tag  shape="circle" font-color="#fa8c16">组合</md-tag> {{combo.name}}</div>
                                <div class="spec-desc">{{combo.spec_desc}}</div>
                                <div class="price">
                                    <div class="text-left"><small>{{combo.currency.code}}</small>{{combo.currency.amount}}</div>
                                    <div class="text-right">x{{ combo.count }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>

            <div class="mt-1" v-if="info.status > 0 && info.product_type == 0 ">
                <md-button class="md" round type="primary" @click="codesVisible = true;">查看产品密钥</md-button>
            </div>
        </div>
        <div class="block-item supple-tips" v-if="info.status < 1 && info.has_blind_product == 1">
            <md-icon name="info-solid" class="color-danger"></md-icon> 订单中含有潘多拉魔盒商品，需要支付后才能查看。
        </div>
        <div class="block-item supple-tips" v-if="info.need_supple_count > 0 && info.status > 0">
            <md-icon name="info-solid" class="color-danger"></md-icon> 您还有<span class="">{{info.need_supple_count}}</span>个商品正在处理中，
            <span v-if="info.wait_handle_left > 0">预计{{info.wait_handle_left}}小时内为您发货</span>
            <span v-else>将尽快为您发货</span>
            ，感谢您的理解。
        </div>
        <div class="block-item">
            <div class="bill-box small">
                <div class="item" v-for="item in info.subjects" :key="item.id">
                    <div class="label">{{item.desc}}</div>
                    <div class="value">{{item.type == 'decr' ? '-':''}} {{item.currency.code}}{{item.currency.amount}}</div>
                </div>
                <div class="item">
                    <div class="label">订单总价</div>
                    <div class="value color-danger"><small>{{info.total_currency.code}}</small> {{info.total_currency.amount}}</div>
                </div>
            </div>
        </div>
        <div class="block-item">

            <div class="bill-box small">
                <div class="item">
                    <div class="label">订单编号</div>
                    <div class="value">{{info.order_sn}}</div>
                </div>
                <div class="item">
                    <div class="label">下单时间</div>
                    <div class="value">{{info.created_at}}</div>
                </div>
                <div class="item" v-if="info.status >= 1 && info.pay_info">
                    <div class="label">支付方式</div>
                    <div class="value">{{info.pay_info.paymentName}}</div>
                </div>
                <div class="item">
                    <div class="label">完成时间</div>
                    <div class="value">{{info.finished_at}}</div>
                </div>
                 
            </div>
        </div>

        <div class="bottom-box">
            <md-button class="md" type="primary" v-if="info.status == 0" round @click="$helper.orderToPay(info.order_sn)">支付</md-button>
            <md-button class="md" type="warning" v-if="info.status == 2 && !info.is_comment" round @click="$helper.goPage('/order-comment/'+ info.order_sn)">评价</md-button>
        </div>

        <md-popup v-model="codesVisible" position="bottom" class="codes-pop">
            <div class="block-title">
                <span>产品密钥列表</span>
                <a @click="codesVisible = false" class="close"><md-icon name="close"></md-icon></a>
            </div>
            <div class="codes-panel">
                <div v-for="item in info.products" :key="item.id">
                <div class="item"  >
                    <div class="name">
                        <img :src="$store.state.assetUrl+item.img" alt="">
                        <div>
                            {{item.name}}
                            <div class="small">
                                <span class="color-gray">{{item.spec_desc}}</span> <strong>x{{item.count}}</strong>
                            </div>
                        </div>
                        
                    </div>
                    <div class="code-remark" v-if="item.code_remark">{{item.code_remark}}</div>
                    <template v-if="item.codes.length > 0">
                        <div class="code-item" v-for="code in item.codes" :key="code.id">
                            <div class="code-value">{{code.code=='sfd' ? '已发送至您的邮箱':code.code}}</div>
                            <md-button size="small" round type="default" @click="$helper.copyText(code.code)" v-if="code.code !='sfd'">复制</md-button>
                        </div>
                    </template>
                    <div v-else class="color-gray small text-center">正在处理中，请耐心等待</div>
                </div>
                <div class="item" v-for="gift in item.gift_products" :key="gift.id">
                    <div class="name">
                        <img :src="$store.state.assetUrl+gift.img" alt="">
                        <span>{{gift.name}}

                            <div class="small">
                                <span class="color-gray">{{gift.spec_desc}}</span> <strong>x{{gift.count}}</strong>
                            </div>
                        </span>
                    </div>
                    <div class="code-remark" v-if="gift.code_remark">{{gift.code_remark}}</div>
                    <template v-if="gift.codes.length > 0">
                        <div class="code-item" v-for="code in gift.codes" :key="code.id">
                            <div class="code-value">{{code.code=='sfd' ? '已发送至您的邮箱':code.code}}</div>
                            <md-button size="small" round type="default" @click="$helper.copyText(code.code)" v-if="code.code !='sfd'">复制</md-button>
                        </div>
                    </template>
                    <div v-else class="color-gray small text-center">正在处理中，请耐心等待</div>
                </div>

                <div class="item" v-for="combo in item.combo_products" :key="combo.id">
                    <div class="name">
                        <img :src="$store.state.assetUrl+combo.img" alt="">
                        <span>{{combo.name}}
                            <div class="small">
                                <span class="color-gray">{{combo.spec_desc}}</span> <strong>x{{combo.count}}</strong>
                            </div>
                        </span>
                    </div>
                    <div class="code-remark" v-if="combo.code_remark">{{combo.code_remark}}</div>
                    <template v-if="combo.codes.length > 0">
                        <div class="code-item" v-for="code in combo.codes" :key="code.id">
                            <div class="code-value">{{code.code=='sfd' ? '已发送至您的邮箱':code.code}}</div>
                            <md-button size="small" round type="default" @click="$helper.copyText(code.code)" v-if="code.code !='sfd'">复制</md-button>
                        </div>
                    </template>
                    <div v-else class="color-gray small text-center">正在处理中，请耐心等待</div>
                </div>
                </div>
            </div>
        </md-popup>
    </div>
</template>

<script>
let self;
export default {
    name: "order",
    components: {},
    data() {
        return {
            info:{
                order_logs:[],
                invoice:{},
                total_currency:{}
            },
            codesVisible:false
        };
    },
    mounted(){
        self = this;
        document.title="订单详情";
        this.$store.dispatch('hideBottomBar');
        this.orderSn = this.$route.params.order_sn;
        this.getOrder();
    },
    methods:{
        getOrder(){
            this.$http.get('user/getOrder?order_sn='+this.orderSn).then((res)=>{
                self.info = res.data;
               
            });
        }
    }
};
</script>


<style>
.codes-pop .md-popup-box{
    padding: 0;
}
</style>

<style scoped lang='less' >
@import "../assets/reset.less";

.page-wrap{
    padding-bottom: 70px;
    background: rgba(0, 0, 0, 0.01);
}
.status{
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    padding: 1rem;
}
.block-item {
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.04), 0 5px 10px rgba(0, 0, 0, 0.02);
  margin: 0.7rem;
  padding: 1rem;
  background: #fff;
}
.block-item .title {
  position: relative;
  margin-bottom: 1rem;
}

.block-item .title > span:first-child {
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 14px;
}
.block-item .title .more-view {
  position: absolute;
  right: -4px;
  top: 0;
  font-size: 12px;
  color: #999;
  display: flex;
  line-height: 20px;
}
.block-item .title .more-view .md-icon {
  font-size: 14px;
  line-height: 20px;
}
.account .title{
    margin-bottom: 0.7rem;
}
.account .name{
    font-weight: 500;
    color: #111;
}
.account .email{
    font-size: 13px;
    color: rgba(0, 0, 0, 0.8);
}
.order-product-item{
    display: flex;
    padding-top: 1rem;
}
.order-product-item:first-child{
    padding-top:0;
}
.order-product-item img{
    height: 50px;
    width: 50px;
}
.order-product-item .info{
    padding-left: 0.5rem;
    font-size: 12px;
    width: calc(100% - 50px);
}
.order-product-item .spec-desc{
    color: rgba(0, 0, 0, 0.4);
}
.order-product-item .price{
    overflow: hidden;
    font-size: 12px;
    display: flex;
    font-weight: 500;
}
.order-product-item .price .text-left,.order-product-item .price .text-right{
    flex: 1;
}
.order-product-combos-list{
    background: rgba(0, 0, 0, 0.01);
    margin-top: 10px;
}
.order-product-item.combos{    
    padding: 10px;
    border-radius: 4px;
}

.order-product-item.combos img{
    height: 40px;
    width: 40px;
}
.order-product-item .name{
    font-size: 14px;
}

.order-product-item.combos .info{
    padding-left: 0.5rem;
    font-size: 12px;
    width: calc(100% - 40px);
}

.remark-item{
    padding-bottom: 0.7rem;
    margin-bottom: 0.7rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}
.remark-item:last-child{
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
}
.remark-item .date{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.4);
}

.bill-box.with-head{
    border-top: 1px dashed rgba(0, 0, 0, 0.15);
    padding-top: 0.7rem;
}
.bill-box .item{
    padding: 0.3rem 0;
    display: flex;
    color: rgba(0, 0, 0, 0.6);
}
.bill-box.small .item{
    font-size: 12px;
}
.bill-box .item .label{
    flex: 3;
    text-align: left;
}
.bill-box .item .value{
    flex: 2;
    text-align: right;
}
.bill-box .bill-footer{
    border-top: 1px dashed rgba(0, 0, 0, 0.15);
    padding-top: 1rem;
    margin-top: 0.7rem;
}

.supple-tips{
    font-size: 13px;
    color: rgba(0, 0, 0, 0.8);
}
.codes-pop .block-title{
    padding: 1rem 1rem 0 1rem;
}
.codes-pop .block-title .close{
    right: 10px;
    top: 10px;
}
.codes-panel{
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 56px);
    padding:0.7rem;
}
.codes-panel .item{
    border-radius: 10px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.04), 0 5px 10px rgba(0, 0, 0, 0.02);
    margin-bottom: 0.7rem;
    padding: 1rem;
}
.codes-panel .item .name{
    display: flex;
    font-size: 13px;
    padding-bottom: 0.7rem;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.15);
    margin-bottom: 0.7rem;
}
.codes-panel .code-remark{
    font-size: 12px;
    padding-bottom: 0.7rem;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.15);
    margin-bottom: 0.7rem;
    text-align: center;
    }
.codes-panel .item .name img{
    height: 40px;
    width: 40px;
    border-radius: 10px;
    vertical-align: middle;
    margin-right: 10px;
    background: rgba(0, 0, 0, 0.01);
    padding: 4px;
}
.codes-panel .code-item{
    color: @success-color;
    display: flex;
    padding-bottom: 0.7rem;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.15);
    margin-bottom: 0.7rem;
}
.codes-panel .code-item:last-child{
    margin-bottom: 0;
    border-bottom: none;
    padding-bottom: 0;
}
.codes-panel .code-item .code-value{
    width: calc(100% - 60px);
    padding-right: 10px;
    font-weight: 500;
    word-break: break-all;
}
.codes-panel .code-item .md-button{
    width: 60px;
}
.bottom-box{
    padding: 1rem 0.7rem;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}
</style>
