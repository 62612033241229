<template>
    <div class="page-wrap">
        <div class="fixed-top">
            <div class="search-box">
                <md-icon name="search" class="search-icon"></md-icon>
                <input type="search" placeholder="请输入关键词" v-model="keyword">
                <a class="search-btn" @click="search">搜索</a>
            </div>
            <div class="cates-box">
                <a class="item" :class="{active:'' == cate}" @click="selectCate('')">全部</a>
                <a class="item" :class="{active:item.id == cate}" v-for="item in categories" :key="item.id"  @click="selectCate(item.id)">{{item.name}}</a>
            </div>
        </div>
        <!-- <div class="ad-item ract full">
            <a><img src="../assets/phone-bg.png" alt="" /></a>
        </div> -->
        <div class="emtpy" v-if="products.total <= 0">
            <p><svg t="1631180042906" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16472" width="64" height="64"><path d="M855.6 427.2H168.5c-12.7 0-24.4 6.9-30.6 18L4.4 684.7C1.5 689.9 0 695.8 0 701.8v287.1c0 19.4 15.7 35.1 35.1 35.1H989c19.4 0 35.1-15.7 35.1-35.1V701.8c0-6-1.5-11.8-4.4-17.1L886.2 445.2c-6.2-11.1-17.9-18-30.6-18zM673.4 695.6c-16.5 0-30.8 11.5-34.3 27.7-12.7 58.5-64.8 102.3-127.2 102.3s-114.5-43.8-127.2-102.3c-3.5-16.1-17.8-27.7-34.3-27.7H119c-26.4 0-43.3-28-31.1-51.4l81.7-155.8c6.1-11.6 18-18.8 31.1-18.8h622.4c13 0 25 7.2 31.1 18.8l81.7 155.8c12.2 23.4-4.7 51.4-31.1 51.4H673.4zM819.9 209.5c-1-1.8-2.1-3.7-3.2-5.5-9.8-16.6-31.1-22.2-47.8-12.6L648.5 261c-17 9.8-22.7 31.6-12.6 48.4 0.9 1.4 1.7 2.9 2.5 4.4 9.5 17 31.2 22.8 48 13L807 257.3c16.7-9.7 22.4-31 12.9-47.8zM375.4 261.1L255 191.6c-16.7-9.6-38-4-47.8 12.6-1.1 1.8-2.1 3.6-3.2 5.5-9.5 16.8-3.8 38.1 12.9 47.8L337.3 327c16.9 9.7 38.6 4 48-13.1 0.8-1.5 1.7-2.9 2.5-4.4 10.2-16.8 4.5-38.6-12.4-48.4zM512 239.3h2.5c19.5 0.3 35.5-15.5 35.5-35.1v-139c0-19.3-15.6-34.9-34.8-35.1h-6.4C489.6 30.3 474 46 474 65.2v139c0 19.5 15.9 35.4 35.5 35.1h2.5z" p-id="16473" fill="#bfbfbf"></path></svg></p>
            <p>暂无商品</p>
        </div>
        <div class="container mt-1" v-else>
            <div class="guess-likes fixed">
                <template  v-for="item in products.data">
                <div class="item" @click="$helper.goPage('/item/' + item.slug)" :key="item.id">
                    <div class="img"><img :src="$store.state.assetUrl + item.img" alt=""></div>
                    <div class="text-ellipsis ">{{item.name}}</div>
                    <div>
                        <md-tag shape="circle" type="ghost" font-color="#555">评分 {{item.rate}}星</md-tag>
                    </div>
                    <div class="price">
                        <small>{{item.currency.code}}</small>{{item.currency.amount}} 
                        <del v-if="item.market_price > 0"><small>{{item.market_currency.code}} </small>{{item.market_currency.amount}} </del>
                    </div>
                </div>
                </template>
            </div>
            
            <div class="load-more" v-if="isLoading">正在努力加载中...</div>
            <div class="load-more" v-if="isNoMore">全部加载完了</div>
        </div>
    </div>
</template>

<script>
let self;
export default {
    name: 'search',
    components: {
        
    },
    data(){
        return {
            page:1,
            products:{
                total: 0,
                data:[]
            },
            categories:{},
            cate: 0,
            keyword: '',
            tag:'',
            isLoading: false,
            isNoMore: false,
        }
    },
    mounted(){
        document.title = '搜索商品'
        this.$store.dispatch('hideBottomBar');
        self = this;

        this.keyword = this.$route.query.keyword || '';
        this.tag = this.$route.query.tag || '';
        this.cate = parseInt(this.$route.query.cate) || 0;

        this.getSearchData();

        this.page = 1;
        this.getProducts();

        window.onscroll = ()=>{
            if(self.$route.path == '/search'){
                self.endReached();
            }
        }
    },
    methods:{
        search(){
            this.page = 1;
            this.getProducts();
            this.$http.post('searchLog',{keyword:this.keyword});
        },
        getSearchData(){
            this.$http.get('getSearchData', {showLoading:false}).then((res)=>{
                self.categories = res.data.categories;

            });
        },
        getProducts(){
            var param = {
                page : this.page,
                cate : this.cate,
                keyword: this.keyword,
                tag: this.tag
            };
            this.$http.get('listProducts',{params:param}).then((res)=>{
                if(param.page == 1){
                    self.products = res.data;
                }else if(res.data.data.length > 0){
                    self.products.data = self.products.data.concat(res.data.data);
                }
                
            }).finally(()=>{
                self.isLoading = false;
            });
        },
        endReached(){
            let top = document.documentElement.scrollTop || document.body.scrollTop;
            //触底
            if(top + window.innerHeight  > document.getElementsByClassName('page-wrap')[0].offsetHeight - 90){
                if(this.isLoading){
                    return;
                }
                if(this.page >= this.products.last_page ){
                    self.isNoMore = true;
                    return;
                }
                this.page++;
                this.isLoading = true;
                this.getProducts();
            }
        },
        selectCate(id){
            this.cate = id;
            this.page = 1;
            this.$helper.scrollTop();
            this.getProducts();
        }
    }
}
</script>

<style scoped lang='less'>
.emtpy{
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    padding: 3rem 0;
}
.page-wrap{
    background: rgba(0, 0, 0, 0.01);
    padding-bottom:0;
    padding-top: 100px;
}
.fixed-top{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}
.search-box,.cates-box{
    background: #fff;
    padding: 0.7rem;
    display: flex;
    font-size: 13px;
    position: relative;
}
.search-box{
    padding-bottom: 0;
}
.search-box .search-icon{
    position: absolute;
    left: 1.3rem;
    top: 1.25rem;
}
.search-box input{
    -webkit-appearance: none;
    -webkit-box-shadow: none;
    outline: none;
    width: calc(100% - 40px);
    background: rgba(0, 0, 0, 0.03);
    height: 34px;
    line-height: 34px;
    padding-left: 30px;
    border-radius: 25px;
    border: 2px solid rgba(37, 198, 252, 0);
    transition: all 0.2s;
}
.search-box input:focus{
    border: 2px solid rgba(37, 198, 252, 0.8);
    background: #fff;
}
.search-box .search-btn{
    width: 40px;
    display: inline-block;
    text-align: right;
     line-height: 34px;
}
.cates-box{
    white-space: nowrap;
    overflow-x: auto;
}
.cates-box .item{
    display: inline-block;
    padding: 4px 10px;
    border-radius: 20px;
    color: rgba(0, 0, 0, 0.5);
    background: rgba(0, 0, 0, 0.03);
    border: 1px solid rgba(0, 0, 0, 0);
    margin-right: 8px;
}
.cates-box .item.active{
    background: rgba(37, 198, 252, 0.1);
    color: rgba(37, 198, 252, 1);
    border: 1px solid rgba(37, 198, 252, 1);
}
</style>
