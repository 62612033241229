import Vue from 'vue';
import VueRouter from "vue-router";

Vue.use(VueRouter);

import error from './pages/error';

import index from './pages/index';
import login from './pages/login';
import search from './pages/search';
import article from './pages/article';
import articles from './pages/articles';
import product from './pages/product';
import carts from './pages/carts';
import checkout from './pages/checkout';
import orderPay from './pages/orderPay';
import checkoutGroupon from './pages/checkoutGroupon';
import orderPayGroupon from './pages/orderPayGroupon';
import orderPayDeposit from './pages/orderPayDeposit';
import orderPayCollect from './pages/orderPayCollect';
import payCallback from './pages/payCallback';
import myAccount from './pages/myAccount';
import myAuctions from './pages/myAuctions';
import orders from './pages/orders';
import orderGroupons from './pages/orderGroupons';
import myCollectOrders from './pages/myCollectOrders';
import order from './pages/order';
import orderComment from './pages/orderComment';
import address from './pages/address';
import vip from './pages/vip';
import profile from './pages/profile';
import myCoupons from './pages/myCoupons';
import coupons from './pages/coupons';
import points from './pages/points';
import exchange from './pages/exchange';
import cdkey from './pages/cdkey';
import groupons from './pages/groupons';
import auctions from './pages/auctions';
import auction from './pages/auction';
import checkoutAuction from './pages/checkoutAuction';
import ranking from './pages/ranking';
import commission from './pages/commission';
import luckyGrid from './pages/luckyGrid';
import signIn from './pages/signIn';
import wishList from './pages/wishList';
const routes = [
    {
        path:'/',
        component:index,
    },
    {
        path:'/login',
        component:login
    },
    {
        path:'/search',
        component:search
    },
    {
        path:'/article/:id?',
        component:article
    },
    {
        path:'/articles/:cid?',
        component:articles
    },
    {
        path:'/item/:slug',
        component:product
    },
    {
        path:'/carts',
        component:carts
    },
    {
        path:'/checkout/:sku/:count',
        component:checkout
    },
    {
        path:'/checkout',
        component:checkout
    },
    {
        path:'/order-pay/:orderSn',
        component:orderPay
    },
    {
        path:'/checkout-groupon/:sku/:count/:grouponParentId',
        component:checkoutGroupon
    },
    {
        path:'/checkout-auction/:id/:issue/:price',
        component:checkoutAuction
    },
    {
        path:'/order-pay-groupon/:orderSn',
        component:orderPayGroupon
    },
    {
        path:'/order-pay-deposit',
        component:orderPayDeposit
    },
    {
        path:'/collect/:slug',
        component:orderPayCollect
    },
    {
        path:'/pay-callback/:payment/:orderSn',
        component:payCallback
    },
    {
        path:'/my-account',
        component:myAccount
    },
    {
        path:'/ranking',
        component:ranking
    },
    {
        path:'/commission',
        component:commission
    },
    {
        path:'/luckyGrid',
        component:luckyGrid
    },
    {
        path:'/signIn',
        component:signIn
    },
    {
        path:'/wishList',
        component:wishList
    },
    {
        path:'/orders/:status',
        component:orders
    },
    {
        path:'/order-groupons/:status',
        component:orderGroupons
    },
    {
        path:'/order/:order_sn',
        component:order
    },
    {
        path:'/order-comment/:order_sn',
        component:orderComment
    },
    {
        path:'/address',
        component:address
    },
    {
        path:'/vip',
        component:vip
    },
    {
        path:'/profile',
        component:profile
    },
    {
        path:'/my-coupons',
        component:myCoupons
    },
    {
        path:'/my-auctions',
        component:myAuctions
    },
    {
        path:'/my-collect-orders',
        component:myCollectOrders
    },
    {
        path:'/coupons',
        component:coupons
    },
    {
        path:'/points',
        component:points
    },
    {
        path:'/exchange',
        component:exchange
    },
    {
        path:'/cdkey',
        component:cdkey
    },
    {
        path:'/groupons',
        component:groupons
    },
    {
        path:'/auctions',
        component:auctions
    },
    {
        path:'/auction/:id',
        component:auction
    },
    {
        path:'*',
        component:error
    }
];
export default new VueRouter({routes,mode:'history'});
