<template>
    <div class="page-wrap">
        <div class="valid-box">
            <div class="item">
                <div class="count">
                    {{$store.state.userInfo.points}}
                </div>
                <div><span class="tag">可用积分</span></div>
            </div>
            <div class="item">
                <div class="count">
                    {{$store.state.userInfo.total_points}}
                </div>
                <div><span class="tag">累计积分</span></div>
            </div>
        </div>
        <div class="container">
            
            <div class="coupon-item" v-for="item in coupons" :key="item.id" @click="showCoupon(item.id)">
                <div class="top">
                    <div class="money">
                        <span class="value">{{ item.value }}</span>
                        <span class="small" v-if="item.value_type == 0">￥</span>
                        <span class="small" v-if="item.value_type == 1">%</span>
                        <div>
                            <span class="small" v-if="item.type == 0">代金券</span>
                            <span class="small" v-if="item.type == 1">满{{ item.value_limit }}元可用</span>
                        </div>
                    </div>

                    <div class="info">
                        <div class="title text-ellipsis">{{ item.title }}</div>
                        <div class="limit">
                            <div class="small" v-if="item.use_range > 0">部分商品可用</div>
                            <div class="small" v-if="item.use_range == 0">所有商品可用</div>
                            <div class="small" v-if="item.price_points > 0">兑换需要<span class="color-danger">{{item.price_points}}积分</span></div>
                            <div class="small" v-if="item.price_points == 0">免费领取</div>
                        </div>
                        <img src="https://www.aiviy.com/assets/imgs/discount-coupon.svg" class="tip-icon">
                    </div>
                </div>
                <div class="bottom">
                    <div>
                        <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" >
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z">
                            </path>
                        </svg>
                        <span v-if="item.receive_count_limit > 0">每人限量x{{ item.receive_count_limit }}</span>
                        <span v-else>不限量</span>
                    </div>
                    <div>
                        <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                        <span>{{item.end_at}}</span>
                    </div>
                </div>
            </div>
            <div class="divider"></div>
            <div class="product-item" v-for="item in exchangeProducts" :key="item.id" @click="showExchangeInfo(item)">
                <img :src="$store.state.assetUrl + item.sku_info.product.img" alt="">
                <div class="info">
                    <div class="name text-ellipsis-2">{{item.sku_info.product.name}}</div>
                    <div class="desc">{{item.sku_info.spec_desc}}</div>
                    <div class="desc">需要<span class="color-danger">{{item.price_points}} 积分</span></div>
                </div>
                <div class="btns">
                    <md-tag shape="circle" type="fill" fill-color="rgba(37,198,252,0.9)" font-color="#fff" v-if="item.count > item.exchanged_count">立即兑换</md-tag>
                    <md-tag shape="circle" type="fill" fill-color="rgba(0,0,0,0.06)" font-color="#555" v-else>立即兑换</md-tag>
                    <div>每人限量x{{item.limit_count}}</div>
                    <div>剩余 {{item.count - item.exchanged_count}}</div>
                </div>
            </div>


        </div>

        <md-landscape v-model="couponVisible">
            <div class="coupon-info">
                <div class="money">
                <span class="value">{{ coupon.value }}</span>
                <span class="small" v-if="coupon.value_type == 0">￥</span>
                <span class="small" v-if="coupon.value_type == 1">%</span>
                </div>
                <div class="text-center title">{{ coupon.title }}</div>
                <div class="bill-box with-head small">
                    <div class="item">
                        <div class="label">使用条件</div>
                        <div class="value">
                            <span v-if="coupon.type == 0" >无条件</span>
                            <span v-if="coupon.type == 1" >满{{ coupon.value_limit }}元</span>
                        </div>
                    </div>
                    <div class="item">
                        <div class="label">使用范围</div>
                        <div class="value">
                            <span v-if="coupon.use_range == 0" >所有商品</span>
                            <span v-if="coupon.use_range == 1" >部分商品</span>
                        </div>
                    </div>
                    <div class="item">
                        <div class="label">限量</div>
                        <div class="value">
                            <span v-if="coupon.receive_count_limit > 0" >每人可领{{ coupon.receive_count_limit }}次</span>
                            <span v-else >无</span>
                        </div>
                    </div>
                    <div class="item">
                        <div class="label">售价</div>
                        <div class="value color-danger">
                            {{ coupon.price_points }}积分
                        </div>
                    </div>
                </div>
                <h5 style="margin: 1rem 0 0.5rem 0">使用规则</h5>
                <div style="line-height: 2" class="small">
                    <p class="wrap-pre">{{ coupon.desc }}</p>
                    <div v-if="coupon.use_range > 0">
                        <div v-if="coupon.products.length > 0">
                        <p>以下商品{{ coupon.use_range == 1 ? "可用" : "不可用" }}：</p>
                        <p v-for="record in coupon.products" :key="record.id">
                            《{{ record.product.name }}》
                        </p>
                        </div>
                        <div v-if="coupon.categories.length > 0">
                        <p>以下类目{{ coupon.use_range == 1 ? "可用" : "不可用" }}：</p>
                        <span v-for="record in coupon.categories" :key="record.id"
                            >《{{ record.category.name }}》</span
                        >
                        </div>
                    </div>
                    <p class="">领取时间：{{ coupon.start_at }} ~ {{ coupon.end_at }}</p>
                </div>
                <md-button type="primary" round @click="receiveCoupon(coupon.id)" class="mt-1 md" >确认领取</md-button>
            </div>
        </md-landscape>

        <md-popup v-model="exchangeInfoVisible" position="bottom" class="exchange-pop" >
            <div class="account">
                <div class="block-title">
                    <span>兑换商品</span>
                    <a @click="exchangeInfoVisible = false" class="close"><md-icon name="close"></md-icon></a>
                </div>
                <div class="block-item">
                    <div class="product-item">
                        <img :src="$store.state.assetUrl + exchangeInfo.sku_info.product.img" alt="">
                        <div class="info">
                            <div class="name text-ellipsis-2">{{exchangeInfo.sku_info.product.name}}</div>
                            <div class="desc">{{exchangeInfo.sku_info.spec_desc}}</div>
                            <div class="desc">需要<span class="color-danger">{{exchangeInfo.price_points}} 积分</span></div>
                        </div>
                        <div class="btns">
                            <div>每人限量x{{exchangeInfo.limit_count}}</div>
                            <div>剩余 {{exchangeInfo.count - exchangeInfo.exchanged_count}}</div>
                        </div>
                    </div>
                </div>
                <div class="account-panel" v-if="exchangeInfo.sku_info.product.type == 0">
                    <div class="item-title"><span>授权信息</span></div>
                    <md-input-item
                        preview-type="text"
                        v-model="exchangeForm.account.last_name"
                        title="名字"
                        placeholder="名字"
                        is-title-latent
                    >
                        <span slot="right">请输入英文字母</span>
                    </md-input-item>
                    <md-input-item
                        preview-type="text"
                        v-model="exchangeForm.account.first_name"
                        title="姓氏"
                        placeholder="姓氏"
                        is-title-latent
                        >
                        <span slot="right">请输入英文字母</span>
                    </md-input-item>
                    <md-input-item
                        preview-type="text"
                        v-model="exchangeForm.account.email"
                        title="输入邮箱地址"
                        placeholder="授权邮箱"
                        is-title-latent
                        >
                    </md-input-item>
                </div>

                <div class="address-panel" v-else>
                    <div class="item-title"><span>收货信息</span></div>
                    <md-input-item
                        title="收件人"
                        placeholder="填写收件人姓名"
                        v-model="exchangeForm.address.consignee"
                    ></md-input-item>
                    <md-input-item
                        title="联系电话"
                        placeholder="填写手机号码"
                        v-model="exchangeForm.address.mobile"
                    ></md-input-item>
                    <md-input-item
                        title="省份"
                        placeholder="填写省份"
                        v-model="exchangeForm.address.province"
                    ></md-input-item>
                    <md-input-item
                        title="城市"
                        placeholder="填写城市"
                        v-model="exchangeForm.address.city"
                    ></md-input-item>
                    <md-input-item
                        title="区域"
                        placeholder="填写区域"
                        v-model="exchangeForm.address.area"
                    ></md-input-item>
                    <md-input-item
                        title="地址"
                        placeholder="填写详细地址"
                        v-model="exchangeForm.address.address"
                    ></md-input-item>
                    <md-input-item
                        title="邮编"
                        placeholder="填写邮政编码"
                        v-model="exchangeForm.address.zipcode"
                    ></md-input-item>
                    
                </div>

                <div class="mt-1">
                    <md-button type="primary" round class="md" @click="exchange">确认兑换</md-button>
                </div>
            </div>
        </md-popup>

        <md-popup v-model="exchangeSuccessVisible" position="bottom">
            <div class="exchange-success">
                <div class="block-title">
                    <span>兑换商品</span>
                </div>
                <div class="desc-icon">
                    <md-icon name="success"></md-icon>
                </div>
                <div class="desc">恭喜，您已成功兑换商品！</div>
                <div class="btns">
                    <md-button  type="black" round class="md" @click="exchangeSuccessVisible = false"  >继续兑换</md-button>
                    <md-button  type="primary" round class="md" @click="$helper.goPage('/order/' + orderSn)">去查看</md-button>
                </div>
            </div>
        </md-popup>
    </div>
</template>

<script>
let self;
export default {
    name: "exchange",
    components: {},
    data() {
        return {
            coupons:[],
            coupon:{},
            couponVisible:false,
            exchangeInfo:{
                sku_info:{
                    product:{}
                }
            },
            exchangeForm:{
                id:0,
                account:{},
                address:{}
            },
            exchangeProducts:[],
            exchangeInfoVisible:false,
            exchangeSuccessVisible:false,
            orderSn:'',
            id:0
        };
    },
    mounted(){
        self = this;
        this.$store.dispatch('hideBottomBar');
        document.title = '积分兑换中心'

        this.id = this.$route.query.id || 0;
        this.getCoupons();
        this.getExchangeProducts();

    },
    beforeDestroy(){
        
    },
    methods:{
        getCoupons(){
            
            this.$http.get('listCoupons').then((res)=>{
                let coupons = [];
                res.data.map((v)=>{
                    if(v.price_points > 0){
                        coupons.push(v);
                    }
                })
                self.coupons = coupons;
            });
        },
        getExchangeProducts(){
            
            this.$http.get('listExchangeProducts').then((res)=>{
                self.exchangeProducts = res.data;
                if(self.id > 0){
                    self.exchangeProducts.map((v)=>{
                        if(v.id == self.id){
                            self.showExchangeInfo(v)
                        }
                    })
                }
            });
        },
        receiveCoupon(id){
            
            this.$http.post('user/receiveCoupon',{key:id,type:'id'}).then((res)=>{
                self.$toast.succeed(res.msg)
            });
        },
        showExchangeInfo(record){
            if(record.exchanged_count >= record.count){
                self.$toast.failed('您来晚了，已被兑换光了');
                return;
            }
            
            this.$http.get('user/getExchangeInfo?id='+record.id).then((res)=>{
                self.exchangeForm.id = record.id;
                self.exchangeForm.account = res.data.account || {}
                self.exchangeForm.address = res.data.address || {}
                self.exchangeInfo = {...res.data.info};
                self.exchangeInfoVisible = true;
                self.exchangeStep = 1;
            });
        },
        exchange(){
            this.$http.post('user/exchangeProduct',this.exchangeForm).then((res)=>{
                self.orderSn = res.data.order_sn;
                self.$store.dispatch('setUserInfo', res.data.user_info)
                self.exchangeInfoVisible = false;
                self.exchangeSuccessVisible = true;
            });
        },
        showCoupon(coupon_id){
            this.couponVisible = true;
            this.$http.get('getCoupon',{params:{id:coupon_id}}).then((res)=>{
                self.coupon = res.data;
            });
        }
    }
};
</script>
<style>

</style>
<style scoped lang='less' >
@import "../assets/reset.less";
.page-wrap{
    background: rgba(0, 0, 0, 0.01);
    padding-bottom: 1rem;
}
.valid-box{
    flex: 2;
    background: #fff;
    padding: 0.7rem;
    display: flex;
}
.valid-box .item{
    flex: 1;
    text-align: center;
    position: relative;
}
.valid-box .item:first-child:after{
    content: '';
    display: block;
    position: absolute;
    right: 0;
    width: 1px;
    height: 20px;
    top: calc(50% - 10px);
    background: rgba(0, 0, 0, 0.06);
}
.valid-box .count{
    font-size: 32px;
    color: rgb(36, 69, 131);
}
.valid-box .tag{
    font-size: 12px;
    border-radius: 10px;
    padding: 0 8px;
    color: #fff;
    background: rgb(36, 69, 131);
}
.valid-box .item:last-child .count{
    color: rgba(36, 69, 131,0.7);
}
.valid-box .item:last-child .tag{
    color: rgb(36, 69, 131);
    background: rgba(36, 69, 131,0.05);
}
.coupon-item {
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-left: 0;
    margin: 0.7rem 0;
    border-radius: 10px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.05), 0 5px 10px rgba(0, 0, 0, 0.02), 0 1px 3px rgba(0, 0, 0, 0.01);
    overflow: hidden;
}
.coupon-item.disable .info img{
    display: none;
}
.coupon-item.disable .money{
    color: rgba(0, 0, 0, 0.6);
}
.coupon-item .top{
    display: flex;
}

.coupon-item .bottom{
    background: @primary-color;
    color: #fff;
    display: flex;
    font-size: 12px;
    padding: 0.3rem 0.7rem;
}
.coupon-item .bottom>div{
    flex: 1;
    line-height: 20px;
}
.coupon-item .bottom>div span{
    vertical-align: middle;
    display: inline-block;
}
.coupon-item .bottom div:last-child{
    text-align: right;
}
.coupon-item .bottom svg{
    height: 20px;
    vertical-align: middle;
    margin-right: 5px;
}
.coupon-item .money {
  color: rgb(36, 69, 131);
  text-align: center;
  height: 70px;
  width: 120px;
  font-weight: 500;
  padding-top: 0.5rem;
}
.coupon-item .money .value {
  font-size: 32px;
  line-height: 1;
}
.coupon-item .info {
  padding: 0.5rem 1rem;
  width: calc(100% - 120px);
  font-size: 12px;
  position: relative;
}
.coupon-item .info .tip-icon{
    position: absolute;
    right: 0.7rem;
    top: 1rem;
    height: 40px;
}
.coupon-item .info .limit{
    color: rgba(0, 0, 0, 0.6);
    line-height: 1.4;
}
.coupon-item .info .limit-count{
    color: #999;
}
.coupon-item .info .title{
    font-size: 14px;
    color: #000;
}


.coupon-info {
  background: #fff;
  border-radius: 15px;
  padding: 1rem;

}
.coupon-info .title {
  margin-bottom: 1rem;
}
.coupon-info .money {
  font-size: 32px;
  text-align: center;
}
.coupon-info .md-tag {
  margin: 5px;
}

.product-item{
    display: flex;
    margin: 0.7rem 0;
    border-radius: 10px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.05), 0 5px 10px rgba(0, 0, 0, 0.02), 0 1px 3px rgba(0, 0, 0, 0.01);
    overflow: hidden;
    padding: 0.7rem;
}
.product-item img{
    height: 80px;
    width: 80px;
}
.product-item .desc{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
}
.product-item .info{
    width: calc(100% - 160px);
    padding: 5px 0.5rem 0 0.5rem;
}
.product-item .info .name{
    line-height: 1.2;
    margin-bottom: 0.4rem;
    word-break: break-all;
    font-size: 13px;
    font-weight: 500;
}
.product-item .btns{
    width: 80px;
    text-align: center;
    padding-top: 10px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
}
.product-item .md-tag {
  margin-bottom: 10px;
}

.item-title{
    padding: 1rem 0.5rem;
    color: rgba(0, 0, 0, 0.9);
}
.item-title span{
    position: relative;
}
.item-title span::before{
    background: rgba(0, 0, 0, 0.9);
    width: 3px;
    height: 12px;
    border-radius: 3px;
    position: absolute;
    display: block;
    content: '';
    left: -10px;
    top: 5px;
}

.exchange-success .desc-icon {
  text-align: center;
  color: #37e492;
  margin-top: 2rem;
}
.exchange-success .desc-icon .md-icon {
  font-size: 42px;
}
.exchange-success .desc {
  text-align: center;
  margin: 1rem 0;
}
.exchange-success .btns {
  display: flex;
  margin-top: 2rem;
}
.exchange-success .btns .md-button:first-child {
  margin-right: 0.5rem;
}
</style>
