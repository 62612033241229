<template>
  <div class="grid-content">
    <div class="top-box">
      <div class="back-icon" @click="$router.back()">
          <md-icon name="arrow-left" size="lg"></md-icon>
      </div>
    </div>
    <div class="bw-bg"><img src="../assets/grid/title.png" alt=""></div>
    <div class="points-panel">
      <span>当前积分:{{user.points}}，每次抽奖-{{points}}积分</span>
    </div>
    <!-- 抽奖弹窗 -->
    <div class="turntable">
      <!-- 礼品框 -->
      <div class="present-content">
        <template v-for="(item, index) in awards">
          <div class="item" :key="index" :class="{active: positionMap[current] == index}">
            <div class="goods" >
              <div class="goods-top">
                <img :src="$store.state.assetUrl + item.img" />
              </div>
              <div class="goods-bottom text-ellipsis">
                {{item.name}}
              </div>
            </div>
          </div>
          <div class="item" :key="'s_'+index" v-if="index == 3">
            <div class="start-btn" @click="drawAward">
              <div class="label">抽奖</div>
              <div class="desc">免费x{{user.luck_draw_chance}}</div>
            </div>
          </div>
        </template>
      </div>
      <!-- 抽奖按钮 -->
    </div>
    <div class="award-log-panel">
      <md-icon name="volumn" size="sm"></md-icon>
      <div class="list" :style="{'transform': 'translateY(-'+awardLoopTop+'vw)'}" ref="loopList">
        <div class="item text-ellipsis" v-for="(item, index) in awardLogs" :key="index">{{item.remark}}</div>
      </div>
    </div>

    <div class="rule-panel">
      <div class="title">活动规则</div>
      <p>1. 本活动“艾维商城”的用户均可参加；</p>
      <p>2. 奖品共1万份，中奖率30%；</p>
      <p>3. 每次抽奖需消耗{{points}}积分，如果有免费抽奖次数将优先扣除；</p>
      <p>4. 获得免费抽奖次数请戳这里--> <a class="go-btn" @click="$router.push('/signIn')">《去看看》</a></p>
      <p>5. 如果发现用户存在违规操作，本网站可能在不告知用户的情况下取消中奖资格。</p>
    </div>
  </div>
</template>

<script>
let self
export default {
  name: "index",
  data() {
    return {
      order:[],
      animate: false,
      initSpeed: 200,  // 初始速度
      speed: null,  // 变化速度
      diff: 20,  // 速度变化的值，值越大，变化地越快
      points:0,
      awards: {},
      awardInfo:{
        position:-1,
        name:"",
        type:-1
      },
      time: 0,  // 记录开始抽奖的时间
      current: -1,  // 当前转动的位置
      isRuningLucky: false,  // 是否正在抽奖
      awardLogs: [],
      awardLoopTop:0,
      awardLoopTimer:null,
      positionMap:[0,1,2,4,7,6,5,3],
      user:{
        points:0
      },
      address:{},
      account:{}
    };
  },
  created() {
  },
  beforeDestroy(){
    clearInterval(self.awardLoopTimer)
  },
  mounted() {
    self = this;
    document.title = "幸运大抽奖";
    this.$store.dispatch("hideBottomBar");
    this.getData();
    this.getAwardLogs();
  },
  methods: {
    //中奖滚动名单列表
    getAwardLogs(){
      this.$http.get('user/listAwardLogs').then((res)=>{
         this.awardLogs = res.data.data
         this.$nextTick(()=>{
           self.awardLogsLoop();
         })
      })
    },
    //获取游戏基本信息
    getData(){
      this.$http.get('user/luckData').then((res)=>{
        if(!res.data.switch){
          this.$dialog.alert({
              title: '提示',
              content: '活动暂未开放',
              confirmText: '确定',
              onConfirm: () => {
                this.$router.back();
              }
          })
          return;
        }
        this.awards = res.data.award_list
        this.points = res.data.points
        this.user = res.data.user
        this.address = res.data.address;
        this.account = res.data.account;
      })
    },
    resetDraw(){
      this.initSpeed = 200;
      this.speed = this.initSpeed;
      // 开始抽奖
      this.isRuningLucky = true;
      this.time = Date.now();
      this.current = 0;
      this.awardIndex = -1;
      this.awardName = "";
    },
    //执行抽奖
    drawAward() {
      if(!this.address){
        this.$dialog.alert({
            title: '提示',
            content: '请完善收获地址',
            confirmText: '确定',
            onConfirm: () => {
              this.$router.push("/address?opt=addrFromGrid");
            }
        })
        return;
      }
      if(!this.account){
        this.$dialog.alert({
            title: '提示',
            content: '请完善授权信息',
            confirmText: '确定',
            onConfirm: () => {
              this.$router.push("/my-account?opt=showAccountFromGrid");
            }
        })
        return;
      }
      if(this.isRuningLucky){
        return false;
      }
      this.resetDraw()
      this.$http.post('user/drawAward').then((res)=>{
        this.awardInfo = res.data
        this.getData();
        this.move();
      }).finally(()=>{
        this.isRuningLucky = false;
      })
    },
    move() {
      let timer = setTimeout(() => {
        this.current++;
        if (this.current > 7) {
          this.current = 0;
        }
        // 若抽中的奖品id存在，则开始减速转动
        if (this.awardInfo.position > -1 && (Date.now() - this.time) / 1000 > 2) {
          // 转动减速
          this.speed += this.diff;
          // 若转动时间超过4秒，并且奖品id等于小格子的奖品id，则停下来
          if ((Date.now() - this.time) / 1000 > 4 && this.awardInfo.position == this.positionMap[this.current]) {
            clearTimeout(timer);
            this.isRuningLucky = false;
            // 这里写停下来要执行的操作（弹出奖品框）
            //type=  1 表示商品 2优惠券 3 谢谢参与
            setTimeout(()=>{
              if(this.awardInfo.type == 3){
                this.$dialog.alert({
                    title: '谢谢参与',
                    content: '很遗憾，您未中奖',
                    confirmText: '确定',
                    onConfirm: () => {}
                })
              }else{
                this.getAwardLogs()
                if(this.awardInfo.type == 1){
                  this.$dialog.confirm({
                      title: '恭喜中奖',
                      content: '您抽中了《' + this.awardInfo.name+'》您可以到订单中心查看详情',
                      confirmText: '继续抽奖',
                      cancelText:"去查看",
                      onCancel:()=>{
                        this.$router.push("/orders/all")
                      },
                      onConfirm: () => {}
                  })
                }else{
                  this.$dialog.confirm({
                      title: '恭喜中奖',
                      content: '您抽中了《' + this.awardInfo.name+'》您可以到我的优惠券中查看',
                      confirmText: '继续抽奖',
                      cancelText:"去查看",
                      onCancel:()=>{
                        this.$router.push("/my-coupons")
                      },
                      onConfirm: () => {}
                  })
                }
                
              }
            },500)
            return;
          }
        } else {
          if (this.speed >= 50) {
            this.speed -= this.diff; // 转动加速
          }
        }
        this.move();
      }, this.speed);
    },
    awardLogsLoop(){
      //只有一条记录时不需要滚动
      if(self.awardLogs.length <= 1){
        return;
      }

      self.awardLoopTimer && clearInterval(self.awardLoopTimer)
      let i = 0;
      self.awardLoopTimer = setInterval(()=>{
        i++;
        if(i >= self.awardLogs.length){
          i = 0;
          self.awardLoopTop = 0;
        }else{
          self.awardLoopTop = i*5.3333;
        }
      },3000)
    }
  }
};
</script>
<style lang="less" scoped>
.top-box{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    padding: 14px;
}
.top-box .back-icon{
    width: 30px;
    text-align: left;
    height: 34px;
    line-height: 38px;
    color: #fff;
}
.bw-bg{
  text-align: center;
  padding: 50px 0 30px 0;
}
.bw-bg img{
  width: 80%;
}
.points-panel{
  font-size: 12px;
  text-align: center;
  margin-bottom: 15px;
  color: #fff;
}
.points-panel span{
  background: rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  padding: 2px 10px;
}
.grid-content {
  background-color: #F63857;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 30px;
}

// 抽奖
.grid-content .turntable {
  position: relative;
  background: url(../assets/grid/bg.png) no-repeat center;
  background-size: contain;
  height: 321px;
  width: 321px;
  margin: 0 auto;
}

.present-content {
  width: calc(100% - 47px);
  height: calc(100% - 47px);
  position: absolute;
  top: 25px;
  left: 26px;
  z-index: 100;
  font-size: 12px;
  padding: 3px;

}
.present-content .item{
  padding: 2px;
  width: 88px;
  height: 88px;
  display: inline-block;
  vertical-align: middle;
}
.present-content .item .goods,.start-btn {
  padding: 10px;
  position: relative;
  text-align: center;
  height: 100%;
  border-radius: 7px;
}

.present-content .item .goods::before, .start-btn::before{
  position: absolute;
  background: #fff;
  height: 78px;
  width: 100%;
  content: "";
  z-index: -1;
  left: 0;
  top: 0;
  border-radius: 7px;
  transition: all 0.2s;
}
.present-content .item .goods::after, .start-btn::after{
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  height: 52px;
  width: 100%;
  content: "";
  z-index: -2;
  left: 0;
  bottom: 0;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  transition: all 0.2s;
}
.present-content .item.active{
  color: #5a000a;
}
.present-content .item.active .goods::before{
  background: rgba(255, 240, 87, 1);
  box-shadow: 0 0 15px rgba(255, 240, 87, 1);
}
.present-content .item.active .goods::after{
  background: rgba(255, 240, 87, 0.6);
}
.start-btn{
  color: #fff;
  user-select: none;
}
.start-btn .label{
  font-size: 22px;
  font-weight: bold;
}
.start-btn .desc{
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
}
.start-btn::before{
  background: #ff3a59;
  box-shadow: 0 1px 0 0.5px rgba(255, 255, 255, 1);
  transition: all 0.2s;
}
.start-btn:active:before{
  box-shadow: 0 1px 0 0.5px rgba(255, 255, 255, 1), 0 1px 0 2px rgba(234, 0, 37, 1) inset;
}
.start-btn::after{
  background: #ea0025;
}
.present-content .goods .goods-top img{
  width: 45px;
  height: 45px;
  vertical-align: middle;
}
.present-content .goods .goods-bottom {
  font-size: 12px;
}


@keyframes btn-animation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.85);
  }
}

.notice-img {
  position: absolute;
  top: 6px;
  left: 27.5px;
}

.award-log-panel{
  display: flex;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  width: 300px;
  margin: 15px auto;
  font-size: 12px;
  padding: 2px 10px;
  color: #fff;
  height: 22px;
  overflow: hidden;
}
.award-log-panel .md-icon{
  line-height: 18px;
  width: 18px;
  text-align: center;
}
.award-log-panel .list{
  width: calc(100% - 20px);
  transition: all 0.2s;
 
}
.award-log-panel .list .item{
 height: 20px;
}

.rule-panel{
  width: 300px;
  background: #FFF;
  padding: 10px;
  border-radius: 10px;
  margin: 0 auto;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
}
.rule-panel .title{
  font-size: 14px;
  color: #000;
  margin-bottom: 10px;
}
.go-btn{
  color: #ff3a59;
}
</style>
