<template>
  <div class="page-wrap">
    <div class="user-info" v-if="$store.state.isLogin">
      <img :src="$store.state.assetUrl + $store.state.userInfo.avatar" alt="" @click="showAvatar = true" class="avatar">
      <div>
        <a class="setting-btn" @click="$helper.goPage('/profile')">
          <svg t="1631524597664" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3147"
               width="24" height="24" fill="currentColor">
            <path
                d="M511.92 362.61c82.462 0 149.31 66.848 149.31 149.31s-66.848 149.31-149.31 149.31-149.31-66.848-149.31-149.31 66.848-149.31 149.31-149.31z m0 63.99c-47.12 0-85.32 38.2-85.32 85.32 0 47.12 38.2 85.32 85.32 85.32 47.12 0 85.32-38.2 85.32-85.32 0-47.12-38.2-85.32-85.32-85.32z m253.102-289.076l197.686 342.401a63.99 63.99 0 0 1 0 63.99L765.022 886.316a63.99 63.99 0 0 1-55.417 31.995h-395.37a63.99 63.99 0 0 1-55.417-31.995L61.132 543.915a63.99 63.99 0 0 1 0-63.99l197.686-342.401a63.99 63.99 0 0 1 55.417-31.995h395.37a63.99 63.99 0 0 1 55.417 31.995z m-55.417 31.995h-395.37L116.549 511.92l197.686 342.401h395.37L907.291 511.92 709.605 169.519z"
                p-id="3148"></path>
          </svg>
        </a>
        <div class="nickname">{{ $store.state.userInfo.nickname }} <span
            class="uid">UID#{{ $store.state.userInfo.id }}</span></div>
        <div class="desc">
          <span @click="$helper.goPage('/vip')"><md-tag shape="circle" type="fill" fill-color="rgba(223,185,145,1)"
                                                        font-color="#674623">{{ $store.state.userInfo.level }}</md-tag></span>
          <span>累计{{ $store.state.userInfo.total_points }}积分</span>
        </div>
      </div>
    </div>

    <div class="block-item order">
      <div class="account-stat">
        <div class="item" @click="$helper.goPage('/orders/all')">订单数量：{{ orderCount }}</div>
        <div class="item" @click="$helper.goPage('orders/2')">待评价：<span :class="{'count-light': needCommentCount > 0}">{{ needCommentCount }}</span>
        </div>
      </div>
      <div class="order-stat-box">
        <div class="item" @click="$helper.goPage('/orders/0')">
          <svg t="1631525492130" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4209"
               width="22" height="22">
            <path
                d="M896 192H128c-35.2 0-64 28.8-64 64v512c0 35.2 28.8 64 64 64h768c35.2 0 64-28.8 64-64V256c0-35.2-28.8-64-64-64z m0 576H128V384h768v384zM128 320v-64h768v64H128z m320 288H192v-32h256v32z m-128 64H192v-32h128v32z"
                p-id="4210"></path>
          </svg>
          <div>待支付</div>
        </div>
        <div class="item" @click="$helper.goPage('/orders/1')">
          <svg t="1631525433037" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3996"
               width="22" height="22">
            <path
                d="M768 768H512v-64h256v64z m0-192H576v64h192v-64z m128-288v576c0 17.6-14.4 32-32 32H160c-17.6 0-32-14.4-32-32V288h-0.8l70.4-124.8c11.2-21.6 32.8-35.2 57.6-35.2H768c24 0 46.4 13.6 57.6 35.2L896 288zM528 192v96h304.8l-44.8-77.6c-4-9.6-12.8-16.8-23.2-18.4m-505.6 0c-10.4 1.6-19.2 8.8-23.2 18.4L191.2 288H496V192m336 128H192v512h640V320z"
                p-id="3997"></path>
          </svg>
          <div>待发货</div>
        </div>
        <div class="item" @click="$helper.goPage('/orders/2')">
          <svg t="1631525602578" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4597"
               width="22" height="22">
            <path
                d="M512 128c52 0 102.4 10.4 149.6 30.4 45.6 19.2 86.4 47.2 122.4 82.4s63.2 76 82.4 122.4c20 47.2 30.4 97.6 30.4 149.6s-10.4 102.4-30.4 149.6c-19.2 45.6-47.2 86.4-82.4 122.4s-76.8 63.2-122.4 82.4c-47.2 20-97.6 30.4-149.6 30.4s-102.4-10.4-149.6-30.4c-45.6-19.2-86.4-47.2-122.4-82.4s-63.2-76.8-82.4-122.4C138.4 614.4 128 564 128 512s10.4-102.4 30.4-149.6C177.6 316.8 204.8 276 240 240s76-63.2 122.4-82.4C409.6 138.4 460 128 512 128m0-64C264.8 64 64 264.8 64 512s200.8 448 448 448 448-200.8 448-448S759.2 64 512 64zM288 384c0 35.2 28.8 64 64 64s64-28.8 64-64-28.8-64-64-64-64 28.8-64 64z m320 0.8c0 35.2 28.8 64 64 64s64-28.8 64-64-28.8-64-64-64-64 28-64 64zM512 768c46.4 0 92-15.2 132-44.8 37.6-28 68.8-67.2 91.2-114.4 8-16 0.8-35.2-15.2-42.4-16-8-35.2-0.8-42.4 15.2-17.6 37.6-43.2 68.8-72 90.4-28 20.8-60.8 32-93.6 32s-64.8-11.2-93.6-32.8c-28.8-21.6-53.6-52.8-72-90.4-8-16-27.2-22.4-42.4-15.2-16 8-22.4 26.4-15.2 42.4 22.4 47.2 54.4 86.4 91.2 114.4 40 30.4 85.6 45.6 132 45.6z"
                p-id="4598"></path>
          </svg>
          <div>已完成</div>
        </div>
        <div class="item" @click="$helper.goPage('/orders/all')">
          <svg t="1631525562956" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4422"
               width="22" height="22">
            <path
                d="M800 192H640c0-70.4-57.6-128-128-128s-128 57.6-128 128H224c-17.6 0-32 14.4-32 32v704c0 17.6 14.4 32 32 32h576c17.6 0 32-14.4 32-32V224c0-17.6-14.4-32-32-32z m-288-32c17.6 0 32 14.4 32 32s-14.4 32-32 32-32-14.4-32-32 14.4-32 32-32z m256 736H256V256h64v64h384v-64h64v640z m-64-384H320v-64h384v64z m0 128H320v-64h384v64zM576 768H320v-64h256v64z"
                p-id="4423"></path>
          </svg>
          <div>全部订单</div>
        </div>
      </div>
    </div>
    <div class="block-item">
      <div class="title" @click="$helper.goPage('/points')">
        <span>积分 </span><span class="small">可用{{ info.points }}</span>
        <span class="more-view">
                    明细
                    <md-icon name="arrow-right" size="small"></md-icon>
                </span>
      </div>
      <div class="small color-gray">
        <md-steps
            class="get-points-step"
            :steps="steps"
            :current="currentStep"
        >
        </md-steps>
      </div>
      <div>
        <md-button class="md" round type="success" @click="$helper.goPage('/exchange')">积分兑换中心</md-button>
      </div>
    </div>
    <div class="block-item">
      <div class="title" @click="$helper.goPage('/my-coupons')">
        <span>优惠券 </span><span class="small">可用{{ couponCount }}</span>
        <span class="more-view">
                    全部
                    <md-icon name="arrow-right" size="small"></md-icon>
                </span>
      </div>
      <div>
        <md-button class="md" round type="warning" @click="$helper.goPage('/coupons')">领券中心</md-button>
      </div>
    </div>
    <div class="deposit-box">
      <div class="title"> 拍卖保证金</div>
      <div class="moeny">

        <span
            v-if="info.auction_deposit_status == 0"><small>{{ deposit_currency.code }}</small>{{ deposit_currency.amount }}</span>
        <span v-else>{{ info.auction_deposit.currency.code }}{{ info.auction_deposit.currency.amount }}</span>
      </div>
      <div class="img" v-if="info.auction_deposit_status != 0">
        <svg t="1633772766878" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="23189"
             width="55" height="55">
          <path
              d="M564.887 14.157L917.113 217.35C949.84 236.226 970 271.108 970 308.858v406.284c0 37.75-20.161 72.632-52.887 91.507l-352.226 203.194c-32.727 18.876-73.047 18.876-105.774 0L106.887 806.65C74.16 787.774 54 752.892 54 715.142V308.858c0-37.75 20.161-72.632 52.887-91.507L459.113 14.157c32.727-18.876 73.047-18.876 105.774 0z"
              fill="#FFA0A0" p-id="23190"></path>
          <path
              d="M663.243 316L968 620.062v93.989c-0.019 37.6-19.996 72.36-52.466 91.292L657.22 956 510.759 723.037c-42.322 9.145-47.077-28.017-14.266-111.327 15.006-38.22 102.08 20.616 125.432-26.801 4.28-8.722 8.137-20.616 11.624-35.63 3.17-13.849-140.015 31.771-137.109 0 1.321-13.902 23.988-26.642 47.341-64.86 10.198-16.652 44.277 12.686 78.144-20.564 8.824-8.617-45.227-25.691-39.626-54.078 3.17-16.123 30.116-47.417 80.944-93.777z"
              fill="#FB7474" p-id="23191"></path>
          <path
              d="M586.588 428.974H618.6c17.49 0 31.694 14.488 31.694 32.36 0.206 17.675-13.976 32.178-31.694 32.41h-76.848v41.348H618.6c17.49 0 31.694 14.487 31.694 32.358a31.949 31.949 0 0 1-9.129 22.729 32.101 32.101 0 0 1-22.565 9.63h-76.848v89.832a31.949 31.949 0 0 1-9.147 22.747 32.101 32.101 0 0 1-22.6 9.612c-17.696-0.232-31.87-14.703-31.692-32.359V599.81h-76.849c-17.717-0.203-31.922-14.683-31.746-32.359 0-17.87 14.204-32.358 31.693-32.358h76.849v-41.347H401.41c-17.717-0.233-31.899-14.736-31.693-32.412 0-17.871 14.204-32.359 31.693-32.359h32.012l-73.139-74.657c-12.377-12.786-12.377-33.056 0-45.841A31.306 31.306 0 0 1 382.728 299a31.306 31.306 0 0 1 22.445 9.476l104.832 107.121 104.831-107.016a31.306 31.306 0 0 1 22.445-9.475 31.306 31.306 0 0 1 22.445 9.475c12.365 12.75 12.365 32.986 0 45.736l-73.138 74.657z"
              fill="#FFFFFF" p-id="23192"></path>
        </svg>
      </div>
      <div class="img" v-if="info.auction_deposit_status == 0">
        <svg t="1633772766878" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="23189"
             width="55" height="55">
          <path
              d="M564.887 14.157L917.113 217.35C949.84 236.226 970 271.108 970 308.858v406.284c0 37.75-20.161 72.632-52.887 91.507l-352.226 203.194c-32.727 18.876-73.047 18.876-105.774 0L106.887 806.65C74.16 787.774 54 752.892 54 715.142V308.858c0-37.75 20.161-72.632 52.887-91.507L459.113 14.157c32.727-18.876 73.047-18.876 105.774 0z"
              fill="#ccc" p-id="23190"></path>
          <path
              d="M663.243 316L968 620.062v93.989c-0.019 37.6-19.996 72.36-52.466 91.292L657.22 956 510.759 723.037c-42.322 9.145-47.077-28.017-14.266-111.327 15.006-38.22 102.08 20.616 125.432-26.801 4.28-8.722 8.137-20.616 11.624-35.63 3.17-13.849-140.015 31.771-137.109 0 1.321-13.902 23.988-26.642 47.341-64.86 10.198-16.652 44.277 12.686 78.144-20.564 8.824-8.617-45.227-25.691-39.626-54.078 3.17-16.123 30.116-47.417 80.944-93.777z"
              fill="#aaa" p-id="23191"></path>
          <path
              d="M586.588 428.974H618.6c17.49 0 31.694 14.488 31.694 32.36 0.206 17.675-13.976 32.178-31.694 32.41h-76.848v41.348H618.6c17.49 0 31.694 14.487 31.694 32.358a31.949 31.949 0 0 1-9.129 22.729 32.101 32.101 0 0 1-22.565 9.63h-76.848v89.832a31.949 31.949 0 0 1-9.147 22.747 32.101 32.101 0 0 1-22.6 9.612c-17.696-0.232-31.87-14.703-31.692-32.359V599.81h-76.849c-17.717-0.203-31.922-14.683-31.746-32.359 0-17.87 14.204-32.358 31.693-32.358h76.849v-41.347H401.41c-17.717-0.233-31.899-14.736-31.693-32.412 0-17.871 14.204-32.359 31.693-32.359h32.012l-73.139-74.657c-12.377-12.786-12.377-33.056 0-45.841A31.306 31.306 0 0 1 382.728 299a31.306 31.306 0 0 1 22.445 9.476l104.832 107.121 104.831-107.016a31.306 31.306 0 0 1 22.445-9.475 31.306 31.306 0 0 1 22.445 9.475c12.365 12.75 12.365 32.986 0 45.736l-73.138 74.657z"
              fill="#FFFFFF" p-id="23192"></path>
        </svg>
      </div>
      <div class="text-right btns">
        <md-button size="small" round plain type="white" v-if="info.auction_deposit_status == 0"
                   @click="$helper.orderToPay('','deposit')">立即缴纳
        </md-button>
        <md-button size="small" round plain type="white" v-if="info.auction_deposit_status == 1"
                   @click="showRefundConfirm">申请退款
        </md-button>
        <md-button size="small" round plain type="white" v-if="info.auction_deposit_status == 2">退款中</md-button>
      </div>
    </div>


    <div class="block-item">
      <div class="title">
        <span>其他</span>
      </div>
      <div class="other-item" style="margin-top:-0.7rem" @click="$helper.goPage('/order-groupons/all')">
        <span>我的拼团</span>
        <span class="more-view"><md-icon name="arrow-right" size="small"></md-icon></span>
      </div>
      <div class="other-item" @click="$helper.goPage('/my-auctions')">
        <span>我的拍卖</span>
        <span class="more-view"><md-icon name="arrow-right" size="small"></md-icon></span>
      </div>
      <div class="other-item" @click="$helper.goPage('/vip')">
        <span>我的会籍</span>
        <span class="more-view"><md-icon name="arrow-right" size="small"></md-icon></span>
      </div>
      <div class="other-item" @click="$helper.goPage('/address')">
        <span>收货地址</span>
        <span class="more-view"><md-icon name="arrow-right" size="small"></md-icon></span>
      </div>
      <div class="other-item" @click="accountVisible = true">
        <span>授权信息</span>
        <span class="more-view"><md-icon name="arrow-right" size="small"></md-icon></span>
      </div>
      <div class="other-item" @click="showInvoice">
        <span>我的发票</span>
        <span class="more-view"><md-icon name="arrow-right" size="small"></md-icon></span>
      </div>
      <div class="other-item" @click="$helper.goPage('/cdkey')">
        <span>CDKEY兑换</span>
        <span class="more-view"><md-icon name="arrow-right" size="small"></md-icon></span>
      </div>
      <div class="other-item" @click="$helper.goPage('/my-collect-orders')">
        <span>我的账单</span>
        <span class="more-view"><md-icon name="arrow-right" size="small"></md-icon></span>
      </div>
      <a class="other-item"
         href="https://chat.aiviysoft.com/chat.php?s=2&linkid=OTc2YzQ4ZmI5MWE0NDcwYWUzZGZkY2ZiZTgwMTZjZDQ_">
        <span>联系客服</span>
        <span class="more-view">工作日 9:00 ~ 22:00<md-icon name="arrow-right" size="small"></md-icon></span>
      </a>
      <div class="other-item" @click="$helper.goPage('/ranking')">
        <span>排行榜</span>
        <span class="more-view"><md-icon name="arrow-right" size="small"></md-icon></span>
      </div>
      <div class="other-item" @click="$helper.goPage('/commission')">
        <span>我的佣金</span>
        <span class="more-view"><md-icon name="arrow-right" size="small"></md-icon></span>
      </div>
      <div v-if="showGrid" class="other-item" @click="$helper.goPage('/luckyGrid')">
        <span>幸运抽奖</span>
        <span class="more-view"><md-icon name="arrow-right" size="small"></md-icon></span>
      </div>
      <div v-if="showSign"  class="other-item" @click="$helper.goPage('/signIn')">
        <span>签到</span>
        <span class="more-view"><md-icon name="arrow-right" size="small"></md-icon></span>
      </div>

      <div class="other-item"  @click="$helper.goPage('/wishList')">
        <span>心愿单</span>
        <span class="more-view"><md-icon name="arrow-right" size="small"></md-icon></span>
      </div>

    </div>





    <div class="mt-1 container">
      <div class="block-title"><span>猜你喜欢</span></div>
      <div class="guess-likes fixed">
        <template v-for="item in random_products">
          <div class="item" @click="$helper.goPage('/item/' + item.slug)" :key="item.id">
            <div class="img"><img :src="$store.state.assetUrl + item.img" alt=""></div>
            <div class="text-ellipsis ">{{ item.name }}</div>
            <div>
              <md-tag shape="circle" type="ghost" font-color="#555">评分 {{ item.rate }}星</md-tag>
            </div>
            <div class="price">
              <small>{{ item.currency.code }}</small>{{ item.currency.amount }}
              <del v-if="item.market_price > 0">
                <small>{{ item.market_currency.code }}</small>{{ item.market_currency.amount }}
              </del>
            </div>
          </div>
        </template>
      </div>
    </div>

    <md-popup v-model="accountVisible" position="bottom" class="account-pop">
      <div class="account">
        <div class="block-title">
          <span>授权信息</span>
          <a @click="accountVisible = false" class="close">
            <md-icon name="close"></md-icon>
          </a>
        </div>
        <md-input-item
            preview-type="text"
            v-model="accountForm.last_name"
            title="名字"
            placeholder="名字"
            is-title-latent
        >
          <span slot="right">请输入英文字母</span>
        </md-input-item>
        <md-input-item
            preview-type="text"
            v-model="accountForm.first_name"
            title="姓氏"
            placeholder="姓氏"
            is-title-latent
        >
          <span slot="right">请输入英文字母</span>
        </md-input-item>
        <md-input-item
            preview-type="text"
            v-model="accountForm.email"
            title="输入邮箱地址"
            placeholder="授权邮箱"
            is-title-latent
        >
        </md-input-item>

        <div class="mt-1">
          <md-button type="primary" round class="md" @click="saveAccount">保存</md-button>
        </div>
      </div>
    </md-popup>

    <md-popup v-model="invoiceVisible" position="bottom" class="invoice-pop">
      <div class="block-title">
        <span>发票</span>
        <a @click="invoiceVisible = false" class="close">
          <md-icon name="close"></md-icon>
        </a>
      </div>
      <div class="invoice-panel">
        <md-field-item title="发票类型" solid @click="changeInvoiceType">
          <md-radio :name="0" v-model="invoiceForm.invoice_type" label="普通发票" inline></md-radio>
          <md-radio :name="1" v-model="invoiceForm.invoice_type" label="增值专用" inline></md-radio>
        </md-field-item>
        <md-field-item title="抬头类型" solid>
          <md-radio :name="0" v-model="invoiceForm.head_type" label="个人" inline
                    :disabled="invoiceForm.invoice_type == 1"></md-radio>
          <md-radio :name="1" v-model="invoiceForm.head_type" label="企业" inline></md-radio>
        </md-field-item>
        <md-input-item
            title="发票抬头"
            placeholder="填写发票抬头"
            v-model="invoiceForm.head"
        ></md-input-item>

        <template v-if="invoiceForm.head_type == 1">
          <md-input-item
              title="单位税号"
              placeholder="填写单位税号"
              v-model="invoiceForm.taxes_no"
          ></md-input-item>
          <template v-if="invoiceForm.invoice_type == 1">
            <md-input-item
                title="注册地址"
                placeholder="填写注册地址"
                v-model="invoiceForm.reg_address"
            ></md-input-item>
            <md-input-item
                title="注册电话"
                placeholder="填写注册电话"
                v-model="invoiceForm.reg_phone"
            ></md-input-item>
            <md-input-item
                title="开户银行"
                placeholder="填写开户银行"
                v-model="invoiceForm.bank_name"
            ></md-input-item>
            <md-input-item
                title="银行账号"
                placeholder="填写银行账号"
                v-model="invoiceForm.bank_account"
            ></md-input-item>
          </template>
        </template>
      </div>
      <div class="btns">
        <md-button type="primary" round class="md" @click="saveInvoice">保存</md-button>
      </div>
    </md-popup>

    <md-image-viewer
        v-model="showAvatar"
        :list="[$store.state.assetUrl + info.avatar]"
        :has-dots="false"
    >
    </md-image-viewer>
  </div>
</template>

<script>
let self;
export default {
  name: 'myAccount',
  components: {},
  data() {
    return {
      showAvatar: false,
      loginVisible: false,
      accountVisible: false,
      info: {
        auction_deposit: {
          currency: {}
        }
      },
      showGrid: false,
      showSign: false,
      deposit: 0,
      deposit_currency: {},
      orderCount: 0,
      needCommentCount: 0,
      couponCount: 0,
      steps: [
        {
          name: '下单付款',
        },
        {
          name: '确认收货',
        },
        {
          name: '评价订单',
        },
        {
          name: '获得积分',
        },
      ],
      currentStep: 4,
      random_products: [],
      accountForm: {},
      invoiceForm: {},
      invoiceVisible: false,
      opt:""
    }
  },
  mounted() {
    self = this;

    document.title = "个人中心";
    this.$store.commit('disableShowLoading');
    this.$store.dispatch('showBottomBar', 5);
    this.opt = this.$route.query.opt || "";
    this.getCenter();
  },
  beforeDestroy() {
    this.$store.commit('ableShowLoading');
  },
  methods: {
    getCenter() {
      let data = self.$store.state.pageLoad.ucenter || null;
      this.initData(data);
      this.$http.get('user/center').then((res) => {
        self.$store.dispatch('setUserInfo', res.data.user);
        self.initData(res.data);
        self.$store.commit('setPageLoad', {ucenter: res.data});
      });
    },
    initData(data) {
      if (!data) {
        return;
      }
      self.info = data.user;
      self.accountForm = data.user.last_account || {};
      self.orderCount = data.order_count;
      self.needCommentCount = data.need_comment_count;
      self.couponCount = data.coupon_count;
      self.random_products = data.random_products;
      self.deposit = data.deposit;
      self.deposit_currency = data.deposit_currency;
      self.showSign = data.show_sign;
      self.showGrid = data.show_grid;

      if(this.opt == "showAccountFromGrid"){
        this.accountVisible = true;
      }
    },
    showInvoice() {
      this.invoiceVisible = true;
      this.getInvoice();
    },
    saveAccount() {
      this.$http.post('user/saveAccount', this.accountForm).then((res) => {
        self.$toast.succeed(res.msg);
        self.accountForm = res.data.last_account;
        self.accountVisible = false;
        if(self.opt == "showAccountFromGrid"){
          setTimeout(()=>{
            self.$router.back()
          },1000)
        }
      });
    },
    getInvoice() {
      this.$http.get('user/getInvoice').then((res) => {
        self.invoiceForm = res.data || {};
        self.$helper.nullToEmpty(self.invoiceForm);
      });
    },
    changeInvoiceType() {

      if (this.invoiceForm.invoice_type == 1) {
        this.invoiceForm.head_type = 1;
      }
    },
    saveInvoice() {
      this.$http.post('user/saveInvoice', this.invoiceForm).then((res) => {
        self.$toast.succeed(res.msg);
        self.invoiceVisible = false;
      });
    },
    showRefundConfirm() {
      this.$dialog.confirm({
        title: '确认',
        content: '确认要申请退保证金吗？',
        confirmText: '确定',
        onConfirm: () => self.applyRefund(),
      })
    },
    applyRefund() {
      this.$http.post('user/applyDepositRefund').then((res) => {
        self.$toast.succeed(res.msg);
        self.getCenter();
      });
    }
  }
}
</script>

<style>
.get-points-step.md-steps.md-steps-horizontal {
  padding: 0.5rem 1rem 3rem 1rem;
}

.get-points-step.md-steps .step-wrapper .text-wrapper .name {
  font-size: 12px;

}
</style>
<style scoped lang='less'>
@import "../assets/reset.less";

.page-wrap {
  background: rgba(0, 0, 0, 0.01);
  padding-bottom: 70px;
}

.user-info {
  display: flex;
  padding: 2rem 1rem 1rem 1rem;
  background: linear-gradient(to bottom, rgba(37, 198, 252, 0.06), rgba(37, 198, 252, 0));

  position: relative;
}

.user-info .avatar {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.user-info .nickname {
  padding-left: 1.1rem;
  font-size: 16px;
  font-weight: bold;
}

.user-info .desc {
  padding-left: 0.9rem;
  font-size: 12px;
  margin-top: 9px;
}

.user-info .desc .md-tag {
  margin-right: 5px;
}

.setting-btn {
  color: rgba(0, 0, 0, 0.6);
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.block-item {
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.04), 0 5px 10px rgba(0, 0, 0, 0.02);
  margin: 0.7rem;
  padding: 1rem;
  background: #fff;
}

.block-item .title {
  position: relative;
  margin-bottom: 1rem;
}

.block-item .title > span:first-child {
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 15px;
}

.block-item .title .more-view {
  position: absolute;
  right: -4px;
  top: 0;
  font-size: 12px;
  color: #999;
  display: flex;
  line-height: 20px;
}

.block-item .title .more-view .md-icon {
  font-size: 14px;
  line-height: 20px;
}

.block-item.order {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05), 0 2px 10px rgba(0, 0, 0, 0.02);
}

.account-stat {
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}

.account-stat .item {
  flex: 1;
  text-align: center;
  font-size: 12px;
}

.account-stat .item:first-child {
  border-right: 1px solid rgba(0, 0, 0, 0.03);
}

.order-stat-box {
  display: flex;
}

.order-stat-box .item {
  width: 25%;
  text-align: center;
  font-size: 13px;
}

.other-item {
  position: relative;
  padding: 0.7rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  display: block;
}

.other-item:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.other-item .more-view {
  position: absolute;
  right: -4px;
  top: 0.6rem;
  font-size: 12px;
  color: #999;
  display: flex;
  line-height: 20px;
}

.other-item .more-view .md-icon {
  font-size: 14px;
  line-height: 20px;
}

.deposit-box {
  position: relative;
  background: linear-gradient(45deg, #4194f2, #3f75ea);
  padding: 1.5rem;
  color: #fff;
  text-align: center;
  margin: 0.7rem;
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.04), 0 5px 10px rgba(0, 0, 0, 0.02);
}

.deposit-box .title {
  text-align: right;
  font-size: 15px;
}

.deposit-box .img {
  position: absolute;
  left: 1.5rem;
  bottom: 1.5rem;
}

.deposit-box .moeny {
  text-align: right;
  margin-bottom: 15px;
}

.deposit-box .btns {
  text-align: right;
}

.deposit-box .btns .md-button {
  width: auto;
  display: inline-block;
  padding: 0 1.5rem;
}

.uid {
  font-size: 12px;
  font-weight: normal;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding: 0 4px;
  color: rgba(0, 0, 0, 0.6);
  margin-left: 5px;
}
</style>
