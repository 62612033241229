<template>
  <div class="page-wrap" :class="{'grieve-model': grieve_model}">
    <header :style="{background: 'rgba(0, 0, 0,' + headerAlpha + ')'}" :class="{active:headerAlpha > 0.7}">
          <img class="logo" src="../assets/logo.svg" alt="">
          <div class="nav-search">
              <span class="search-input"  @click="$helper.goPage('/search')">
                  <md-icon name="search"></md-icon> 输入商品关键词
              </span>
              <a class="btn-cart" href="/carts">
                  <i class="icon"><svg t="1630491127273" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11755" width="64" height="64"><path d="M246.4 912a2.1 2.1 0 1 0 134.4 0 2.1 2.1 0 1 0-134.4 0Z" p-id="11756"></path><path d="M716.8 912a2.1 2.1 0 1 0 134.4 0 2.1 2.1 0 1 0-134.4 0Z" p-id="11757"></path><path d="M905.6 764.8l-537.6 0c-28.8 0-57.6-25.6-64-54.4l-96-566.4c-9.6-54.4-60.8-96-115.2-96l-22.4 0c-12.8 0-25.6 12.8-25.6 25.6 0 12.8 12.8 25.6 25.6 25.6l22.4 0c28.8 0 57.6 25.6 64 54.4l96 566.4c9.6 54.4 60.8 96 115.2 96l537.6 0c12.8 0 25.6-12.8 25.6-25.6C931.2 777.6 921.6 764.8 905.6 764.8z" p-id="11758"></path><path d="M880 179.2l-572.8 0c-12.8 0-25.6 12.8-25.6 25.6 0 12.8 12.8 25.6 25.6 25.6l572.8 0c25.6 0 38.4 16 32 41.6l-70.4 281.6c-6.4 32-41.6 57.6-73.6 60.8l-396.8 28.8c-12.8 0-25.6 12.8-22.4 28.8 0 12.8 12.8 25.6 28.8 22.4l396.8-28.8c54.4-3.2 105.6-48 118.4-99.2l70.4-281.6C976 230.4 937.6 179.2 880 179.2z" p-id="11759"></path></svg></i>
              </a>
          </div>
    </header>
      <md-swiper :is-prevent="false" :useNative-driver="false" class="banners">
          <md-swiper-item :key="index" v-for="(item, index) in banners">
              <div class="banner-item" :style="{'background': 'url(' + ASSET_URL + item.img+ ') no-repeat center / cover'}"  @click="goUrl(item)"></div>
          </md-swiper-item>
      </md-swiper>

      <div class="menu">
          <a @click="goUrl(item)" class="item" v-for="item in navs" :key="item.id">
              <img :src="ASSET_URL + item.img" alt="">   
              <div>{{item.text}}</div> 
          </a>
      </div>

      <div class="block-item">
          <div class="block-title"><span>活动列表</span></div>
          <div class="fixed activity-1" v-if="home_activity_1.length > 0">
              <div class="pull-left ">
                  <div class="product-item" @click="$helper.goPage('/item/' + home_activity_1[0].product.slug)">
                      <div class="text-ellipsis-2">{{home_activity_1[0].product.name}}</div>
                      <img :src="ASSET_URL + home_activity_1[0].product.img" alt="">
                  </div>
              </div>
              <div class="pull-right">
                  <div class="product-item" @click="$helper.goPage('/item/' + home_activity_1[1].product.slug)">
                      <img :src="ASSET_URL + home_activity_1[1].product.img" alt="">
                      <div class="info">
                          <md-tag shape="circle" type="fill" fill-color="#244583" font-color="#fff">促销</md-tag>
                          <div class="text-ellipsis">{{home_activity_1[1].product.name}}</div>
                          <div class="price"><small>{{home_activity_1[1].product.currency.code}}</small>{{home_activity_1[1].product.currency.amount}}</div>
                      </div>
                  </div>
                  <div class="product-item" @click="$helper.goPage('/item/' + home_activity_1[2].product.slug)">
                      <img :src="ASSET_URL + home_activity_1[2].product.img" alt="">
                      <div class="info">
                          <md-tag shape="circle" type="fill" fill-color="#244583" font-color="#fff">促销</md-tag>
                          <div class="text-ellipsis">{{home_activity_1[2].product.name}}</div>
                          <div class="price"><small>{{home_activity_1[2].product.currency.code}}</small>{{home_activity_1[2].product.currency.amount}}</div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="ad-item" v-if="home_activity_2.id">
          <a :href="home_activity_2.url"><img :src="ASSET_URL + home_activity_2.img" alt=""></a>
      </div>

      <div class="activity-2">
        <template  v-for="(item, index) in home_activity_3">
          <div class="product-item" v-if="index < 2"  @click="$helper.goPage('/item/' + item.product.slug)" :key="index">
                <img :src="ASSET_URL + item.product.img" alt="">
                <div class="text-ellipsis">{{item.product.name}}</div>
                <md-tag shape="circle" type="ghost" font-color="#555" v-if="item.product.discount > 0">-{{item.product.discount}}%OFF!</md-tag>
                <md-tag shape="circle" type="ghost" font-color="#555" v-else>优惠中!</md-tag>
                <div class="price">
                    <small>{{item.product.currency.code}}</small>{{item.product.currency.amount}} 
                    <del v-if="item.product.market_price > 0"><small>{{item.product.market_currency.code}}</small>{{item.product.market_currency.amount}}</del>
                </div>
          </div>
        </template>
      </div>
      
      <div class="activity-3">
          <div class="title">活动精选</div>
          <template  v-for="(item, index) in home_activity_3">
            <div class="product-item" v-if="index > 1 && index < 5" @click="$helper.goPage('/item/' + item.product.slug)" :key="index">
                
                <img  :src="ASSET_URL + item.product.img" alt="">
                <div class="info">
                    <div class="text-ellipsis-2">{{item.product.name}}</div>
                    <div class="price"><small>{{item.product.currency.code}}</small>{{item.product.currency.amount}}</div>
                    <div class="desc">评分 {{item.product.rate}}星</div>
                </div>
        
            </div>
          </template>
           
      </div>
      <!-- <div class="ad-item">
          <a :href="home_activity_2.url"><img :src="ASSET_URL + home_activity_2.img" alt=""></a>
      </div> -->
      <div class="activity-4">
          <div class="title">热门产品</div>
          <md-scroll-view :scrolling-y='false' v-if="home_hot_1.length > 0">
              <div class="hot-banner-item" >
                  <a :href="'/item/' + item.product.slug" class="fixed"  :key="index" v-for="(item, index) in home_hot_1" @click="$helper.goPage('/item/' + item.product.slug)">
                      <img :src="ASSET_URL + item.product.img" alt="">
                      <div class="text-ellipsis">{{item.product.name}}</div>
                      <div desc>
                          <md-tag shape="circle" type="ghost" font-color="#555" v-if="item.product.discount > 0">-{{item.product.discount}}%OFF!</md-tag>
                          <md-tag shape="circle" type="ghost" font-color="#555" v-else>优惠中</md-tag>
                      </div>
                  </a>
              </div>
          </md-scroll-view>
      </div>
      <div class="ad-item" style="margin-top:0.7rem" v-if="hot_ad.id > 0">
          <a :href="hot_ad.url"><img :src="$store.state.assetUrl + hot_ad.img" alt=""></a>
      </div>
      <template  v-for="item in home_hot_2">
        <div class="activity-5" @click="$router.push('item/' + item.product.slug)" :key="item.id">
          <div class="img"><img :src="ASSET_URL + item.product.img" alt=""></div>
          <div class="text-ellipsis ">{{item.product.name}}</div>
          <div>
              <md-tag shape="circle" type="ghost" font-color="#555">好评如潮</md-tag>
              <md-tag shape="circle" type="ghost" font-color="#555" v-if="item.product.discount > 0">-{{item.product.discount}}%OFF!</md-tag>
          </div>
          <div class="fixed">
              <div class="pull-left price">
                  <small>{{item.product.currency.code}}</small>{{item.product.currency.amount}}
              </div>
              <div class="pull-right">
              <md-tag shape="circle" type="fill" fill-color="#f4f4f4" font-color="#111">立即购买</md-tag>
              </div>
          </div>
        </div>
      </template>
      
      <div class="block-item">
          <div class="block-title"><span>猜你喜欢</span></div>
          <div class="guess-likes fixed">
              <template  v-for="item in products.data">
                <div class="item" @click="$helper.goPage('/item/' + item.slug)" :key="item.id">
                  <div class="img"><img :src="ASSET_URL + item.img" alt=""></div>
                  <div class="text-ellipsis ">{{item.name}}</div>
                  <div>
                      <md-tag shape="circle" type="ghost" font-color="#555">评分 {{item.rate}}星</md-tag>
                  </div>
                  <div class="price">
                      <small>{{item.currency.code}}</small>{{item.currency.amount}} 
                      <del v-if="item.market_price > 0"><small>{{item.market_currency.code}}</small>{{item.market_currency.amount}}</del>
                  </div>
                </div>
              </template>
          </div>
          <div class="load-more" v-if="isLoading">正在努力加载中...</div>
          <div class="load-more" v-if="isNoMore">全部加载完了</div>
        </div>
    </div>
   
</template>

<script>
let self;
export default {
    name: 'index',
    components: {
        
    },
    
    data(){
        return {
        navs:[],
        banners:[],
        home_activity_1:[],
        home_activity_2:{},
        home_activity_3:[],
        home_hot_1:[],
        home_hot_2:[],
        hot_ad:{},
        products:{},
        page:1,
        loading:false,
        isLoading:false,
        isNoMore:false,
        headerAlpha:0,
        ASSET_URL:'',
        grieve_model:false
        }
    },
    mounted(){
        self = this;
        document.title="艾维商城";
        this.ASSET_URL = this.$store.state.assetUrl;
        window.onscroll = ()=>{
            if(self.$route.path == '/'){
                self.onScroll(document.documentElement);
            }
        }
        this.$store.dispatch('showBottomBar',1);

        if(!this.$store.state.pageLoad.index){
            this.getHomeData();
        }else{
            this.setData(this.$store.state.pageLoad.index);
        }
    },
    methods:{
        goUrl(item){
            let url = ""
            if(item.title == "snapUp"){
                if(this.$store.state.userInfo.id){
                    url = item.url + "?sk=" + this.$store.state.userInfo.auth_token;
                }else{
                    url = "/login?referer=seckill"
                }
            }else{
                url = item.url
            }
            window.location.href = url
        },
        getHomeData(){
            this.loading = true;
            this.$http.get('homeData').then((res)=>{
                let data = res.data;
                self.$store.commit('setPageLoad', {index:data});
                this.setData(data);
            }).finally(()=>{
                self.loading = false;
            });
        },
        setData(data){
            self.navs = data.navs || [];
            self.banners = data.banners || [];
            self.home_activity_1 = data.home_activity_1 || [];
            self.home_activity_2 = data.home_activity_2 || {};
            self.home_activity_3 = data.home_activity_3 || [];
            self.home_hot_1 = data.home_hot_1 || [];
            self.home_hot_2 = data.home_hot_2 || [];
            self.hot_ad = data.hot_ad || {};
            self.grieve_model = data.grieve_model;
            setTimeout(()=>{
                self.onScroll();
            },10)
        },
        onScroll(){
            let top = document.documentElement.scrollTop || document.body.scrollTop;
            let alphaRate = 1 / 150;
            if(top <= 200 && top > 0){
                this.headerAlpha = top * alphaRate;
            }
            if(top < 10){
                this.headerAlpha = 0;
            }
            if(top > 200){
                this.headerAlpha = 1;
            }
            //触底
            if(top + window.innerHeight  > document.getElementsByClassName('page-wrap')[0].offsetHeight - 90){
                if(this.isLoading){
                    return;
                }
                this.isLoading = true;
                this.onEndReached();
            }
        },
        onEndReached(){
            let page = self.page;
            if(page > self.products.last_page){
                this.isLoading = false;
                this.isNoMore = true;
                return;
            }
            //getProducts
            this.$http.get('listProducts',{params:{page:page}}).then((res)=>{
                if(page == 1){
                    self.products = res.data;
                }else if(res.data.data.length > 0){
                    self.products.data = self.products.data.concat(res.data.data);
                }
                self.page++;
            }).finally(()=>{
                self.isLoading = false;
            })
        }
    }
}
</script>

<style scoped lang='less'>
@import '../assets/reset.less';
.page-wrap{
    padding-bottom: 60px;
}
.grieve-model {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray;
    filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
}
.banners{
    height: 200px!important;
}
.banners .banner-item{
    float: left;
    width: 100%;
    height: 100%;
    line-height: 250px;
    text-align: center;
    font-size: 28px;
    color: #000;
    box-align: center;
    align-items: center;
    box-pack: center;
    justify-content: center;
    text-decoration-line: none;
}
.banners .md-swiper-indicator{
    border-radius: 2px;
    background: rgba(255, 255, 255, 0.4);
    height: 3px;
}
.banners .md-swiper-indicator.md-swiper-indicator-active{
    background: @primary-color;
}
header{
    color: rgba(255, 255, 255, 0.8);
    padding: 8px 0 8px 0.7rem;
    position: fixed;
    width: 100%;
    z-index: 2;
    display: flex;
}

header .logo{
    height: 26px;
    vertical-align: middle;
}
header .nav-search{
    flex: 1;
    text-align: right;
    padding: 0 14px 0 10px;
}
header .search-input{
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.1);
    display: inline-block;
    width: calc(100% - 45px);
    vertical-align: middle;
    color:   rgba(255, 255, 255, 0.7);
    text-align: left;
    padding-left: 10px;
    line-height: 34px;
    height: 34px;
    transition: all 0.3s;
    margin-right: 10px;
}
header .btn-cart{
    width: 34px;
    height: 34px;
    line-height: 34px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.1);
    text-align: center;
    font-size: 16px;
    display: inline-block;
    vertical-align: middle;
    padding-top: 2px;
    transition: all 0.3s;
}
header .btn-cart svg{
    fill: rgba(255, 255, 255, 0.7);
    height: 16px;
    width: 16px;
}

header.active .search-input,header.active .btn-cart{
    background: rgba(255, 255, 255, 0.2);
}

.menu{
    padding: 1rem 1rem 0 1rem;
    overflow: hidden;
    margin-top: 1rem;
}
.menu .item{
    width: 25%;
    text-align: center;
    float: left;
    margin-bottom: 1rem;
    font-size: 12px;
}
.menu .item img{
    height: 40px;
    width: 40px;
}

.block-item{
    padding: 1rem 0.7rem;
}


.activity-1{
    border-radius: 13px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.05), 0 5px 10px rgba(0, 0, 0, 0.03), 0 1px 3px rgba(0, 0, 0, 0.01);
    overflow: hidden;
}
.activity-1 .pull-left{
    background: rgba(0, 0, 0, 0.01);
    width: 50%;
    height: 100%;
}
.activity-1 .pull-right{
    width: 50%;
}
.activity-1 .product-item{
    padding: 1.5rem;
    text-align: center;
}
.activity-1 .product-item img{
    height: 50px;
    width: 50px;
    vertical-align: middle;
}
.activity-1 .pull-left .product-item{
    font-weight: bold;
}
.activity-1 .pull-left .product-item img{
    height: 100px;
    width: 100px;
    margin-top: 2rem;
}
.activity-1 .pull-right{
    padding-top: 2rem;
}
.activity-1 .pull-right .product-item{
    margin-bottom: 3rem;
    padding: 0 0.5rem;
    display: flex;
}
.activity-1 .pull-right .product-item:last-child{
    margin-bottom: 2rem;
}
.activity-1 .pull-right .info{
    width: calc(100% - 55px);
    padding-top: 3px;
    padding-left: 5px;
    font-size: 12px;
    text-align: left;
    height: 2rem;
}
.activity-1 .pull-right .info .price{
    font-size: 18px;
    font-weight: bold;
    text-align: right;
}
.activity-1 .pull-right .info .price small{
    font-weight: normal;
    font-size: 12px;
}
.activity-1 .pull-right .info .md-tag{
    margin-bottom: 4px;
}

.activity-2{
    overflow: hidden;
    border-radius: 13px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.05), 0 5px 10px rgba(0, 0, 0, 0.03), 0 1px 3px rgba(0, 0, 0, 0.01);
    margin: 0.7rem;
    background: #fff;
}

.activity-2 .product-item{
    width: 50%;
    float: left;
    text-align: center;
    padding: 1rem;
}
.activity-2 .product-item img{
    height: 90px;
    width: 90px;
    margin-bottom: 0.5rem;
    
}
.activity-2 .price {
    font-weight: bold;
}
.activity-2 .price small{
    font-size: 12px;
    font-weight: normal;
}
.activity-2 .price del{
    font-weight: normal;
}
.activity-2 .product-item .md-tag{
    margin: 5px 0;
}

.activity-3{
    overflow: hidden;
    border-radius: 13px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.05), 0 5px 10px rgba(0, 0, 0, 0.03), 0 1px 3px rgba(0, 0, 0, 0.01);
    margin: 0.7rem;
    background: #fff;
}
.activity-3 .product-item{
    text-align: center;
    padding: 1rem;
    position: relative;
    display: flex;
}

.activity-3 .product-item img{
    height: 90px;
    width: 90px;
    margin-right: 0.8rem;
    background: rgba(0, 0, 0, 0.02);
    padding: 5px;
    border-radius: 10px;
}
.activity-3 .info{
    text-align: left;
    flex: 1;
    width: calc(100% - 90px - 0.8rem);
    padding-top: 3px;
}
.activity-3 .desc{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.4);
}
.activity-3 .price {
    font-weight: bold;
    text-align: left;
    margin: 5px 0 0 0;

}
.activity-3 .price small{
    font-size: 12px;
    font-weight: normal;
}
.activity-3 .price del{
    font-weight: normal;
}

.activity-3 .title{
    text-align: left;
    font-weight: bold;
    padding: 1rem;
    font-size: 1.05rem;
}
.activity-4{
    overflow: hidden;
    border-radius: 13px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.05), 0 5px 10px rgba(0, 0, 0, 0.03), 0 1px 3px rgba(0, 0, 0, 0.01);
    margin: 0.7rem  0.7rem 0 0.7rem;
    background: #fff;
}
.activity-4 .title{
    text-align: left;
    font-weight: bold;
    padding: 1rem;
    font-size: 1.05rem;
}
.activity-4 .md-scroll-view{
    margin: 0 1rem;
}
.hot-banner-item{
    height: 200px;
    white-space: nowrap
}
.hot-banner-item>a{
    display: inline-block;
    width: 190px;
    padding: 1rem;
    text-align: center;
}
.hot-banner-item>a img{
    width: 100px;
    height: 100px;
    margin-bottom: 0.5rem;
}
.hot-banner-item>a .md-tag{
    margin-top: 5px;
}
.hot-banners .md-swiper-indicator{
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.1);
    height: 3px;
}
.hot-banners .md-swiper-indicator.md-swiper-indicator-active{
    background: @primary-color;
}

.activity-5{
    overflow: hidden;
    border-radius: 13px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.05), 0 5px 10px rgba(0, 0, 0, 0.03), 0 1px 3px rgba(0, 0, 0, 0.01);
    margin: 0.7rem;
    padding: 1.5rem 1rem 1rem 1rem;
    background: #fff;
}
.activity-5 img{
    height: 120px;
}
.activity-5 .img{
    text-align: center;
    margin-bottom: 0.5rem;
}
.activity-5 .text-ellipsis {
    margin-bottom: 0.25rem;
}
.activity-5 .price{
    font-weight: bold;
    font-size: 1.1rem;
}
.activity-5 .price small{
    font-weight: normal;
}
.activity-5 .fixed{
    margin-top: 0.5rem;
}

.activity-5 .md-tag:first-child{
  margin-right: 5px;
}
</style>
