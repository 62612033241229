<template>
  <div class="sign-page">
    <div class="top-box">
      <div class="back-icon" @click="$router.back()">
          <md-icon name="arrow-left" size="lg"></md-icon>
      </div>
    </div>
    <div class="sign-top">
      <div class="sign-top-left">累计签到 <span class="num">{{config.total_sign_in_num}}</span> 天</div>
      <div class="sign-top-right">连续签到 <span class="num">{{config.go_on_sign_in_num}}</span> 天</div>
      <div class="today-sign" @click="signIn">
        <div class="content">
          <div>今日</div>
          <div>
            <span v-if="config.today_sign == 0">未签到</span>
            <span v-if="config.today_sign == 1">已签到</span>
          </div>
        </div>
      </div>
    </div>

    <div class="sign-container">
      <div class="block-item">
        <div class="block-item-title">
          <span class="title">每日签到</span>
          <span class="desc">送{{config.points}}积分<span v-if="config.lucky_draw_num > 0">。</span></span>
          <span class="more" @click="showLogs">中奖记录 <md-icon name="arrow-right" size="small"></md-icon></span>
        </div>
        <div class="day-sign-panel">
          <div class="day-sign-content">
            <div class="day" :class="index<=2 ? 'prev':index == 3 ? 'today':'next'"  v-for="(item,index) in config.days" :key="index">
              <div class="day-head">{{ item.day }}</div>
              <div class="day-foot" v-if="index<=2" >
                <md-icon name="right" v-if="item.sign" color="#FFFFFF"></md-icon>
                <md-icon name="close" v-if="!item.sign" color="#FFFFFF"></md-icon>
              </div>
              <div class="day-foot" v-if="index == 3"  >
                <md-icon name="right" v-if="item.sign" color="#FFFFFF"></md-icon>
                <div class="sign" v-if="!item.sign"  @click="signIn">签到</div>
              </div>
              <div class="day-foot" v-if="index > 3"  >
                <div class="sign">签到</div>
              </div>
            </div>
          </div>
        </div>
        <div class="day-sign-bottom">
          <i class="icon"><svg t="1651751556071" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10951" width="1em" height="1em"><path d="M507.251935 364.136333a161.792 193.536 30 1 0 193.536-335.214185 161.792 193.536 30 1 0-193.536 335.214185Z" fill="#F8B184" p-id="10952"></path><path d="M302.691666 254.3259a161.792 135.168 64.734 1 0 244.474574-115.385161 161.792 135.168 64.734 1 0-244.474574 115.385161Z" fill="#FCD684" p-id="10953"></path><path d="M768.512 1019.392H262.656c-67.072 0-126.464-49.664-132.096-112.64L80.896 364.544c-7.68-82.432 51.712-152.064 131.584-152.064h605.696c79.872 0 139.264 69.632 131.584 152.064l-49.664 542.208c-5.12 62.464-64.512 112.64-131.584 112.64z" fill="#F55569" p-id="10954"></path><path d="M582.144 1019.392h-133.12L409.6 212.992h211.968z" fill="#FCD684" p-id="10955"></path></svg></i>
          <span class="text">连续7天签到送{{config.lucky_draw_num}}次抽奖机会，连续签到{{config.month_day_num}}天,有机率获得神秘商品</span>
        </div>
      </div>

      <div class="block-item">
        <div class="block-item-title">
          <span class="title">每日任务</span>
          <span class="more" @click="$router.push('/luckyGrid')">幸运大转盘 <md-icon name="arrow-right" size="small"></md-icon></span>
        </div>
        <div class="task-list">
          <div class="item">
            <div class="icon-tag"><i class="icon"><svg t="1651819094278" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9146" width="1em" height="1em" fill="currentColor"><path d="M908.8 153.6c-12.8 0-120.32-12.8-209.92-12.8-79.36 0-133.12 15.36-151.04 25.6-7.68 5.12-23.04 12.8-35.84 23.04-12.8-12.8-28.16-17.92-33.28-23.04-20.48-10.24-71.68-25.6-151.04-25.6-92.16 0-199.68 12.8-212.48 12.8-38.4 2.56-69.12 33.28-69.12 71.68v570.88c0 38.4 30.72 69.12 69.12 71.68h2.56c10.24-2.56 64-7.68 122.88-12.8 104.96-7.68 145.92 0 161.28 2.56 7.68 0 17.92 5.12 28.16 7.68 23.04 7.68 48.64 15.36 76.8 15.36 23.04 0 48.64-7.68 71.68-15.36 17.92-5.12 35.84-10.24 43.52-10.24 2.56 0 5.12 0 7.68-2.56 120.32-17.92 263.68 10.24 263.68 10.24h5.12c38.4 0 71.68-30.72 71.68-71.68V225.28c7.68-38.4-23.04-69.12-61.44-71.68zM143.36 376.32c28.16-7.68 84.48-20.48 174.08-20.48 53.76 0 89.6 10.24 128 30.72 12.8 7.68 15.36 23.04 10.24 33.28-5.12 7.68-12.8 12.8-23.04 12.8-5.12 0-7.68 0-12.8-2.56-30.72-17.92-58.88-23.04-104.96-23.04-84.48 0-135.68 12.8-161.28 20.48-12.8 2.56-28.16-5.12-30.72-17.92 0-17.92 7.68-30.72 20.48-33.28zM460.8 622.08c-5.12 10.24-12.8 15.36-23.04 15.36-2.56 0-7.68 0-10.24-2.56 0 0-58.88-25.6-107.52-25.6-81.92 0-112.64 7.68-143.36 15.36-5.12 2.56-10.24 2.56-15.36 5.12-12.8 2.56-28.16-5.12-30.72-17.92-2.56-12.8 5.12-28.16 17.92-30.72 5.12-2.56 10.24-2.56 15.36-5.12 33.28-7.68 66.56-17.92 156.16-17.92 61.44 0 125.44 28.16 130.56 30.72 10.24 7.68 15.36 20.48 10.24 33.28z m435.2-222.72c-2.56 12.8-15.36 23.04-30.72 20.48-64-12.8-110.08-20.48-168.96-17.92-56.32 0-94.72 20.48-97.28 20.48-2.56 2.56-7.68 2.56-12.8 2.56-7.68 0-17.92-5.12-23.04-12.8-7.68-12.8-2.56-28.16 10.24-33.28 2.56 0 51.2-28.16 120.32-28.16 64-5.12 112.64 5.12 181.76 17.92 15.36 2.56 23.04 17.92 20.48 30.72z" fill="" p-id="9147"></path></svg></i></div>
            <div class="info">
              <div class="label">阅读文章</div>
              <div class="desc">可获得{{config.task_article_award_count}}积分</div>
            </div>
            <md-button type="default" round class="small" v-if="config.tasks[0].status == 0" @click="goWxArticle">去完成</md-button>
            <md-button type="primary" round class="small" v-if="config.tasks[0].status == 1" @click="receiveTask('article')">领取</md-button>
            <md-button type="default" round class="small" v-if="config.tasks[0].status == 2">已领取</md-button>
          </div>
          
          <div class="item">
            <div class="icon-tag"><i class="icon"><svg t="1651821194696" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11825" width="1em" height="1em" fill="currentColor"><path d="M397.184 642.112a192 192 0 1 1-10.144-270.464L554.592 262.4a144 144 0 1 1 36.8 52.416l-165.44 107.84c14.08 26.72 22.016 57.12 22.016 89.376a191.36 191.36 0 0 1-15.808 76.384l241.056 135.584a144 144 0 1 1-28.992 57.12l-247.072-138.976z" p-id="11826"></path></svg></i></div>
            <div class="info">
              <div class="label">分享一件商品给朋友</div>
              <div class="desc">+{{config.task_share_award_count}}抽奖机会</div>
            </div>
            <md-button type="default" round class="small" v-if="config.tasks[1].status == 0" @click="$router.push('/search')">去完成</md-button>
            <md-button type="primary" round class="small" v-if="config.tasks[1].status == 1" @click="receiveTask('share')">领取</md-button>
            <md-button type="default" round class="small" v-if="config.tasks[1].status == 2">已领取</md-button>
          </div>

          <div class="item">
            <div class="icon-tag"><i class="icon"><svg t="1651821247630" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10802" width="1em" height="1em" fill="currentColor"><path d="M658.410667 533.333333a42.666667 42.666667 0 1 1-0.021334-85.312 42.666667 42.666667 0 0 1 0 85.312z m-298.666667 0a42.666667 42.666667 0 1 1-0.021333-85.312 42.666667 42.666667 0 0 1 0 85.312z m0.938667-251.392c0-85.162667 66.773333-153.941333 148.864-153.941333 82.069333 0 148.864 68.778667 148.864 153.941333v38.741334L360.682667 320v-38.058667z m556.714666 100.352c-2.474667-34.496-32.277333-62.293333-66.986666-62.293333h-149.333334v-38.058667C701.077333 173.482667 615.466667 85.333333 509.546667 85.333333s-191.530667 88.149333-191.530667 196.608V320H167.744c-34.837333 0-64.512 27.626667-66.986667 62.293333L64.170667 894.656A60.501333 60.501333 0 0 0 125.013333 960h768.128a60.373333 60.373333 0 0 0 60.842667-65.344L917.397333 382.293333z" p-id="10803"></path></svg></i></div>
            <div class="info">
              <div class="label">完成一笔订单</div>
              <div class="desc">+{{config.task_order_award_count}}抽奖机会</div>
            </div>
            <md-button type="default" round class="small" v-if="config.tasks[2].status == 0" @click="$router.push('/search')">去完成</md-button>
            <md-button type="primary" round class="small" v-if="config.tasks[2].status == 1" @click="receiveTask('order')">领取</md-button>
            <md-button type="default" round class="small" v-if="config.tasks[2].status == 2">已领取</md-button>
          </div>
        </div>
      </div>

      <div class="block-item-2">
        <div class="block-item-title">
          <span class="title">积分兑换</span>
          <span class="more" @click="$router.push('/exchange')">兑换中心 <md-icon name="arrow-right" size="small"></md-icon></span>
        </div>

        <div class="exchange-list">
          <div class="product-item" v-for="item in exchangeProducts" :key="item.id" @click="$router.push('/exchange?id='+item.id)">
              <img :src="$store.state.assetUrl + item.sku_info.product.img" alt="">
              <div class="info">
                  <div class="name text-ellipsis">{{item.sku_info.product.name}}</div>
                  <div class="desc">{{item.price_points}} 积分</div>
              </div>
              <div class="btns">
                  <md-tag shape="circle" type="fill" fill-color="rgba(254,55,112,0.7)" font-color="#fff">去兑换</md-tag>
              </div>
          </div>
        </div>
      </div>

      <div class="block-item-2">
        <div class="block-item-title">
          <span class="title">热销排行</span>
          <span class="more" @click="$router.push('/ranking')">更多排行 <md-icon name="arrow-right" size="small"></md-icon></span>
        </div>
        <div class="p-list">
          <div class="p-item" v-for="(item, index) in rankProducts" :key="index" @click="$helper.goPage('/item/' + item.product.slug)">
                <img :src="$store.state.assetUrl + item.product.img" alt="">
                <div class="info">
                    <div class="name text-ellipsis">{{item.product.name}}</div>
                    <div class="rate"><md-tag  shape="circle" type="ghost" font-color="#555">评分 {{ item.product.rate }}星</md-tag></div>
                    <div class="fixed">
                        <div class="price pull-left">
                            <span class="small">{{item.product.currency.code}}</span>{{item.product.currency.amount}}
                        </div>
                        <div class="pull-right">
                           <svg t="1630491127273" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11755" width="1em" height="1em"  fill="currentColor"><path d="M246.4 912a2.1 2.1 0 1 0 134.4 0 2.1 2.1 0 1 0-134.4 0Z" p-id="11756"></path><path d="M716.8 912a2.1 2.1 0 1 0 134.4 0 2.1 2.1 0 1 0-134.4 0Z" p-id="11757"></path><path d="M905.6 764.8l-537.6 0c-28.8 0-57.6-25.6-64-54.4l-96-566.4c-9.6-54.4-60.8-96-115.2-96l-22.4 0c-12.8 0-25.6 12.8-25.6 25.6 0 12.8 12.8 25.6 25.6 25.6l22.4 0c28.8 0 57.6 25.6 64 54.4l96 566.4c9.6 54.4 60.8 96 115.2 96l537.6 0c12.8 0 25.6-12.8 25.6-25.6C931.2 777.6 921.6 764.8 905.6 764.8z" p-id="11758"></path><path d="M880 179.2l-572.8 0c-12.8 0-25.6 12.8-25.6 25.6 0 12.8 12.8 25.6 25.6 25.6l572.8 0c25.6 0 38.4 16 32 41.6l-70.4 281.6c-6.4 32-41.6 57.6-73.6 60.8l-396.8 28.8c-12.8 0-25.6 12.8-22.4 28.8 0 12.8 12.8 25.6 28.8 22.4l396.8-28.8c54.4-3.2 105.6-48 118.4-99.2l70.4-281.6C976 230.4 937.6 179.2 880 179.2z" p-id="11759"></path></svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>

    <md-popup v-model="logsVisible" position="bottom" class="logs-pop">
      <div class="block-title">
          <span>中奖记录</span>
          <a @click="logsVisible = false" class="close"><md-icon name="close"></md-icon></a>
      </div>
      
      <div class="logs-panel" @scroll="signInLogEndReached">
          <div class="log-item" v-for="item in signInLogs.data" :key="item.id">
              <div class="fixed">
                <div class="pull-left date">{{item.belong_date}}</div>
                <div class="pull-right date">积分+{{item.points}}</div>
              </div>
              <div class="content">
             
              <span v-if="item.luck_draw_chance > 0">连续7天签到送抽奖机会+{{item.luck_draw_chance}}</span>
              <div  v-else-if="item.cdk">
                <div>连续签到一个月获得神秘礼物，请复制兑换码到 “cdkey兑换中心” 兑换</div>
                <div class="code-box">
                  <div class="code"><span>{{item.cdk}}</span> <a @click="$helper.copyText(item.cdk)">复制</a></div>
                  <md-button class="md w100p" type="primary" round @click="$router.push('/cdkey')">去兑换</md-button>
                </div>
              </div>
              <span v-else>签到成功</span>
              </div>
          </div>
      <div class="load-more" v-if="isLoading">正在努力加载中...</div>
      <div class="load-more" v-if="isNoMore">全部加载完了</div>
  </div>
  </md-popup>
  </div>
</template>

<script>
let self;
export default {
  name: "index",
  data() {
    return {
      config:{
        days:[],
        tasks:[{status:0},{status:0},{status:0}]
      },
      exchangeProducts:[],
      rankProducts:[],
      signInLogs:{},
      logsVisible:false,
      isLoading:false,
      isNoMore:false,
      page:1,
    };
  },
  created() {
  },
  mounted() {
    self = this;
    this.$store.dispatch("hideBottomBar");
    this.getSignData();
    this.getExchangeProducts()
    this.getRanking()
  },
  methods: {
    getSignData(){
      this.$http.get('/user/signData').then((res)=>{
        self.config = res.data;
      })
    },
    goWxArticle(){
      this.$http.post('/user/finishTaskArticle').then(()=>{
         window.location.href = self.config.task_article_url;
      })
    },
    receiveTask(type){
      this.$http.post('/user/receiveTask', {type:type}).then((res)=>{
        self.$toast.succeed(res.msg);
        self.getSignData();
      })
    },
    showLogs(){
      this.logsVisible = true;
      this.getSignLogs();
    },
    getSignLogs(){
      this.$http.get('/user/listSignInLogs').then((res)=>{
        this.signInLogs = res.data;
      })
    },
    signInLogEndReached(e){
            let top = e.target.scrollTop;
            //触底
            if(top + e.target.offsetHeight  > e.target.scrollHeight - 50){
                if(this.isLoading){
                    return;
                }
                if(this.page >= this.signInLogs.last_page ){
                    self.isNoMore = true;
                    return;
                }
                this.page++;
                this.isLoading = true;
                this.getSignLogs();
            }
        },
    signIn(){
      if(this.config.today_sign == 1){
        return;
      }
      this.$http.post('/user/signIn').then((res)=>{
        if(res.data.luck_draw_chance > 0){
          //连续满7天获得的抽奖机会次数
          self.$dialog.alert({
              title: '签到成功',
              content: '您已连续签到满7天，获得一次免费抽奖次数',
              confirmText: '确定',
              onConfirm: () => {}
          })
        }else if(res.data.cdk){
          //cdk 签到满月 并且概率命中 获得的商品兑换码   为空没有获得 或者不是满月签到
          self.$dialog.alert({
              title: '签到成功',
              content: '您已连续签到满'+self.month_day_num+'天，获得神秘礼物一份，可到中奖记录中查看。',
              confirmText: '确定',
              onConfirm: () => {}
          })
        }else{
          self.$toast.succeed("签到成功");
        }
        self.getSignData();
      })
    },
    getExchangeProducts(){
        this.$http.get('listExchangeProducts?size=3').then((res)=>{
            self.exchangeProducts = res.data;
        });
    },
    getRanking(){
        var param = {
          ranking_type_id : 0,
        };
        this.$http.get('listRanking',{params:param}).then((res)=>{
            self.rankProducts = {...res.data.data};
        });
    },
  }
};
</script>
<style lang="less" scoped>
@import "../assets/reset.less";

.top-box{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    padding: 14px;
}
.top-box .back-icon{
    width: 30px;
    text-align: left;
    height: 34px;
    line-height: 38px;
    color: #fff;
}
.sign-pange {
  background-color: #FFFFFF;
}


.logs-panel {
  max-height: calc(100% - 38px);
  overflow-y: auto;
  overflow-x: hidden;
  margin:0 -1rem;
  padding: 10px 1rem;
}
.log-item {
  margin-bottom: 1rem;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05), 0 5px 10px rgba(0, 0, 0, 0.02), 0 1px 3px rgba(0, 0, 0, 0.01);
  border-radius:10px;
  padding:1rem 0.7rem
}
.log-item.single{
  box-shadow:none;
  padding:0
}
.log-item:last-child, .log-item.single{
    border-bottom: none;
}
.log-item .date {
  font-size: 12px;
  line-height: 30px;
}
.log-item .content {
  font-size: 13px;
  margin: 0.3rem 0;
  font-weight:500;
}

.code-box .code{
  display: flex;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  padding: 5px;
  margin: 5px 0;
}
.code-box .code span{
  width: calc(100% - 50px);
}
.code-box .code a{
  width: 50px;
  text-align: center;
}



.sign-top {
  background-image: linear-gradient(337deg, rgba(54, 54, 54, 0.05) 0%, rgba(54, 54, 54, 0.05) 33.333%,rgba(85, 85, 85, 0.05) 33.333%, rgba(85, 85, 85, 0.05) 66.666%,rgba(255, 255, 255, 0.05) 66.666%, rgba(255, 255, 255, 0.05) 99.999%),linear-gradient(384deg, rgba(81, 81, 81, 0.05) 0%, rgba(81, 81, 81, 0.05) 33.333%,rgba(238, 238, 238, 0.05) 33.333%, rgba(238, 238, 238, 0.05) 66.666%,rgba(32, 32, 32, 0.05) 66.666%, rgba(32, 32, 32, 0.05) 99.999%),linear-gradient(171deg, rgba(192, 192, 192, 0.05) 0%, rgba(192, 192, 192, 0.05) 33.333%,rgba(109, 109, 109, 0.05) 33.333%, rgba(109, 109, 109, 0.05) 66.666%,rgba(30, 30, 30, 0.05) 66.666%, rgba(30, 30, 30, 0.05) 99.999%),linear-gradient(220deg, rgba(77, 77, 77, 0.05) 0%, rgba(77, 77, 77, 0.05) 33.333%,rgba(55, 55, 55, 0.05) 33.333%, rgba(55, 55, 55, 0.05) 66.666%,rgba(145, 145, 145, 0.05) 66.666%, rgba(145, 145, 145, 0.05) 99.999%),linear-gradient(121deg, rgb(0,200,249),rgb(2,151,254));
  height: 150px;
  display: flex;
  justify-content: space-between;
  color: #FFFFFF;
  position: relative;
  padding-top: 50px;
}
.sign-top .today-sign{
  height: 70px;
  width: 70px;
  position: absolute;
  border-radius: 50%;
  background: #fff;
  text-align: center;
  padding-top: 5px;
  bottom: -30px;
  left: calc(50% - 30px);
  line-height: normal;
}
.sign-top .today-sign .content{
  background: linear-gradient(121deg, rgba(0,200,249,0.5),rgb(2,151,254));
  width: 60px;
  height: 60px;
  display: inline-block;
  border-radius: 50%;
  font-size: 12px;
  line-height: 18px;
  padding-top: 12px;
}

.sign-top .sign-top-left {
  padding: 20px;
}

.sign-top .sign-top-right {
  padding: 20px;
}

.sign-top .num {
  font-size: 26px;
  font-weight: 500;
}

.sign-container {
  padding: 14px;
}

.sign-container .block-item {
  margin-top: 30px;
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.04), 0 5px 10px rgba(0, 0, 0, 0.02);
  padding: 10px;
}

.block-item-2{
  margin-top: 30px;
  padding: 0 5px;
}
.block-item-title{
  margin-bottom: 24px;
  position: relative;
}
.block-item-title .title{
  font-size: 16px;
  font-weight: 500;
  position: relative;
  display: inline-block;
}
.block-item-title .title::after{
  display: block;
  content: "";
  background: linear-gradient(121deg, rgba(2,151,254,0.3), rgba(0,200,249,0.08));
  position: absolute;
  width: 100%;
  height: 12px;
  bottom: 0;
  left: 0;
  z-index: 0;
  transform: rotate3d(5,2,-3, 6deg);
}
.block-item-title .more{
  position: absolute;
  right: 0;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  line-height: 24px;
}


.block-item-title .desc {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  margin-left: 5px;
}
.day-sign-panel{
  overflow-x: auto;
  overflow-y: hidden;
}
.day-sign-content {
  word-break: keep-all;
  white-space: nowrap;
}

.day-sign-content .day {
  display: inline-block;
  border-radius: 5px;
  box-sizing: border-box;
  height: 60px;
  padding: 10px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  width: 50px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 12px;
  margin-right: 10px;
}

.day-sign-content .day.prev{
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  border: none;
  color: #fff;
}

.day-sign-content .day.today{
  background: linear-gradient(to right bottom, rgba(0,200,249,0.8), rgba(2,151,254,0.7) );
  border: none;
  color: #fff;
}
.day-sign-content .day-head{
  font-size: 14px;
  margin-bottom: 2px;
}
.day-sign-content .day.next .day-foot .sign{
  background: rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding: 0 2px;
  font-size: 11px;
}
.day-sign-bottom{
  margin-top: 10px;
  font-size: 12px;
}
.day-sign-bottom .text{
  color: rgba(0, 0, 0, 0.5);
  margin-left: 5px;
}

.task-list .item{
  display: flex;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}
.task-list .item:last-child{
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.task-list .item .icon-tag{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(to right bottom, rgba(255,185,57,0.8), rgba(250,141,56,1));
  color: #fff;
  text-align: center;
  line-height: 45px;
  font-size: 18px;
}
.task-list .item:nth-child(2) .icon-tag{
  background: linear-gradient(to right bottom, rgba(157,142,245,0.8), rgba(127,106,233,1));
}
.task-list .item:nth-child(3) .icon-tag{
  background: linear-gradient(to right bottom, rgba(20,230,157,0.8), rgba(10,181,136,1));
}
.task-list .item .info{
  width: calc(100% - 110px);
  padding: 0 10px;
}
.task-list .item .desc{
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}
.task-list .item .md-button{
  width: 70px;
  margin-top: 5px;
}


.exchange-list{
  display: flex;
  margin: 0 -5px 10px -5px;
}
.product-item{
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    padding: 10px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.03), 0 5px 10px rgba(0, 0, 0, 0.02), 0 1px 3px rgba(0, 0, 0, 0.01);
    margin: 0 5px;
    flex: 1;
}
.product-item img{
    height: 70px;
    width: 70px;
}
.product-item .desc{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
}

.product-item .info .name{
    line-height: 1.2;
}

.product-item .md-tag{
  margin-top: 7px;
}


.p-item{
    padding: 1rem 0.7rem;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.03), 0 5px 10px rgba(0, 0, 0, 0.02), 0 1px 3px rgba(0, 0, 0, 0.01);
    border-radius:10px;
    background: #fff;
    display: flex;
    margin-bottom: 10px;
}
.p-item img{
    height: 75px;
}
.p-item .info{
    padding-left: 10px;
    width: calc(100% - 95px);
}
.p-item .info .name{
    line-height: 20px;
    margin-bottom: 5px;
}
.p-item .info .rate{
    margin-bottom: 5px;
}
.p-item .price{

    font-size: 16px;
    font-weight: 500;
}
.p-item .price .small{
    font-weight: normal;
}
.p-item .info .pull-right{
    font-size: 18px;
    line-height: 22px;
    padding-top: 3px;
    color: #ff585a;
}


</style>
