<template>
    <div class="page-wrap">
        <div class="container">
            <md-cell-item  addon="上传头像" arrow @click="$refs.picker.click()">
                <img slot="left" :src="$store.state.assetUrl + $store.state.userInfo.avatar" class="avatar" alt="">
                <input type="file" class="hide" @change="pickUp" ref="picker">
            </md-cell-item>
            <md-cell-item title="昵称" :addon="$store.state.userInfo.nickname" arrow @click="nicknameVisible = true"></md-cell-item>
            <md-cell-item title="绑定邮箱" :addon="$store.state.userInfo.email" arrow @click="showEmailForm"></md-cell-item>
            <md-cell-item title="绑定手机" :addon="$store.state.userInfo.mobile" arrow @click="showMobileForm"></md-cell-item>
            <md-cell-item title="修改密码" arrow @click="pwdVisible = true"></md-cell-item>
            <md-cell-item title="货币类型" arrow @click="currencyVisible = true"></md-cell-item>
            <md-button type="warning" plain class="md mt-1" round @click="logout">退出登录</md-button>
        </div>

        <md-popup
        v-model="currencyVisible"
        position="bottom"
        class="currency-pop"
        >
            <div class="nickname">
                <div class="block-title">
                    <span>选择货币</span>
                    <a @click="currencyVisible = false" class="close"><md-icon name="close"></md-icon></a>
                </div>
                <md-radio-list :options="currencyOptions" v-model="selectedCurrency" ></md-radio-list>
                <div class="mt-1">
                    <md-button type="primary" round class="md" @click="selectCurrency">保存</md-button>
                </div>
            </div>
        </md-popup>


        <md-popup
        v-model="nicknameVisible"
        position="bottom"
        class="nickname-pop"
        >
            <div class="nickname">
                <div class="block-title">
                    <span>修改昵称</span>
                    <a @click="nicknameVisible = false" class="close"><md-icon name="close"></md-icon></a>
                </div>
                <md-input-item
                    preview-type="text"
                    v-model="nickname"
                    title="昵称"
                    placeholder="请填写昵称"
                    is-title-latent
                ></md-input-item>
                <div class="mt-1">
                    <md-button type="primary" round class="md" @click="saveNickname">保存</md-button>
                </div>
            </div>
        </md-popup>

        <md-popup
        v-model="pwdVisible"
        position="bottom"
        class="pwd-pop"
        >
            <div class="pwd">
                <div class="block-title">
                    <span>修改密码</span>
                    <a @click="pwdVisible = false" class="close"><md-icon name="close"></md-icon></a>
                </div>
                <md-input-item
                    type="password"
                    preview-type="text"
                    v-model="form.password"
                    title="新密码"
                    placeholder="请填写新密码"
                    is-title-latent
                >
                <span slot="right">大小写字母和数字</span>
                </md-input-item>
                <md-input-item
                    type="password"
                    preview-type="text"
                    v-model="form.repassword"
                    title="确认新密码"
                    placeholder="请再次填写新密码"
                    is-title-latent
                ></md-input-item>
                <div class="mt-1">
                    <md-button type="primary" round class="md" @click="savePwd">保存</md-button>
                </div>
            </div>
        </md-popup>
        <md-popup
        v-model="mobileVisible"
        position="bottom"
        class="mobile-pop"
        >
            <div class="mobile">
                <div class="block-title">
                    <span>绑定手机</span>
                    <a @click="mobileVisible = false" class="close"><md-icon name="close"></md-icon></a>
                </div>
                <md-input-item
                    preview-type="text"
                    v-model="mobile"
                    title="手机号"
                    placeholder="请填写新的手机号"
                    is-title-latent
                ></md-input-item>
                <!-- <md-input-item
                    preview-type="text"
                    v-model="mobileForm.code"
                    title="验证码"
                    placeholder="请填写短信验证码"
                    is-title-latent
                >
                <div  slot="right">发送验证码</div>
                </md-input-item> -->
                <div class="mt-1">
                    <md-button type="primary" round class="md" @click="saveMobile">保存</md-button>
                </div>
            </div>
        </md-popup>
        <md-popup
        v-model="emailVisible"
        position="bottom"
        class="email-pop"
        >
            <div class="email">
                <div class="block-title">
                    <span>修改邮箱</span>
                    <a @click="emailVisible = false" class="close"><md-icon name="close"></md-icon></a>
                </div>
                <md-input-item
                    preview-type="text"
                    v-model="email"
                    title="邮箱"
                    placeholder="请填写邮箱地址"
                    is-title-latent
                ></md-input-item>
                <div class="mt-1">
                    <md-button type="primary" round class="md" @click="saveEmail">保存</md-button>
                </div>
            </div>
        </md-popup>
    </div>
</template>

<script>
import { compressImage } from '../utils/compressImage';
let self;
export default {
    name: "profile",
    components: {},
    data() {
        return {
            nicknameVisible:false,
            mobileVisible:false,
            emailVisible:false,
            currencyVisible:false,
            nickname:'',
            mobile:'',
            email:'',
            pwdVisible:false,
            form: {},
            currencyOptions:[
                {
                    value:"rmb",
                    label:"CNY - 人民币"
                },
                {
                    value:"hkd",
                    label:"HKD - 港币"
                },
                {
                    value:"twd",
                    label:"TWD - 台币"
                },
                {
                    value:"usd",
                    label:"USD - 美元"
                }
            ],
            selectedCurrency:"",
        };
    },
    mounted(){
        self = this;
        
        this.nickname = this.$store.state.userInfo.nickname;
        this.email = this.$store.state.userInfo.email;
        this.mobile = this.$store.state.userInfo.mobile;
        this.$store.dispatch('hideBottomBar');
        let currency = this.$helper.getStorage("currency") || "rmb";
        this.selectedCurrency = currency;
    },
    beforeDestroy(){
        
    },
    methods:{
        selectCurrency(){
            this.$helper.selectCurrency(this.selectedCurrency);
        },
        logout(){
            this.$http.post('user/logout').then(()=>{
                self.$store.dispatch('setUserInfo',false);
                self.$router.back();      
            });
        },
        saveNickname(){
            this.$http.post('user/saveNickname',{nickname:this.nickname}).then((res)=>{
                self.$toast.succeed(res.msg);
                self.$store.dispatch('setUserInfo', res.data);
                self.nicknameVisible = false;
            });
        },
        pickUp(e){
            if(e.target.files.length == 0){
                return;
            }
            let config = {
                width: 250, // 压缩后图片的宽
                height: 250, // 压缩后图片的高
                quality: 0.7 // 压缩后图片的清晰度，取值0-1，值越小，所绘制出的图像越模糊
            }
            let file = e.target.files[0];
            //let fileSize = parseFloat(parseInt(file['size']) / 1024).toFixed(2);
            compressImage(file, config).then(result => {
              
                self.upload(result)
            })
            e.target.value='';
        },
        upload(file){
            const formData = new FormData();
            formData.append('file', file);
            this.$http.post('user/uploadImg?upload_type=avatar',formData).then((res)=>{
                self.saveAvatar(res.data.path);
            });
        },
        saveAvatar(avatar){
            this.$http.post('user/saveAvatar',{avatar}).then((res)=>{
                self.$toast.succeed(res.msg);
                self.$store.dispatch('setUserInfo', res.data);
            });
        },
        savePwd(){
            this.$http.post('user/savePassword',this.form).then((res)=>{
                self.$toast.succeed(res.msg);
                self.logout();
            });
        },
        saveEmail(){
            this.$http.post('user/saveEmail',{email:this.email}).then((res)=>{
                self.$toast.succeed(res.msg);
                self.$store.dispatch('setUserInfo', res.data);
                self.emailVisible = false;
            });
        },
        saveMobile(){
            this.$http.post('user/saveMobile',{mobile:this.mobile}).then((res)=>{
                self.$toast.succeed(res.msg);
                self.$store.dispatch('setUserInfo', res.data);
                self.mobileVisible = false;
            });
        },
        showEmailForm(){
            if(this.$store.state.userInfo.email){
                return;
            }
            this.emailVisible = true;
        },
        showMobileForm(){
            if(this.$store.state.userInfo.mobile){
                return;
            }
            this.mobileVisible = true;
        }
    }
};
</script>

<style scoped lang='less' >
.avatar{
    height: 40px;
    width: 40px;
    border-radius: 5px;
    vertical-align: middle;
}
.bottom-box{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem;
    background: transparent;
    border-top: none;
}
</style>
