<template>
  <div class="guess-likes-panel">
    <div class="block-title"><span>相关商品</span></div>
      <div class="guess-likes fixed" v-if="randomProducts.length > 0">
        <div
          class="item"
          v-for="item in randomProducts"
          :key="item.id"
          @click="$helper.goPage('/item/' + item.slug,false,true)"
        >
          <div class="img">
            <img :src="$store.state.assetUrl + item.img" alt="" />
          </div>
          <div class="text-ellipsis">{{ item.name }}</div>
          <div>
            <md-tag  shape="circle" type="ghost" font-color="#555">评分 {{ item.rate }}星</md-tag>
          </div>
          <div class="price">
            <small>{{item.currency.code}}</small>{{ item.currency.amount }}
            <del v-if="item.market_price > 0"><small>{{ item.market_currency.code }}</small>{{ item.market_currency.amount }}</del>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
let self;
export default {
    name: "tpl",
    components: {},
    props:{
      products:{
        type:Array,
        default:()=>[]
      }
    },
    data() {
        return {
           randomProducts: [],
        };
    },
    watch:{
      products(val){
        if(val && val.length > 0){
          this.randomProducts = val
        }
      }
    },
    mounted(){
        self = this;
        self.getRandomProducts();
    },
    beforeDestroy(){
        
    },
    methods:{
      getRandomProducts(){
          if(this.products.length > 0){
            return
          }
          self.randomProducts = self.$store.state.pageLoad.randomProducts || [];
          this.$http.get('listRandomProducts', {showLoading:false}).then((res)=>{
              self.randomProducts = res.data;
              self.$store.commit('setPageLoad', {randomProducts:res.data});
          });
      },  
    }
};
</script>

<style scoped lang='less' >

</style>
