<template>
  <div class="countdown-box">
    {{title}} {{hours}}:{{mins}}:{{secs}}:{{microSecs}}
  </div>
</template>

<script>
let self;
export default {
    name: "countdown",
    components: {},
    props: ['value','title'],
    data() {
        return {
            days:0,
            hours:0,
            mins:0,
            secs:0,
            microSecs:0,
            timer:null,
        };
    },
    watch:{
        value(oval, val){
            console.log(val);
        }
    },
    mounted(){
        self = this;
        self.start();
    },
    beforeDestroy(){
        
    },
    methods:{
        start(){
            let self = this;
            
            this.timer = setInterval(()=>{
                self.calcCountdown()
            }, 1);
        },
        calcCountdown(){
            let leftTime = parseInt(this.value - Date.now());

            if(leftTime <= 0){
                clearInterval(this.timer);
                return;
            }
            
            //天数
            // let days = Math.floor(leftTime/(24*3600*1000));
            // //count -= days * 24*3600*1000;
            // this.days = this.formatDate(days, 2);
            
            //小时
            let hours = Math.floor(leftTime/(3600*1000));
            //count -= hours * 3600 *1000;
            this.hours = this.formatDate(hours, 2);

            //分钟
            let mins = Math.floor(leftTime/(60*1000)%60);
            //count -= mins * 60*1000;
            this.mins = this.formatDate(mins, 2);

            //秒
            let  secs = Math.floor(leftTime/1000%60);
            //count -= secs * 1000;
            this.secs = this.formatDate(secs, 2);

            //毫秒
            this.microSecs = this.formatDate(leftTime%1000, 3);
        },
        formatDate(count,len){
            let strLen = count.toString().length;

            let zero = '';
            for(var i = 0; i < len - strLen ; i++){
                zero += '0';
            }
            return strLen >= len ? count.toString() : zero+count;
        },
    }
};
</script>

<style scoped lang='less' >

</style>
