<template>
    <div class="page-wrap">
        <div class="container">
            <div class="amount" v-if="step == 1">
                <span class="small">{{order.total_currency.code}}</span> {{order.total_currency.amount}}
            
                <div class="desc">
                   支付金额
                </div>
            </div>
            <div class="success-box" v-if="step == 2">
                <div class="desc-icon">
                    <md-icon name="success"></md-icon>
                </div>
                <div class="desc">
                   已成功支付<span class="small">{{order.total_currency.code}}</span> {{order.total_currency.amount}}
                </div>
                <div class="btns" v-if="orderType == 'normal' ">
                    <md-button type="default" round class="md" @click="$helper.goPage('/search', false, true)">继续购物</md-button>
                    <md-button type="primary" round class="md" @click="$helper.goPage('/order/'+order.order_sn, false, true)">查看订单</md-button>
                </div>
                <div class="btns" v-if="orderType == 'groupon'">
                    <md-button type="default" round class="md" @click="$helper.goPage('/search', false, true)">继续购物</md-button>
                    <md-button type="primary" round class="md" @click="$helper.goPage('/order-groupons/all', false, true)">查看订单</md-button>
                </div>
                <div class="btns" v-if="orderType == 'deposit'">
                    <md-button type="default" round class="md" @click="$helper.goPage('/auctions', false, true)">去参与竞拍</md-button>
                </div>
                <div class="btns" v-if="orderType == 'collect'">
                    <md-button type="default" round class="md" @click="$helper.goPage('/my-collect-orders', false, true)">查看账单</md-button>
                </div>
            </div>
            <div class="expire-box" v-if="step == 3">
                <div class="desc-icon">
                    <md-icon name="warn"></md-icon>
                </div>
                <div class="desc">
                   支付超时了
                   <div class="small">可能由于网络波动暂时未能获取到支付状态，如果您已支付，请稍后在 个人中心 - 订单 中查看订单状态</div>
                </div>
                <div class="btns" v-if="orderType == 'normal'">
                    <md-button type="default" round class="md" @click="$helper.orderToPay(order.order_sn)">重新支付</md-button>
                    <md-button type="primary" round class="md" @click="$helper.goPage('/order/'+order.order_sn, false, true)">查看订单</md-button>
                </div>
                <div class="btns" v-if="orderType == 'groupon'">
                    <md-button type="default" round class="md" @click="$helper.orderToPay(order.order_sn, 'groupon')">重新支付</md-button>
                </div>
                <div class="btns" v-if="orderType == 'deposit'">
                    <md-button type="default" round class="md" @click="$helper.goPage('/order-pay-deposit',true, true)">重新支付</md-button>
                </div>
                <div class="btns" v-if="orderType == 'collect'">
                    <md-button type="default" round class="md" @click="$helper.goPage('/collect/' + order.slug, true, true)">重新支付</md-button>
                </div>
            </div>
            <div class="block-item" v-if="orderType != 'deposit' && orderType != 'collect'">
                <div class="order-item" v-for="item in order.subjects" :key="item.id">
                    <div class="label">{{item.desc}}</div>
                    <div class="content">{{item.type == 'decr' ? '-':''}} <small>{{item.currency.code}}</small> {{item.currency.amount}}</div>
                </div>
                <div class="order-item">
                    <div class="label">订单总价</div>
                    <div class="content color-danger"><small>{{order.total_currency.code}}</small>{{order.total_currency.amount}}</div>
                </div>
                <div class="order-item">
                    <div class="label">订单编号</div>
                    <div class="content">{{order.order_sn}}</div>
                </div>
                <div class="order-item">
                    <div class="label">下单时间</div>
                    <div class="content">{{order.created_at}}</div>
                </div>
            </div>

            <md-landscape v-model="spinning" class="wait-pay-landscape">
                <div class="text-center" style="color: #fff;">
                    <md-icon name="spinner" size="lg" color="white" ></md-icon><br>
                    正在支付中
                    <br> <small style="color: rgba(255,255,255,0.7);">正在查询支付状态，请耐心等候！</small>
                </div>
            </md-landscape>

            <md-dialog
            title="微信支付提示"
            :closable="false"
            layout="column"
            v-model="wechatVisible"
            :btns="wechatModalBtns"
            >
            <div class="text-center">请确认微信支付是否已完成？</div>
            </md-dialog>
        </div>
    </div>
</template>

<script>
let self;
export default {
    name: "payCallback",
    components: {},
    data() {
        return {
            payment:'',
            orderSn:'',
            wechatVisible:false,
            wechatModalBtns: [
                {
                    text: '我已完成支付',
                    handler: ()=>{
                        this.wechatVisible = false;
                        this.checkOrderStatus()
                    },
                },
                {
                    text: '遇到问题，重新支付',
                    type: 'danger',
                    handler: this.backToPay,
                   
                }
            ],
            spinning:false,
            order:{
                total_currency:{}
            },
            timer:null,
            checkTimer:null,
            step: 1,
            orderType: 'normal'
        };
    },
    mounted(){
        document.title = '支付中';

        self = this;
        this.$store.dispatch('hideBottomBar');
        this.payment = this.$route.params.payment;
        this.orderSn = this.$route.params.orderSn;
        if(this.orderSn.indexOf('g_') > -1){
            this.orderSn = this.orderSn.substr(2);
            this.orderType = 'groupon';
            this.getGrouponOrder();
        }else if(this.orderSn.indexOf('deposit_') > -1){
            this.orderSn = this.orderSn.substr(8);
            this.orderType = 'deposit';
            this.getDepositOrder();
        }else if(this.orderSn.indexOf('collect_') > -1){
            this.orderSn = this.orderSn.substr(8);
            this.orderType = 'collect';
            this.getCollectOrder();
        }else{
            this.getOrder();
        }
        
        
    },
    beforeDestroy(){
        this.$store.commit('ableShowLoading');
        clearInterval(this.checkTimer);
    },
    methods:{
        backToPay(){
            this.$helper.orderToPay(this.orderSn)
        },
        getGrouponOrder(){
            this.$http.get('user/getGrouponOrder?order_sn='+ this.orderSn).then((res)=>{
                self.order = res.data;
                if(self.order.status >= 1){
                    self.step = 2;
                }else{
                    if(self.payment == 'wechat'){
                        self.wechatVisible = true;
                    }else{
                        self.checkOrderStatus();
                    }
                }
                
                
            }).finally(()=>{
                self.$store.commit('disableShowLoading');
            })
        },
        getDepositOrder(){
            this.$http.get('user/getAuctionDeposit').then((res)=>{
                self.order = res.data;
                if(self.order.status == 1){
                    self.step = 2;
                }else{
                    if(self.payment == 'wechat'){
                        self.wechatVisible = true;
                    }else{
                        self.checkOrderStatus();
                    }
                }
            }).finally(()=>{
                self.$store.commit('disableShowLoading');
            })
        },
        getCollectOrder(){
            this.$http.get('user/getCollectOrder?order_sn='+ this.orderSn).then((res)=>{
                self.order = res.data;
                if(self.order.status == 1){
                    self.step = 2;
                }else{
                    if(self.payment == 'wechat'){
                        self.wechatVisible = true;
                    }else{
                        self.checkOrderStatus();
                    }
                }
            }).finally(()=>{
                self.$store.commit('disableShowLoading');
            })
        },
        getOrder(){
            this.$http.get('user/getOrder?order_sn='+ this.orderSn).then((res)=>{
                self.order = res.data;
                if(self.order.status >= 1){
                    self.step = 2;
                }else{
                    if(self.payment == 'wechat'){
                        self.wechatVisible = true;
                    }else{
                        self.checkOrderStatus();
                    }
                }
                
                
            }).finally(()=>{
                self.$store.commit('disableShowLoading');
            })
        },
        checkOrderStatus(){
            let count = 0;
            this.spinning = true;
            let type = 0;
            if(this.orderType == 'groupon'){
                type = 2;
            }
            if(this.orderType == 'deposit'){
                type = 200;
            }
            if(this.orderType == 'collect'){
                type = 300;
            }
            this.checkTimer = setInterval(()=>{
                self.$http.post('user/checkOrderStatus',{order_sn:self.order.order_sn, type:type}).then((res)=>{
                    if(res.data.status == 'success'){
                        clearInterval(self.checkTimer);
                        self.spinning = false;
                        self.step = 2;
                    }
                    count++;

                    //支付超时
                    if(count >= 100 && self.step == 1){
                        clearInterval(self.checkTimer);
                        self.spinning = false;
                        self.step = 3;
                    }
                });
                
            },2000);
        }
    }
};
</script>
<style>
.wait-pay-landscape.md-landscape .md-icon.md-landscape-close{
    display: none;
}
</style>
<style scoped lang='less'>
@import "../assets/reset.less";
.page-wrap{
    
    background: rgba(0, 0, 0, 0.01);
}
.block-item{
    padding: 0.7rem 1rem;
    background: #fff;
    border-radius: 10px;
    position: relative;
    margin-bottom: 1rem;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.04), 0 5px 10px rgba(0, 0, 0, 0.02);
}
.block-item.info{
    border-radius: 0;
    margin-bottom: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    font-size: 16px;
    color: #111;
}
.block-item.info .icon{
    vertical-align: middle;
    display: inline-block;
}
.block-item.info .name{
    font-size: 14px;
}
.block-item .more-view{
    display: block;
    position: absolute;
    right: 0.7rem;
    top: calc(50% - 1rem);
    height: 1rem;
}
.block-item .more-view .md-icon.icon-font{
    font-size: 1rem;
    vertical-align: top;
}

.bottom-box{
    position: fixed;
    bottom: 0;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    left: 0;
    width: 100%;
    padding: 1rem;
    display: flex;
}
.bottom-box .md-button.md{
    height: 38px;
    line-height: 38px;
    flex: 1;
}
.bottom-box .md-button:first-child{
    margin-right: 0.5rem;
}
.amount{
    padding: 3rem;
    text-align: center;
    font-size: 40px;
    color: @info-color;
}
.amount small{
    margin-right: -10px;
    font-size: 18px;
}

.order-item{
    display: flex;
    margin-bottom: 1rem;
}
.order-item .lable{
    flex: 1;
    text-align: left;
}
.order-item .content{
    text-align: right;
    flex: 1;
}
.desc{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
}
.desc span{
    vertical-align: middle;
    display: inline-block;
}
.desc .md-icon{
    display: inline-block;
    vertical-align: middle;
}

.success-box,.expire-box{
    text-align: center;
    padding: 3rem;
}
.success-box .desc-icon{
    color: @success-color;
    margin-bottom: 0.5rem;
}
.success-box .desc-icon .md-icon, .expire-box .desc-icon .md-icon{
    font-size: 64px;
}
.success-box  .btns, .expire-box  .btns{
    display: flex;
    margin-top: 3rem;
}
.success-box  .btns .md-button, .expire-box  .btns .md-button{
    flex: 1;
}
.success-box  .btns .md-button:first-child,.expire-box  .btns .md-button:first-child{
    margin-right: 0.5rem;
}

.expire-box .desc-icon{
    color: @error-color;
    margin-bottom: 0.5rem;
}
</style>
