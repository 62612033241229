<template>
    <div class="page-wrap">
        <div class="container login-box">
            <div class="logo"><img src="../assets/logo-small.png" alt=""></div>
            
            <div v-if="type == 'mobile'">
                <div v-if="step == 1">
                    <div class="phone-area-code" @click="prefixesVisible = true">
                        <span>国家/地区</span>
                        <span>{{prefixCountry}}{{prefix}}<md-icon name="arrow-right"></md-icon></span>
                    </div>
                    <input type="text" class="username-input mt-1-5" placeholder="请输入手机号码" v-model="mobile">

                    <div class="valid-input">
                        <input type="text" v-model="captcha_code" placeholder="请输入图片验证码">
                        <span class="captcha-img" @click="refreshCaptcha">
                            <img :src="captchaImg" alt="">
                        </span>
                    </div>
                    <md-agree v-model="isRead" class="login mt-1-5">
                        <span class="small color-gray">我已阅读并同意</span><a @click="$router.push('/article/service')">《服务条款》</a>和<a @click="$router.push('/article/privacy')">《隐私政策》</a>,未注册的手机或邮箱将自动注册账户并登录
                    </md-agree>
                   
                    <md-button type="primary" round class="mt-2" :disabled="!mobile" @click="sendMobileCode">{{mobileSendText}}</md-button>
                </div>

                <div v-if="step == 2">
                    <div class="send-tips">
                        验证码已发送至：{{prefix}} {{mobile}}
                    </div>
                    <input type="text" class="username-input" placeholder="请输入验证码" v-model="code">
                    <div class="tips"><a class="color-primary" @click="sendMobileCode">{{mobileSendText}}</a></div>
                    <md-button type="primary" round class="mt-1-5" :disabled="!code"  @click="loginByMobile">确认</md-button>
                </div>
            </div>

            <div v-if="type == 'email'">
                <div v-if="step == 1">
                   
                    <input type="text" class="username-input" placeholder="请输入邮箱地址" v-model="email">
                    <div class="valid-input">
                        <input type="text" v-model="captcha_code" placeholder="请输入图片验证码">
                        <span class="captcha-img" @click="refreshCaptcha">
                            <img :src="captchaImg" alt="">
                        </span>
                    </div>
                    <md-agree v-model="isRead" class=" mt-1-5 login">
                        <span class="small color-gray">我已阅读并同意</span><a @click="$router.push('/article/service')">《服务条款》</a>和<a @click="$router.push('/article/privacy')">《隐私政策》</a>,未注册的手机或邮箱将自动注册账户并登录
                    </md-agree>
                   
                    <md-button type="primary" round class="mt-2" :disabled="!email" @click="sendEmailCode">获取验证码</md-button>
                </div>

                <div v-if="step == 2">
                    <div class="send-tips">
                        验证码已发送至：{{email}}
                    </div>
                    <input type="text" class="username-input" placeholder="请输入验证码" v-model="code">
                    <div class="tips"><a class="color-primary" @click="sendEmailCode">{{emailSendText}}</a></div>
                    <md-button type="primary" round class=" mt-1-5" :disabled="!code"  @click="loginByEmail">确认</md-button>
                </div>
            </div>
            <div v-if="type == 'account'">
                <input type="text" class="username-input" placeholder="请输入邮箱" v-model="username">
                <input type="password" class="username-input  mt-1-5" placeholder="请输入密码" v-model="password">
                <md-button type="primary" class=" mt-1-5" round :disabled="!username" @click="login" >登录</md-button>
            </div>

            <div class="login-types">
                <!-- <a @click="setType('register')">新用户注册</a> -->
                <a @click="setType('account')" v-if="type != 'account'">账户密码登录</a>
                <a @click="setType('email')" v-if="type != 'email'">邮箱验证码登录</a>
                <a @click="setType('mobile')" v-if="type != 'mobile'">短信验证码登录</a>
            </div>

            <div class="bottom-box" v-if="isWechat">
                <div class="title">其他登录方式</div>
                <div class="third-box">
                    <a class="wechat" @click="wechatLogin">
                        <svg t="1630979300693" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2227" width="26" height="26"><path d="M683.058 364.695c11 0 22 1.016 32.943 1.976C686.564 230.064 538.896 128 370.681 128c-188.104 0.66-342.237 127.793-342.237 289.226 0 93.068 51.379 169.827 136.725 229.256L130.72 748.43l119.796-59.368c42.918 8.395 77.37 16.79 119.742 16.79 11 0 21.46-0.48 31.914-1.442a259.168 259.168 0 0 1-10.455-71.358c0.485-148.002 128.744-268.297 291.403-268.297l-0.06-0.06z m-184.113-91.992c25.99 0 42.913 16.79 42.913 42.575 0 25.188-16.923 42.579-42.913 42.579-25.45 0-51.38-16.85-51.38-42.58 0-25.784 25.93-42.574 51.38-42.574z m-239.544 85.154c-25.384 0-51.374-16.85-51.374-42.58 0-25.784 25.99-42.574 51.374-42.574 25.45 0 42.918 16.79 42.918 42.575 0 25.188-16.924 42.579-42.918 42.579z m736.155 271.655c0-135.647-136.725-246.527-290.983-246.527-162.655 0-290.918 110.88-290.918 246.527 0 136.128 128.263 246.587 290.918 246.587 33.972 0 68.423-8.395 102.818-16.85l93.809 50.973-25.93-84.677c68.907-51.93 120.286-119.815 120.286-196.033z m-385.275-42.58c-16.923 0-34.452-16.79-34.452-34.179 0-16.79 17.529-34.18 34.452-34.18 25.99 0 42.918 16.85 42.918 34.18 0 17.39-16.928 34.18-42.918 34.18z m188.165 0c-16.984 0-33.972-16.79-33.972-34.179 0-16.79 16.927-34.18 33.972-34.18 25.93 0 42.913 16.85 42.913 34.18 0 17.39-16.983 34.18-42.913 34.18z" fill="#09BB07" p-id="2228"></path></svg>
                    </a>
                    <!-- <a class="google">
                        <svg t="1630979479809" fill="#d81e06" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8344" width="23" height="23"><path d="M928 0h-832C43.2 0 0 43.2 0 96v832c0 52.8 43.2 96 96 96h832c52.8 0 96-43.2 96-96v-832c0-52.8-43.2-96-96-96zM519.616 896c-212.224 0-384-171.776-384-384s171.776-384 384-384c103.616 0 190.4 37.824 257.216 100.416L672.64 328.832c-28.608-27.392-78.4-59.2-153.024-59.2C388.416 269.632 281.6 378.24 281.6 512s107.008 242.368 238.016 242.368c152 0 209.024-109.184 217.792-165.632H519.616V457.152h362.624c3.2 19.2 6.016 38.4 6.016 63.616C888.448 740.16 741.44 896 519.68 896z" p-id="8345"></path></svg>
                    </a>
                     -->
                </div>
                
            </div>
        </div>
        <md-popup v-model="prefixesVisible" position="bottom" class="prefixes-pop">
            <div class="block-title">
                <span>国家/地区</span>
            </div>
            <div class="prefixes-panel">
                <div class="prefix-item" @click="selectPrefix(item)" v-for="item in mobilePrefixes" :key="item.id">{{item.country}} +{{item.prefix}}</div>
            </div>
        </md-popup>

    </div>
</template>

<script>
let self;
export default {
    name: 'login',
    components: {
        
    },
    data(){
        return {
            loading:false,
            type:'mobile',
            step:1,
            loginForm:{},
            regForm:{},
            referer:'',
            username:'',
            mobile:'',
            email:'',
            password:'',
            code:'',
            imgCode:'',
            isRead:true,
            prefixesVisible:false,
            mobilePrefixes:[],
            prefix:'+86',
            prefixCountry:'中国',
            mobileSendText:"获取验证码",
            mobileWaiting:false,
            mobileTimer:null,
            captcha_code:'',
            captchaImg:"",
            emailSendText:"获取验证码",
            emailWaiting:false,
            emailTimer:null,
            isWechat:false,
        }
    },
    mounted(){
        document.title = '登录';
        this.$store.dispatch('hideBottomBar');
        this.$store.commit('disableShowLoading');
        this.isWechat = this.$helper.isWeChat();
     
        this.referer = this.$route.query.referer || '';
        self = this;
        
        this.getMobilePrefixes();
        this.refreshCaptcha();
    },
    beforeDestroy(){
        clearInterval(self.mobileTimer)
        clearInterval(self.emailTimer)
        this.$store.commit('ableShowLoading');
    },
    methods:{
        refreshCaptcha(){
            this.captchaImg = process.env.VUE_APP_API_HOST + '/api/captcha/login?t='+Math.random();
        },
        getMobilePrefixes(){
            this.$http.get('listMoilePrefixes').then((res)=>{
                self.mobilePrefixes = res.data;
                
            });
        },
        setType(type){
            this.type = type;
            this.step = 1;
        },
        loginCallback(res){
            this.$store.dispatch('setUserInfo', res.data);

            if(this.referer){
                if(this.referer == "seckill"){
                    window.location.href = "https://seckill.aiviy.com/?sk="+res.data.auth_token;
                    return;
                }
                this.$helper.goPage(this.referer, false, true);
            }else{
                this.$router.back();
            }
        },
        sendMobileCode(){
            if(!this.mobile){
                this.$toast.failed('请输入手机号');
                return;
            }
            if(!this.captcha_code){
                this.$toast.failed('请输入图片验证码');
                return;
            }
            if(self.mobileWaiting){
                return;
            }
            this.$http.post('sendCode',{type:'login', username: this.mobile, prefix: this.prefix,captcha_code:this.captcha_code}).then((res)=>{
                self.step = 2;
                self.$toast.succeed(res.msg);
                self.mobileWaiting = true;
                self.mobileCountdown();
            }).finally(()=>{
                self.refreshCaptcha();
            });
        },
        loginByMobile(){
            if(!this.mobile){
                //
                this.$toast.failed('请输入手机号码')
                return;
            }
            if(!this.code){
                //
                this.$toast.failed('请输入验证码')
                return;
            }

            this.$http.post('loginByCode',{type:'login', username: this.mobile, code:this.code, prefix: this.prefix}).then((res)=>{
                self.loginCallback(res);
            });
        },
        sendEmailCode(){
            if(!this.email){
                this.$toast.failed('请输入邮箱地址');
            }
            if(!this.captcha_code){
                this.$toast.failed('请输入图片验证码');
                return;
            }
            if(self.emailWaiting){
                return;
            }
            this.$http.post('sendCode',{type:'login', username: this.email, captcha_code:this.captcha_code}).then((res)=>{
                self.step = 2;
                self.$toast.succeed(res.msg);
                self.emailWaiting = true;
                self.emailCountdown();
            }).finally(()=>{
                self.refreshCaptcha();
            });
        },
        mobileCountdown(){
            //重试倒计时
            let count = 60;
            self.mobileTimer = setInterval(()=>{
                count--;
                self.mobileSendText = count + "秒后重试"
                if(count <= 0){
                    clearInterval(self.mobileTimer);
                    self.mobileWaiting = false;
                    self.mobileSendText = "获取验证码";
                }
            },1000)
        },
        emailCountdown(){
            //重试倒计时
            let count = 60;
            self.emailTimer = setInterval(()=>{
                count--;
                self.emailSendText = count + "秒后重试"
                if(count <= 0){
                    clearInterval(self.emailTimer);
                    self.emailWaiting = false;
                    self.emailSendText = "获取验证码";
                }
            },1000)
        },
        loginByEmail(){
            if(!this.email){
                //
                this.$toast.failed('请输入邮箱地址')
                return;
            }
            if(!this.code){
                //
                this.$toast.failed('请输入验证码')
                return;
            }

            this.$http.post('loginByCode',{type:'login', username: this.email, code:this.code}).then((res)=>{
                self.loginCallback(res);
            });
        },
        login(){
            if(!this.username){
                //
                this.$toast.failed('请输入手机号或邮箱')
                return;
            }
            if(!this.password){
                //
                this.$toast.failed('请输入密码')
                return;
            }

            this.$http.post('login',{type:'login', email: this.username, password:this.password}).then((res)=>{
                self.loginCallback(res);
            });
        },
        register(){
            this.$http.post('register',this.regForm).then((res)=>{
                self.loginCallback(res);
                self.$toast.succeed(res.msg);
            });
        },
        selectPrefix(item){
            this.prefix = '+'+item.prefix;
            this.prefixCountry = item.country;
            this.prefixesVisible = false;
        },
        wechatLogin(){
            let referer = this.referer || "/my-account";
            window.location.href = "/api/wechatMpLogin?referer="+referer;
        }
    }
}
</script>


<style>
.login.md-agree .md-agree-content{
    font-size: 12px;
    color: rgba(0,0,0,0.5);
}
.login.md-agree .md-agree-content a{
    color: rgba(0,0,0,0.9);
}

</style>
<style scoped lang='less'>
.phone-area-code{
    height: 48px;
    line-height: 48px;
    display: flex;
    background: rgba(0, 0, 0, 0.03);
    border-radius: 25px;
    padding: 0 1.2rem;
    color: rgba(0, 0, 0, 0.7);
}
.phone-area-code span:first-child{
    flex: 1;
    text-align: left;
}
.phone-area-code span:last-child{
    flex: 1;
    text-align: right;
}
.username-input, .valid-input{
    -webkit-appearance: none;
    -webkit-box-shadow: none;
    outline: none;
    width: 100%;
    background: rgba(0, 0, 0, 0.03);
    height: 48px;
    line-height: 48px;
    padding-left: 1.2rem;
    border-radius: 25px;
    border: 1px solid rgba(37, 198, 252, 0);
    transition: all 0.2s;
    font-size: 18px;
}
.username-input::placeholder, .valid-input input::placeholder{
    font-size: 14px;
}
.valid-input{
    display: flex;
    margin-top: 1rem;
    background: transparent;
    border: 2px solid rgba(0, 0, 0, 0.03);
}
.valid-input input{
    -webkit-appearance: none;
    -webkit-box-shadow: none;
    outline: none;
    border: none;
    width: 100%;
    background: none;
}
.valid-input .captcha-img{
    width: 103px;
    padding-right: 5px;
    padding-top: 4px;
}
.valid-input .captcha-img img{
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.desc{
    margin-bottom: 20px;
}
.send-tips{
    font-size: 13px;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 1rem;
    padding-left: 20px;
}
.login-box {
    padding: 1rem;
}

.confirm-btn{
    margin-top: 20px;
}

.third-box{
    text-align: center;
    margin-top: 0.7rem;
    margin-bottom: 1rem;
}
.third-box a{
    display: inline-block;
    min-width: 45px;
    height: 45px;
    border-radius: 25px;
    padding-top: 8px;
    margin: 0 1rem;
    font-size: 12px;
    text-align: center;
    vertical-align: middle;
}
.third-box a svg{
    vertical-align: middle;
}

.third-box a.wechat{
    background: rgba(9, 187, 7,0.1);
    border: 1px solid rgba(9, 187, 7,0);
}
.third-box a.google{
    background: rgba(216, 30, 6, 0.1);
    border: 1px solid rgba(216, 30, 6, 0);
    padding-top:10px
}

.bottom-box{
    margin-top: 3rem;
    width: 100%;
}
.bottom-box .title{
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
    font-size: 12px;
}
.logo{
    text-align: center;
    padding-top: 1rem;
    margin-bottom: 2rem;
}
.logo img{
    height: 80px;
   
}
.tips{
    font-size: 13px;
    color: rgba(0, 0, 0, 0.3);
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: right;
}
.login-types{
    display: flex;
    margin-top: 1.5rem;
}
.login-types a{
    color: rgba(0, 0, 0, 0.5);
    flex: 1;
    text-align: center;
    position: relative;
}
.login-types a::after{
    content: '';
    display: block;
    position: absolute;
    height: 10px;
    width: 1px;
    background: rgba(0, 0, 0, 0.1);
    right: 0;
    top: 4px;
}
.login-types a:last-child::after{
    display: none;
}

.prefixes-panel {
  max-height: calc(100% - 40px);
  overflow-y: auto;
  overflow-x: hidden;
}
.prefix-item{
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
    padding: 1rem 0;
}
</style>
