<template>
    <div class="page-wrap">
        <div class="addresses-list">
            <div class="address-item" v-for="item in addresses" :key="item.id">
                <div class="">
                    <md-tag type="fill" shape="circle" fill-color="#25c6fc" font-color="#fff" v-if="item.is_default">默认</md-tag>
                    <span class="name">{{item.consignee}} {{item.mobile}}</span>
                </div>
                <div class="info">
                    {{item.province}} {{item.city}} {{item.area}} {{item.address}} 邮编：{{item.zipcode}}
                </div>
                <div class="btns">
                    <md-button size="small" type="default" round @click="showForm(item)">编辑</md-button>
                    <md-button size="small" type="warning" round @click="delAddress(item.id)">删除</md-button>
                </div>
            </div>
        </div>

        <div class="bottom-box">
            <md-button type="primary" round class="md" @click="showForm({})">新增地址</md-button>
        </div>

        <md-popup
        v-model="addressVisible"
        position="bottom"
        class="address-pop"
        >
            <div class="block-title">
                    <span>收货地址</span>
                    <a @click="addressVisible = false" class="close"><md-icon name="close"></md-icon></a>
                </div>
                <div class="address-panel">
                    <md-input-item
                        preview-type="text"
                        v-model="form.consignee"
                        title="收件人姓名"
                        placeholder="收件人"
                        is-title-latent
                    ></md-input-item>
                    <md-input-item
                        preview-type="text"
                        v-model="form.mobile"
                        title="手机号码"
                        placeholder="手机号码"
                        is-title-latent
                        ></md-input-item>
                    <md-input-item
                        preview-type="text"
                        v-model="form.zipcode"
                        title="邮政编码"
                        placeholder="邮政编码"
                        is-title-latent
                        ></md-input-item>
                    <md-input-item
                        preview-type="text"
                        v-model="form.province"
                        title="省份"
                        placeholder="省份"
                        is-title-latent
                    ></md-input-item>    
                    <md-input-item
                        preview-type="text"
                        v-model="form.city"
                        title="城市"
                        placeholder="城市"
                        is-title-latent
                    ></md-input-item> 
                    <md-input-item
                        preview-type="text"
                        v-model="form.area"
                        title="区/街道"
                        placeholder="区/街道"
                        is-title-latent
                    ></md-input-item> 
                    <md-input-item
                        preview-type="text"
                        v-model="form.address"
                        title="详细地址"
                        placeholder="详细地址"
                        is-title-latent
                    ></md-input-item> 
                    <div class="md-field-item-content">
                        <md-check v-model="form.is_default" label="设为默认" />
                    </div>
                        
                   
                </div>
                 <div class="btns">
                    <md-button type="primary" round class="md" @click="submit">保存</md-button>
                </div>
        </md-popup>
    </div>
</template>

<script>
let self;
export default {
    name: "addresses",
    components: {},
    data() {
        return {
            addressVisible:false,
            form:{},
            addresses:{},
            opt:""
        };
    },
    mounted(){
        self = this;
        this.opt = this.$route.query.opt || "";
        this.$store.dispatch('hideBottomBar');
        this.getAddresses();
        if(this.opt == "addrFromGrid"){
            this.showForm({});
        }
    },
    beforeDestroy(){
        
    },
    methods:{
        getAddresses(){
            this.$http.get('user/listAddresses').then((res)=>{
                self.addresses = res.data;
            })
        },
        showForm(item){
            this.form = {...item};
            this.addressVisible = true;
        },
        submit(){
            this.$http.post('user/saveAddress',this.form).then((res)=>{
                self.$toast.succeed(res.msg);
                self.addressVisible = false;
                self.getAddresses();
                if(self.opt == "addrFromGrid"){
                    setTimeout(()=>{
                        self.$router.back()
                    },1000)
                }
            })
        },
        delAddress(id){
            this.$dialog.confirm({
                title: '提示',
                content: '确认要删除该地址吗？',
                confirmText: '确定',
                onConfirm: () => {
                    this.$http.post('user/delAddress',{id:id}).then((res)=>{
                        self.$toast.succeed(res.msg);
                        self.getAddresses();
                    });
                },
            })
            
        }
    }
};
</script>

<style scoped lang='less' >
.page-wrap{
    background: rgba(0, 0, 0, 0.01);
}
.addresses-list{
    padding: 1rem 0.7rem;
}
.addresses-list .address-item{
    border-radius: 10px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.04), 0 5px 10px rgba(0, 0, 0, 0.02);
    padding: 1rem 0.7rem;
    background: #fff;
    margin-bottom: 0.5rem;
}
.address-item .md-tag+.name{
    font-weight: 500;
    margin-left: 10px;
}
.address-item .info{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.7);
    margin-top: 0.5rem;
}
.address-item .btns{
    text-align: right;
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    border-top: 1px dashed rgba(0, 0, 0, 0.1);
}
.address-item .btns .md-button{
    display: inline-block;
    width: 60px;
    margin-left: 10px;
}
.address-panel .btns{
    bottom: 0;
    left: 0;
    width: 100%;
    position: absolute;

}
.address-panel{
    max-height: calc(100% - 80px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 10px
}
.bottom-box{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem;
    background: transparent;
    border-top: none;
}
</style>
