<template>
    <div class="page-wrap">

        <div class="container">

            

            <div class="amount">
                <small>{{order.total_currency.code}}</small> {{order.total_currency.amount}}
                <div class="label">账单金额</div>
            </div>

            <div class="block-item payments">
                <div class="title">选择支付方式</div>

                
                <div class="payment-item" @click="setPayment('alipay')" v-if="!isWechat">
                    <div class="label"><img height="24" src="../assets/alipay.svg" alt=""></div>
                    <div class="check">
                        <md-radio name="alipay" v-model="payment" />
                    </div>
                </div>
                <div class="payment-item" @click="setPayment('wechat')">
                    <div class="label"><img height="25" src="../assets/wechat.svg" alt=""></div>
                    <div class="check">
                        <md-radio name="wechat" v-model="payment" />
                    </div>
                </div>

                <div class="payment-item" @click="setPayment('paypal')" v-if="order.total_amount > 10">
                    <div class="label"><img height="22" src="../assets/paypal.svg" alt=""></div>
                    <div class="check">
                        <md-radio name="paypal" v-model="payment" />
                    </div>
                </div>

                <div class="payment-item"  @click="setPayment('stripe')" v-if="order.total_amount > 10">
                    <div class="label" >
                        <img height="22" src="../assets/credit.svg" alt="" style="vertical-align: top;">
                        <md-icon name="info" @click.stop="creditVisible = true"></md-icon>
                    </div>
                    <div class="check">
                        <md-radio name="stripe" v-model="payment" />
                    </div>
                </div>
            </div>

            <div class="info-box">
                <div class="title">账单信息</div>
                <div class="desc">{{order.desc}}</div>
            </div>

            <div class="bottom-box">
                <!-- paypal -->
                <div :class="{hide:payment != 'paypal'}" class="pay-btn" id='paypal-button-container'></div>
                <!-- paypal -->
                <!-- strip-pay -->
                <form id="payment-form" :class="{hide:payment != 'stripe'}">
                    <div id="card-element"><!--Stripe.js injects the Card Element--></div>
                    <div class="stripe-error">{{stripe_error}}</div>
                    <button type="submit" class="md-button primary active block round md">确认支付</button>
                </form>
                <!-- strip-pay -->
                <md-button type="primary" class="md" round v-if="payment != 'paypal' && payment != 'stripe'" @click="createPay">确认支付</md-button>
            </div>

            <md-popup
            v-model="creditVisible"
            position="bottom"
            class="credit-pop"
            >
                <div class="credit">
                    <div class="block-title">
                        <span>信用卡支付说明</span>
                    </div>
                    <div>
                        支持当前流行的各种信用卡：Visa，Mastercard，American Express，Discover，Diners Club，JCB等。
                        若您的卡开通了 3D Secure 验证，请按操作说明进行下一步，单击 "COMPLETE AUTHENTICATION" 验证你的卡片。
                    </div>
                </div>
            </md-popup>

        </div>
    </div>
</template>

<script>
let self;
export default {
    name: "orderPay",
    components: {},
    data() {
        return {
            slug:'',
            isRead:true,
            orderVisible:false,
            creditVisible:false,
            order:{},
            timer:null,
            checkTimer:null,
            payment:'alipay',
            pay_url:'',
            scanVisible:false,
            webVisible :false,
            payExpireVisible:false,
            pay_qrcode : '',
            step:1,
            stripe_error:'',
            isWechat:false,
            wechatJsPayParams:{},
            initedPaypal:false,
            initedStripe:false
        };
    },
    mounted(){
        document.title = '账单支付';

        self = this;
        this.$store.dispatch('hideBottomBar');
        this.slug = this.$route.params.slug;
        this.isWechat = this.$helper.isWeChat();
        if(this.isWechat){
            this.payment = 'wechat';
        }

        this.getOrder();
    },
    beforeDestroy(){
        clearInterval(this.timer);
    },
    methods:{
        getOrder(){
            this.$http.get('user/getCollectPay/'+ this.slug).then((res)=>{
                self.order = res.data;
            }).catch((res)=>{
               if(res.ret != 403){
                    setTimeout(()=>{
                        self.$router.push('/');
                    },1500)
                }
            })
        },
        setPayment(payment){
            this.payment = payment;
            if(this.payment == 'paypal' && this.initedPaypal == false){
                this.initedPaypal = true;
                this.initPaypal();
            }
            if(this.payment == 'stripe' && this.initedStripe == false){
                this.initedStripe = true;
                this.initStripe();
            }
        },
        initPaypal(){
            let self = this;
            // eslint-disable-next-line no-undef
            paypal.Buttons({
                // eslint-disable-next-line no-undef
                fundingSource:paypal.FUNDING.PAYPAL,
                style:{
                    height: 38,
                    color:'gold',
                    shape:'pill'
                },
                createOrder: function() {
                    return self.$http.post('user/create/paypal/wap',{type:300, order_sn:self.order.order_sn}).then((res)=>{
                        return res;
                    }).then((res)=>{
                        return res.data.result.id
                    });
                },
                onApprove: function() {
                    self.$helper.goPage('/pay-callback/paypal/'+ 'collect_' + self.order.order_sn,false,true);
                    return true;
                }
            }).render('#paypal-button-container');
        },
        initStripe(){
            var self = this;
            
            // eslint-disable-next-line no-undef
            var stripe = Stripe("pk_live_sb3YeRMdoizkQ6Jqiu49GjVk");
            var elements = stripe.elements();
            var style = {
                base: {
                    color: "#244583",
                    fontFamily: 'Arial, sans-serif',
                    fontSmoothing: "antialiased",
                    fontSize: "16px",
                    "::placeholder": {
                        color: "#333"
                    }
                },
                invalid: {
                    fontFamily: 'Arial, sans-serif',
                    color: "#fa755a",
                    iconColor: "#fa755a"
                }
            };
            var card = elements.create("card", { style: style });
            card.mount("#card-element");
            card.on("change", function (event) {
                self.stripe_error = event.error ? event.error.message : "";
            });
            var form = document.getElementById("payment-form");
            form.addEventListener("submit", function(event) {
                event.preventDefault();
                
                
                self.$http.post('user/create/stripe/wap',{type:300, order_sn:self.order.order_sn}).then((res)=>{
                    stripe.confirmCardPayment(res.data.client_secret, {
                        payment_method: {
                            card: card
                        }
                    }).then(function(result) {
                        
                        if (result.error) {
                           
                            self.$toast.error(result.error.message);
                        } else {
                            self.$helper.goPage('/pay-callback/stripe/'+ 'collect_' + self.order.order_sn,false,true);
                        }
                    }).finally(()=>{
                        self.$store.commit('hideLoading');
                    });
                }).finally(()=>{
                    self.$store.commit('showLoading');
                });
            });
            
        },
        onBridgeReady() {
            // eslint-disable-next-line no-undef
            WeixinJSBridge.invoke('getBrandWCPayRequest', self.wechatJsPayParams, function(res){
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                    self.$helper.goPage('/pay-callback/wechat/'+ 'collect_'+self.order.order_sn, false, true);
                }else{
                    self.$toast.failed('支付已取消');
                }
            });
        },
        createPay(){
            let self = this;
            //微信环境中支付
            if(this.isWechat && this.payment == 'wechat'){
                self.$http.post('user/create/'+this.payment+'/mp',{type:300, order_sn:this.order.order_sn}).then((res)=>{
                    self.wechatJsPayParams = res.data.jspay_params;
                    // eslint-disable-next-line no-undef
                    if (typeof WeixinJSBridge == "undefined") {
                        if (document.addEventListener) {
                            document.addEventListener('WeixinJSBridgeReady', self.onBridgeReady, false);
                        } else if (document.attachEvent) {
                            document.attachEvent('WeixinJSBridgeReady', self.onBridgeReady);
                            document.attachEvent('onWeixinJSBridgeReady', self.onBridgeReady);
                        }
                    } else {
                        self.onBridgeReady();
                    }
                });
            }else{
                self.$http.post('user/create/'+this.payment+'/wap',{type:300, order_sn:this.order.order_sn}).then((res)=>{
                    if(self.payment == 'alipay'){
                        document.write(res.data.form)
                    }else{
                        window.location.replace(res.data.url);
                    }
                    
                });
            }
            
        }
    }
};
</script>
<style>
#paypal-button-container>div{
    vertical-align: middle;
}
.payment-item  .check .md-icon.icon-font{
    font-size: 20px;
}
</style>
<style scoped lang='less'>
@import "../assets/reset.less";
.page-wrap{
    
    background: #071726;
}
.block-item{
    padding: 0.7rem 1rem;
    background: #fff;
    border-radius: 10px;
    position: relative;
    margin-bottom: 1rem;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.04), 0 5px 10px rgba(0, 0, 0, 0.02);
}
.block-item.info{
    border-radius: 0;
    margin-bottom: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    font-size: 16px;
    color: #111;
}
.block-item.info .icon{
    vertical-align: middle;
    display: inline-block;
}
.block-item.info .name{
    font-size: 14px;
}
.block-item .more-view{
    display: block;
    position: absolute;
    right: 0.7rem;
    top: calc(50% - 1rem);
    height: 1rem;
}
.block-item .more-view .md-icon.icon-font{
    font-size: 1rem;
    vertical-align: top;
}

.bottom-box{
    position: fixed;
    bottom: 0;
    background: transparent;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    left: 0;
    width: 100%;
    padding: 1rem;
    z-index: 10;
}
.bottom-box .md-button.md{
    height: 38px;
    line-height: 38px;
}
.amount{
    padding: 1rem;
    text-align: center;
    font-size: 40px;
    color: #fff;
}
.amount small{
    margin-right: -10px;
    font-size: 18px;
}
.amount .label{
    font-size: 14px;
}

.payments .title{
    position: relative;
    margin-bottom: 1rem;
}
.payments .title::before{
    content: '';
    display: block;
    height: 0.7rem;
    width: 3px;
    border-radius: 3px;
    background: @primary-color;
    position: absolute;
    left: -0.5rem;
    top: 0.3rem;
}


.payment-item{
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.02);
    padding: 0.7rem 0;
}
.payment-item:last-child{
    margin-bottom: 0;
    
    border-bottom: none;
}
.payment-item .label{
    text-align: left;
    flex: 1;
}
.payment-item .check{
    text-align: right;
}
.payment-item .md-radio{
    margin: 0;
}



.countdown{
    font-size: 13px;
    color: #111;
    text-align: center;
}
.order-item{
    display: flex;
    margin-bottom: 1rem;
}
.order-item .lable{
    flex: 1;
    text-align: left;
}
.order-item .content{
    text-align: right;
    flex: 1;
}
.StripeElement{
    padding: 1rem;
    background: rgba(255, 255, 255, 1);
    color: #fff;
    border-radius: 5px;
}
.stripe-error{
    margin-bottom: 1rem;
    font-size: 12px;
    text-align: center;
    color: #f96062;
    margin-top: 0.5rem;
}

.info-box{
    padding: 1rem 0.7rem;
    color: #fff;
}
.info-box .title{
    font-size: 18px;
    margin-bottom: 1rem;
}
.info-box .desc{
    white-space: pre-wrap;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
}
</style>
