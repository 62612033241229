<template>
    <div class="page-wrap">
        <div class="fixed-top">
            <div class="cates-box">
                <a class="item" :class="{active:item.id == cid}" v-for="item in categories" :key="item.id"  @click="selectCate(item.id)">{{item.name}}</a>
            </div>
        </div>

        <div class="emtpy" v-if="articles.total <= 0">
            <p><svg t="1631180042906" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16472" width="64" height="64"><path d="M855.6 427.2H168.5c-12.7 0-24.4 6.9-30.6 18L4.4 684.7C1.5 689.9 0 695.8 0 701.8v287.1c0 19.4 15.7 35.1 35.1 35.1H989c19.4 0 35.1-15.7 35.1-35.1V701.8c0-6-1.5-11.8-4.4-17.1L886.2 445.2c-6.2-11.1-17.9-18-30.6-18zM673.4 695.6c-16.5 0-30.8 11.5-34.3 27.7-12.7 58.5-64.8 102.3-127.2 102.3s-114.5-43.8-127.2-102.3c-3.5-16.1-17.8-27.7-34.3-27.7H119c-26.4 0-43.3-28-31.1-51.4l81.7-155.8c6.1-11.6 18-18.8 31.1-18.8h622.4c13 0 25 7.2 31.1 18.8l81.7 155.8c12.2 23.4-4.7 51.4-31.1 51.4H673.4zM819.9 209.5c-1-1.8-2.1-3.7-3.2-5.5-9.8-16.6-31.1-22.2-47.8-12.6L648.5 261c-17 9.8-22.7 31.6-12.6 48.4 0.9 1.4 1.7 2.9 2.5 4.4 9.5 17 31.2 22.8 48 13L807 257.3c16.7-9.7 22.4-31 12.9-47.8zM375.4 261.1L255 191.6c-16.7-9.6-38-4-47.8 12.6-1.1 1.8-2.1 3.6-3.2 5.5-9.5 16.8-3.8 38.1 12.9 47.8L337.3 327c16.9 9.7 38.6 4 48-13.1 0.8-1.5 1.7-2.9 2.5-4.4 10.2-16.8 4.5-38.6-12.4-48.4zM512 239.3h2.5c19.5 0.3 35.5-15.5 35.5-35.1v-139c0-19.3-15.6-34.9-34.8-35.1h-6.4C489.6 30.3 474 46 474 65.2v139c0 19.5 15.9 35.4 35.5 35.1h2.5z" p-id="16473" fill="#bfbfbf"></path></svg></p>
            <p>暂无文章</p>
        </div>
        <div v-else class="list">
            <div class="article-item" v-for="item in articles.data" :key="item.id" @click="$helper.goPage('/article/'+item.slug)">
                <div class="title">{{item.title}}</div>
                <div class="date">{{item.created_at}}</div>
                <div class="desc">{{item.desc}}</div>
            </div>
        </div>
    </div>
</template>

<script>
let self;
export default {
    name: "articles",
    components: {},
    data() {
        return {
            categories:[],
            articles:{},
            cid:0,
            isLoading:false,
            isNoMore:false,
            
        };
    },
    mounted(){
        self = this;
         this.$store.dispatch('hideBottomBar');
        this.getCategories();
        window.onscroll = ()=>{
            if(self.$route.path.indexOf('/articles') != -1){
                self.endReached();
            }
        }
    },
    beforeDestroy(){
        
    },
    methods:{
        getCategories(){
            this.$http.get('listArticleCategories').then((res)=>{
                self.categories = res.data;
                self.cid = self.$route.params.cid || self.categories[0].id;
                self.getArticles();
            });
        },
        getArticles(){
            let params = {
                page:this.page,
                cid:this.cid
            };
            this.$http.get('listArticles',{params:params}).then((res)=>{
               if(params.page == 1){
                    self.articles = res.data;
                }else if(res.data.data.length > 0){
                    self.articles.data = self.articles.data.concat(res.data.data);
                }
               
            }).finally(()=>{
                self.isLoading = false;
            });
        },
        endReached(){
            
            let top = document.documentElement.scrollTop || document.body.scrollTop;
            
            //触底
            if(top + window.innerHeight  > document.getElementsByClassName('page-wrap')[0].offsetHeight - 90){
                if(this.isLoading){
                    return;
                }
                if(this.page >= this.articles.last_page ){
                    self.isNoMore = true;
                    return;
                }
                this.page++;
                this.isLoading = true;
                this.getArticles();
            }
        },
        selectCate(cid){
            this.cid = cid;
            this.page =1;
            this.getArticles();
        }
    }
};
</script>

<style scoped lang='less' >
.emtpy{
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    padding: 3rem 0;
}
.page-wrap{
    background: rgba(0, 0, 0, 0.01);
    padding-bottom:0;
    padding-top: 4rem;
}
.fixed-top{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 0.7rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}
.cates-box{
    white-space: nowrap;
    overflow-x: auto;
}
.cates-box .item{
    display: inline-block;
    padding: 4px 10px;
    border-radius: 20px;
    color: rgba(0, 0, 0, 1 );
    background: rgba(0, 0, 0, 0.03);
    border: 1px solid rgba(0, 0, 0, 0);
    margin-right: 8px;
}
.cates-box .item.active{
    background: rgba(37, 198, 252, 0.1);
    color: rgba(37, 198, 252, 1);
    border: 1px solid rgba(37, 198, 252, 1);
}

.article-item{
    background: #fff;
    margin: 0.7rem;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.03), 0 5px 10px rgba(0, 0, 0, 0.02), 0 1px 3px rgba(0, 0, 0, 0.01);
}
.article-item .title{
    font-size: 16px;
    font-weight: 500;
    color: #111;
    margin-bottom: 5px;
}
.article-item .date{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
}
.article-item .desc{
    font-size: 13px;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 1rem;
}
</style>
