import Vue from 'vue'
import mandMobile from 'mand-mobile'

import less from 'less'
import store from './store'
import router from './router'
import http from './utils/http'
import helper from './utils/helper'

import VueLuckyCanvas from '@lucky-canvas/vue'

import 'mand-mobile/lib/mand-mobile.css';
import './assets/style.less';

import App from './App.vue'

Vue.config.productionTip = false

Vue.use(mandMobile)
Vue.use(less)
Vue.use(VueLuckyCanvas)

Vue.prototype.$http = http
Vue.prototype.$helper = helper

const app = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

export default app