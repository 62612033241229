<template>
    <div class="page-wrap">
        <div class="block-item addr" @click="showAddress" v-if="form.product_type == 1">
            <template v-if="form.address.consignee">
                <div>{{form.address.consignee}} {{form.address.mobile}}</div>
                <div class="color-gray small">{{form.address.province}} {{form.address.city}} {{form.address.area}} {{form.address.address}}</div>
            </template>
            <template v-else>
                <div class="small color-gray">填写收货信息</div>
            </template>
            <a class="more-view"><md-icon name="arrow-right"></md-icon></a>
        </div>
        <div class="block-item addr" @click="showAccount" v-if="form.product_type == 0">
            <template v-if="form.account.first_name">
                <div>{{form.account.first_name}} {{form.account.last_name}}</div>
                <div class="color-gray small">{{form.account.email}}</div>
            </template>
            <template v-else>
                <div class="small color-gray">填写授权信息</div>
            </template>
            <a class="more-view"><md-icon name="arrow-right"></md-icon></a>
        </div>
        <div class="container">
            <div class="block-item">
                <div class="title"><md-icon name="order"></md-icon> 订单详情</div>
                <div class="order-product-list">
                <template v-for="item in form.products" >
                    <div class="order-product-item" :key="item.id">
                        <img :src="$store.state.assetUrl + item.product.img" alt="">
                        <div class="info">
                            <div class="name text-ellipsis">{{item.product.name}}</div>
                            <div class="spec-desc">{{item.spec_desc}}</div>
                            <div class="price">
                                
                                <div class="text-left color-primary">
                                    <small>{{item.groupon_sponsor_currency.code}}</small>
                                    <span v-if="form.grouponParentId == 0">{{item.groupon_sponsor_currency.amount}}</span>
                                    <span v-else>{{item.groupon_currency.amount}}</span>
                                </div>
                            </div>
                        </div>   
                    </div>
                </template>
                </div>
            </div>
            <div class="block-item groupon-box">
                <div>拼团信息</div>
                <template v-if="form.grouponParentId == 0">
                    <div >开团成功后，请在{{groupon.expire_days*24}}小时内再邀请{{groupon.left_count}}名好友购买即可成团。</div>
                </template>
                <template v-if="form.grouponParentId > 0">
                    <div v-if="groupon.left_count > 0">参团后，还需要{{groupon.left_count}}名好友购买才能成团。</div>
                    <div v-if="groupon.left_count == 0">支付成功后即可成团。</div>
                    <div><countdown :value="groupon.deadline" title="拼团截至"></countdown></div>
                </template>
                <div>当前团购活动有效期为：{{groupon.start_at}} ~ {{groupon.end_at}}</div>
            </div>
            <div class="block-item"  v-if="form.product_type == 0">
                
                <div class="order-item" @click="showInvoice" >
                    <div class="label">开具发票</div>
                    <div class="content">
                        <span v-if="form.invoice.need_invoice">{{form.invoice.head}}</span>
                        <span v-else>不开发票</span>
                    </div>
                    <a class="more-view"><md-icon name="arrow-right"></md-icon></a>
                </div>
                
            </div>
            <div class="block-item" v-if="form.invoice.need_invoice && form.invoice.invoice_type == 1">
                <div class="order-item" @click="showAddress">
                    <div class="label">发票收件地址</div>
                    <div class="content">
                        <span v-if="form.address.consignee">{{form.address.consignee}}</span>
                        <span v-else>请填写地址</span>
                    </div>
                    <a class="more-view"><md-icon name="arrow-right"></md-icon></a>
                </div>
            </div>
            
            <div class="block-item amount">
                <div class="order-item" v-for="item in form.subjects" :key="item.id">
                    <div class="label">{{item.desc}}</div>
                    <div class="content">
                        <span class="color-primary">{{item.type == 'decr' ? '-':''}} <span class="small">{{item.currency.code}}</span>{{item.currency.amount}}</span>
                    </div>
                </div>
            </div>

            <div>
                <md-agree v-model="isRead" >
                    <span class="small color-gray">
                    我已阅读并同意 <a @click="$router.push('/article/service')">《服务条款》</a>和<a @click="$router.push('/article/privacy')">《隐私政策》</a>
                    </span>
                </md-agree>
            </div>
            
            <div class="bottom-box">
                <div class="total">
                    <div class="price"><small>{{form.total_currency.code}}</small>{{form.total_currency.amount}}</div>
                    <div class="small color-gray">订单总金额</div>
                </div>
                <md-button type="primary" class="md" round @click="saveOrder" :disabled="loading || !isRead">提交订单</md-button>
            </div>



            

            <md-popup
            v-model="invoiceVisible"
            position="bottom"
            class="invoice-pop"
            >
                <div class="block-title">
                    <span>发票</span>
                    <a @click="invoiceVisible = false" class="close"><md-icon name="close" ></md-icon></a>
                </div>
                <div class="invoice-panel">
                    <md-field-item title="是否开发票" solid  >
                        <md-radio  :name="0" v-model="invoiceForm.need_invoice" label="不开" inline></md-radio>
                        <md-radio  :name="1" v-model="invoiceForm.need_invoice" label="发票信息" inline ></md-radio>
                    </md-field-item>
                    
                    <template v-if="invoiceForm.need_invoice">
                        
                        <md-field-item title="发票类型" solid @click="changeInvoiceType">
                            <md-radio  :name="0" v-model="invoiceForm.invoice_type" label="普通发票" inline ></md-radio>
                            <md-radio :name="1" v-model="invoiceForm.invoice_type" label="增值专用" inline :disabled="form.totalAmount < 200"></md-radio>
                        </md-field-item>
                        <md-field-item title="抬头类型" solid>
                            <md-radio   :name="0" v-model="invoiceForm.head_type" label="个人" inline :disabled="invoiceForm.invoice_type == 1"></md-radio>
                            <md-radio  :name="1" v-model="invoiceForm.head_type" label="企业" inline ></md-radio>
                        </md-field-item>
                        <md-input-item
                            title="发票抬头"
                            placeholder="填写发票抬头"
                            v-model="invoiceForm.head"
                        ></md-input-item>

                        <template v-if="invoiceForm.head_type == 1">
                            <md-input-item
                                title="单位税号"
                                placeholder="填写单位税号"
                                v-model="invoiceForm.taxes_no"
                            ></md-input-item>
                            <template v-if="invoiceForm.invoice_type == 1">
                                <md-input-item
                                    title="注册地址"
                                    placeholder="填写注册地址"
                                    v-model="invoiceForm.reg_address"
                                ></md-input-item>
                                <md-input-item
                                    title="注册电话"
                                    placeholder="填写注册电话"
                                    v-model="invoiceForm.reg_phone"
                                ></md-input-item>
                                <md-input-item
                                    title="开户银行"
                                    placeholder="填写开户银行"
                                    v-model="invoiceForm.bank_name"
                                ></md-input-item>
                                <md-input-item
                                    title="银行账号"
                                    placeholder="填写银行账号"
                                    v-model="invoiceForm.bank_account"
                                ></md-input-item>
                            </template>
                        </template>
                    
                    </template>
                </div>
                <div class="btns">
                    <md-button type="primary" round class="md" @click="saveInvoice">保存</md-button>
                </div>
            </md-popup>

            <md-popup
            v-model="addressVisible"
            position="bottom"
            class="address-pop"
            >
                <div class="block-title">
                    <span>收件地址</span>
                    <a @click="addressVisible = false" class="close"><md-icon name="close" ></md-icon></a>
                </div>
                <div class="address-panel">
                    <md-input-item
                        title="收件人"
                        placeholder="填写收件人姓名"
                        v-model="addressForm.consignee"
                    ></md-input-item>
                    <md-input-item
                        title="联系电话"
                        placeholder="填写手机号码"
                        v-model="addressForm.mobile"
                    ></md-input-item>
                    <md-input-item
                        title="省份"
                        placeholder="填写省份"
                        v-model="addressForm.province"
                    ></md-input-item>
                    <md-input-item
                        title="城市"
                        placeholder="填写城市"
                        v-model="addressForm.city"
                    ></md-input-item>
                    <md-input-item
                        title="区域"
                        placeholder="填写区域"
                        v-model="addressForm.area"
                    ></md-input-item>
                    <md-input-item
                        title="地址"
                        placeholder="填写详细地址"
                        v-model="addressForm.address"
                    ></md-input-item>
                    <md-input-item
                        title="邮编"
                        placeholder="填写邮政编码"
                        v-model="addressForm.zipcode"
                    ></md-input-item>
                    
                </div>
                <div class="btns">
                    <md-button type="primary" round class="md" @click="saveAddress">保存</md-button>
                </div>
            </md-popup>

            <md-popup
            v-model="accountVisible"
            position="bottom"
            class="account-pop"
            >
                <div class="account">
                    <div class="block-title">
                        <span>授权信息</span>
                        <a @click="accountVisible = false" class="close"><md-icon name="close"></md-icon></a>
                    </div>
                    <md-input-item
                        preview-type="text"
                        v-model="accountForm.last_name"
                        title="名字"
                        placeholder="授权名字"
                        is-title-latent
                    >
                    <span slot="right">请输入英文字母</span>
                    </md-input-item>
                    <md-input-item
                        preview-type="text"
                        v-model="accountForm.first_name"
                        title="姓氏"
                        placeholder="授权姓氏"
                        is-title-latent
                    >
                        <span slot="right">请输入英文字母</span>
                        </md-input-item>
                    <md-input-item
                        preview-type="text"
                        v-model="accountForm.email"
                        title="邮箱"
                        placeholder="授权邮箱"
                        is-title-latent
                        ></md-input-item>

                    <div class="mt-1">
                        <md-button type="primary" round class="md" @click="saveAccount">保存</md-button>
                    </div>
                </div>
            </md-popup>
        </div>
  </div>
</template>

<script>
let self;
export default {
    name: 'checkout',
    components: {
        
    },
    data(){
        return {
            sku:'',
            count:0,
            isRead:true,
            invoiceVisible:false,
            accountVisible:false,
            order:{},
            form:{
                invoice:{},
                account:{},
                address:{},
                products:[],
                grouponParentId:0,
                total_currency:{}
            },
            accountForm:{},
            invoiceForm:{},
            addressForm:{},
            addressVisible:false,
            errorVisible:false,
            loading:false,
            groupon:{},
            error:''
        }
    },
    mounted(){
        self = this;

        document.title="确认团购";
        this.$store.dispatch('hideBottomBar');

        this.sku = this.$route.params.sku;
        this.count = this.$route.params.count;
        this.form.grouponParentId = this.$route.params.grouponParentId;
        this.prepareOrder();
    },
    methods:{
        prepareOrder(){
            this.form.sku = this.sku;
            this.form.count = this.count;
            this.$http.post('user/prepareGrouponOrder',this.form).then((res)=>{
                let form = res.data.order;
                //
                form.invoice = form.invoice || {
                        need_invoice: 0,
                        invoice_type: 0,
                        head_type:0
                    };
                if(!("need_invoice" in form.invoice)){
                    form.invoice.need_invoice = 0
                }
                this.$helper.nullToEmpty(form.invoice);
                form.account  = form.account || {};
                form.address  = form.address || {};
                
                //参团显示倒计时
                if(self.form.grouponParentId){
                    self.deadline = parseInt(res.data.groupon.expire_secs)*1000;
                }
              
                self.form = {...form};
                self.groupon = res.data.groupon;
            }).catch((res)=>{
                if(res.ret == 5000){
                    setTimeout(()=>{
                        self.$router.back()
                    },1000)
                }
            }).finally(()=>{
                
            });
        },
        setSelectedCoupons(){
            
            self.form.coupons = [];
            self.form.userCoupons.map((v)=>{
                self.form.coupons.push(v.id);
            })
            self.coupons.map((mv)=>{
                mv.selected = 0;
                self.form.userCoupons.map((v)=>{
                    if(v.id == mv.id){
                        mv.selected = 1;
                    }
                })
            })
        },
        saveOrder(){
            this.form.sku = this.sku;
            this.form.count = this.count;
            this.$http.post('user/saveGrouponOrder',this.form).then((res)=>{
                self.$helper.orderToPay(res.data.order_sn, 'groupon');
            }).catch((res)=>{
                if(res.ret == 5000){
                    setTimeout(()=>{
                        self.$router.back()
                    },1000)
                }
            }).finally(()=>{
                
            });
        },
        changeInvoiceType(){
            
            if(this.invoiceForm.invoice_type == 1){
                this.invoiceForm.head_type = 1;
            }
        },
        changeService(){
            
            let name = '';
            this.services.map((v)=>{
                if(v.id == self.form.service){
                    name = v.name;
                }
            })
            this.form = {...this.form, service_name:name};
        },
        showAccount(){
            this.accountForm = {...this.form.account};
            this.accountVisible = true;
        },
        saveAccount(){
            this.form.account = {...this.accountForm};
            this.accountVisible = false;
        },
        showInvoice(){

            this.invoiceForm = {...this.form.invoice};
            this.invoiceVisible = true;
        },
        saveInvoice(){
            this.form.invoice = {...this.invoiceForm};
            this.invoiceVisible = false;
            this.prepareOrder();
        },
        showAddress(){
            this.addressForm = {...this.form.address};
            this.addressVisible = true;
        },
        saveAddress(){
            this.form.address = {...this.addressForm};
            this.addressVisible = false;
        },
    }
}
</script>

<style>
    .coupon-item .info .md-tag .default{
        border-radius: 10px!important;
        overflow: hidden;
        padding: 0 6px!important;
    }
</style>
<style scoped lang='less'>
@import "../assets/reset.less";
.page-wrap{
    padding-bottom: 60px;
    background: rgba(0, 0, 0, 0.01);
}
.block-item{
    padding: 1rem 0.7rem;
    background: #fff;
    border-radius: 10px;
    position: relative;
    margin-bottom: 0.7rem;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.04), 0 5px 10px rgba(0, 0, 0, 0.03), 0 1px 3px rgba(0, 0, 0, 0.01);
}
.block-item.addr{
    padding: 1.2rem;
    border-radius: 0;
    margin-bottom: 0;
    background: #fff url(../assets/bg_addr.svg) repeat-x left bottom;
    background-size: 86px 5px;
    font-size: 22px;
    color: #111;
}
.block-item .more-view{
    display: block;
    position: absolute;
    right: 0.5rem;
    top: calc(50% - 1rem);
    height: 1rem;
}
.block-item .more-view .md-icon.icon-font{
    font-size: 1rem;
    vertical-align: top;
}
.block-item .title{
    font-weight: bold;
    padding-bottom: 1rem;
}

.order-item{
    display: flex;
    position: relative;
    padding: 0.7rem 0;
}
.order-item .label{
    font-size: 13px;
    flex: 1;
}
.order-item .content{
    font-size: 12px;
    flex: 1;
    text-align: right;
    padding-right: 1rem;
    font-weight: 500;
}
.amount .order-item .content{
    padding-right: 0;
    
}
.order-item .more-view{
    display: block;
    position: absolute;
    right: -0.2rem;
    top: calc(50% - 0.7rem);
    height: 1rem;
}
.order-item .more-view .md-icon.icon-font{
    font-size: 1rem;
    vertical-align: top;
}
.container{
    padding: 1rem 5px 95px 5px;
}
.order-product-item{
    display: flex;
    padding-top: 1rem;
}
.order-product-item:first-child{
    padding-top:0;
}
.order-product-item img{
    height: 50px;
    width: 50px;
}
.order-product-item .info{
    padding-left: 0.5rem;
    font-size: 12px;
    width: calc(100% - 50px);
}
.order-product-item .spec-desc{
    color: rgba(0, 0, 0, 0.4);
}
.order-product-item .price{
    overflow: hidden;
    font-size: 12px;
    display: flex;
    font-weight: 500;
}
.order-product-item .price .text-left,.order-product-item .price .text-right{
    flex: 1;
}
.order-product-combos-list{
    background: rgba(0, 0, 0, 0.01);
    margin-top: 10px;
}
.order-product-item.combos{    
    padding: 10px;
    border-radius: 4px;
}

.order-product-item.combos img{
    height: 40px;
    width: 40px;
}
.order-product-item .name{
    font-size: 14px;
}

.order-product-item.combos .info{
    padding-left: 0.5rem;
    font-size: 12px;
    width: calc(100% - 40px);
}

.sub-price{
    text-align: right;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 1rem;
}


.selected-coupon-item{
    display: inline-block;
    position: relative;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
}
.selected-coupon-item .md-icon-fail{
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    color: #fc3f53;
}


.bottom-box{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding: 1rem;
    background: #fff;
    display: flex;
    z-index: 5;

}
.bottom-box .total{
    flex: 3;
    padding-right: 1rem;
    text-align: left;
    font-size: 12px;

}
.bottom-box .total .price{
    color: #fc3f53;
    font-size: 20px;
    font-weight: bold;
    line-height: 21px;
}
.bottom-box .md-button{
    flex: 1;
}
.md-agree-icon{
    width:  1.3rem;
    height: 1rem;
    padding-top: 0.3rem;
    margin-right: 0.5rem;
}
.md-agree-content{
    font-size: 12px;
}
.md-agree-icon .md-icon.icon-font{
    font-size: 18px;
}


.md-cell-item-left{
    margin-right: 1rem;
}

.groupon-box{
    background: linear-gradient(90deg,#ff7b00,#ff585a 50%,#dd65dc);
    color: #fff;
}

.invoice-panel{
    max-height: calc(100% - 90px);
    overflow-y: auto;
    position: relative;
    padding-bottom: 8px;
}
.invoice-panel .btns{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.address-panel{
    max-height: calc(100% - 90px);
    overflow-y: auto;
    position: relative;
    padding-bottom: 8px;
}
.address-panel .btns{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}


</style>
