<template>
  <div class="page-wrap">
    <md-result-page type="lost" text="oops!页面出错了" :subtext="msg">
    </md-result-page>
  </div>
</template>

<script>
export default {
    name: "error",
    components: {},
    data() {
        return {
            msg:'',
            isRead: true,
            accountVisible: false,
        };
    },
    mounted(){
        document.title = '发生错误了';
        this.msg = this.$route.params.msg;
    }
};
</script>

<style scoped lang='less' >
.page-wrap{
  background: #fff;
}
</style>
