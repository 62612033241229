<template>
    <div class="page-wrap article-page">
        <div class="title">{{info.title}}</div>
        <div class="date">{{info.created_at}}</div>
        <div class="content" v-html="info.content"></div>
    </div>
</template>

<script>
let self;
export default {
    name: "article",
    components: {},
    data() {
        return {
            info:{},
            id:0
        };
    },
    mounted(){
        self = this;
        this.id = this.$route.params.id;
        
        this.$store.dispatch('hideBottomBar');
        this.getArticle();
    },
    beforeDestroy(){
        
    },
    methods:{
        getArticle(){
            this.$http.get('getArticle',{params:{slug:this.id}}).then((res)=>{
                self.info = res.data;
                document.title= self.info.title;
            });
        }
    }
};
</script>
<style>
.article-page .content img{
    max-width: 100%;
}
</style>
<style scoped lang='less' >
.page-wrap{
    padding: 0.7rem;
}
.title{
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 1rem;
}
.date{
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 2rem;
}

</style>
