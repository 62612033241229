<template>
    <div class="page-wrap">
        <div class="top-box">
            <div class="search-box">
                <div class="back-icon" @click="$router.back()">
                    <md-icon name="arrow-left" size="lg"></md-icon>
                </div>
                <div class="search-input">
                    <md-icon name="search" class="search-icon"></md-icon>
                    <input type="search" placeholder="请输入商品名称" v-model="name">
                </div>
                <a class="search-btn" @click="search">搜索</a>
            </div>
            <div class="cates-box">
                <a class="item" :class="{active:'all' == status}" @click="selectStatus('all')">全部</a>
                <a class="item" :class="{active:item.id == status}" v-for="item in statuses" :key="item.id"  @click="selectStatus(item.id)">{{item.name}}</a>
            </div>
        </div>
        
        
        <div class="emtpy" v-if="orders.total <= 0">
            <p><svg t="1631180042906" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16472" width="64" height="64"><path d="M855.6 427.2H168.5c-12.7 0-24.4 6.9-30.6 18L4.4 684.7C1.5 689.9 0 695.8 0 701.8v287.1c0 19.4 15.7 35.1 35.1 35.1H989c19.4 0 35.1-15.7 35.1-35.1V701.8c0-6-1.5-11.8-4.4-17.1L886.2 445.2c-6.2-11.1-17.9-18-30.6-18zM673.4 695.6c-16.5 0-30.8 11.5-34.3 27.7-12.7 58.5-64.8 102.3-127.2 102.3s-114.5-43.8-127.2-102.3c-3.5-16.1-17.8-27.7-34.3-27.7H119c-26.4 0-43.3-28-31.1-51.4l81.7-155.8c6.1-11.6 18-18.8 31.1-18.8h622.4c13 0 25 7.2 31.1 18.8l81.7 155.8c12.2 23.4-4.7 51.4-31.1 51.4H673.4zM819.9 209.5c-1-1.8-2.1-3.7-3.2-5.5-9.8-16.6-31.1-22.2-47.8-12.6L648.5 261c-17 9.8-22.7 31.6-12.6 48.4 0.9 1.4 1.7 2.9 2.5 4.4 9.5 17 31.2 22.8 48 13L807 257.3c16.7-9.7 22.4-31 12.9-47.8zM375.4 261.1L255 191.6c-16.7-9.6-38-4-47.8 12.6-1.1 1.8-2.1 3.6-3.2 5.5-9.5 16.8-3.8 38.1 12.9 47.8L337.3 327c16.9 9.7 38.6 4 48-13.1 0.8-1.5 1.7-2.9 2.5-4.4 10.2-16.8 4.5-38.6-12.4-48.4zM512 239.3h2.5c19.5 0.3 35.5-15.5 35.5-35.1v-139c0-19.3-15.6-34.9-34.8-35.1h-6.4C489.6 30.3 474 46 474 65.2v139c0 19.5 15.9 35.4 35.5 35.1h2.5z" p-id="16473" fill="#bfbfbf"></path></svg></p>
            <p>暂无拍卖记录</p>
        </div>
        <div class="order-list" v-else>
            <div class="order-item" v-for="item in orders.data" :key="item.id">
                <div class="desc fixed">
                    <span class="pull-left">
                        第{{item.issue.issue}}期
                    </span>
                    <span class="pull-right status">
                        <md-tag font-color="green" shape="circle" v-if="item.status == 0">进行中</md-tag>
                        <md-tag font-color="blue" shape="circle" v-if="item.status == 1 && item.hit_status == 0">未中拍</md-tag>
                        <md-tag font-color="red" shape="circle" v-if="item.hit_status == 1  && item.status != 3">已中拍</md-tag>
                        <md-tag font-color="grey" shape="circle" v-if="item.status == 3">已放弃</md-tag>
                    </span>
                </div>
                <div class="imgs" @click="$helper.goPage('/auction/'+item.auction_id)">
                    <img :src="$store.state.assetUrl + item.img" alt="" >
                    <div class="name text-ellipsis-2">
                        {{item.name}}
                        <div class="spec">{{item.spec_desc}}</div>
                        <div class="small">出价：<span class="small">￥</span><span class="price">{{item.total_amount}}</span> </div>
                    </div>
                    
                </div>
                <div class="bottom" v-if="item.hit_status == 1 && item.status != 3">
                    <div class="btns" >
                        <md-button size="small" type="primary" round  @click="$helper.orderToPay(item.order.order_sn)" v-if="item.status == 1">去支付</md-button>
                        <md-button size="small" type="default" round  @click="$helper.goPage('/order/'+item.order.order_sn)">订单详情</md-button>
                    </div>
                </div>  
            </div>
            
            <div class="load-more" v-if="isLoading">正在努力加载中...</div>
            <div class="load-more" v-if="isNoMore">全部加载完了</div>
        </div>

        
    </div>
</template>

<script>

let self;
export default {
    name: 'search',
    components: {
        
    },
    data(){
        return {
            page:1,
            orders:{
                total: 0,
                data:[]
            },
            statuses:[
                {id:'doing', name:'进行中'},
                {id:'unhit', name:'未中拍'},
                {id:'hited', name:'已中拍'},
            ],
            status: 'all',
            name: '',
            isLoading: false,
            isNoMore: false,
        }
    },
    mounted(){
        self = this;

        document.title="我的拍卖";
        this.$store.dispatch('hideBottomBar');
        
        this.status = this.$route.params.status || 'all';
        this.page = 1;
        this.getOrders();

        window.onscroll = ()=>{
            if(self.$route.path.indexOf('/myAuctions') != -1){
                self.endReached();
            }
        }
    },
    methods:{
        search(){
            this.page = 1;
            this.getOrders();
        },
        getOrders(){
            var param = {
                page : this.page,
                status : this.status,
                name: this.name
            };
            this.$http.get('user/listAuctions',{params:param}).then((res)=>{
                if(param.page == 1){
                    self.orders = res.data;
                }else if(res.data.data.length > 0){
                    self.orders.data = self.orders.data.concat(res.data.data);
                }
               
            }).finally(()=>{
                self.isLoading = false;
            });
        },
        endReached(){
            
            let top = document.documentElement.scrollTop || document.body.scrollTop;
            
            //触底
            if(top + window.innerHeight  > document.getElementsByClassName('page-wrap')[0].offsetHeight - 90){
                if(this.isLoading){
                    return;
                }
                if(this.page >= this.orders.last_page ){
                    self.isNoMore = true;
                    return;
                }
                this.page++;
                this.isLoading = true;
                this.getOrders();
            }
        },
        selectStatus(id){
            this.status = id;
            this.page = 1;
            this.getOrders();
        }
    }
}
</script>

<style scoped lang='less'>
.emtpy{
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    padding: 3rem 0;
}
.page-wrap{
    background: rgba(0, 0, 0, 0.01);
    padding-bottom: 0;
    padding-top: 111px;
}
.top-box{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
}
.search-box,.cates-box{
    background: #fff;
    padding: 0.7rem;
    display: flex;
    font-size: 13px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    position: relative;
}
.search-box .back-icon{
    width: 30px;
    text-align: left;
    height: 34px;
    line-height: 38px;
}
.search-box .search-input{
    position: relative;
    width: calc(100% - 70px);
}
.search-box .search-icon{
    position: absolute;
    left: 0.5rem;
    top: 0.6rem;
}
.search-box input{
    -webkit-appearance: none;
    -webkit-box-shadow: none;
    outline: none;
    width: 100%;
    background: rgba(0, 0, 0, 0.03);
    height: 34px;
    line-height: 34px;
    padding-left: 30px;
    border-radius: 25px;
    border: 1px solid rgba(37, 198, 252, 0);
    transition: all 0.2s;
}
.search-box input:focus{
    border: 1px solid rgba(37, 198, 252, 0.8);
    background: #fff;
}
.search-box .search-btn{
    width: 40px;
    display: inline-block;
    text-align: right;
     line-height: 34px;
}
.cates-box{
    white-space: nowrap;
    overflow-x: auto;
}
.cates-box .item{
    display: inline-block;
    padding: 4px 10px;
    border-radius: 20px;
    color: rgba(0, 0, 0, 1 );
    background: rgba(0, 0, 0, 0.03);
    border: 1px solid rgba(0, 0, 0, 0);
    margin-right: 8px;
}
.cates-box .item.active{
    background: rgba(37, 198, 252, 0.1);
    color: rgba(37, 198, 252, 1);
    border: 1px solid rgba(37, 198, 252, 1);
}
.order-list{
    padding: 0.5rem 0.7rem;
}
.order-item{
    padding: 1rem 0.7rem;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.03), 0 5px 10px rgba(0, 0, 0, 0.02), 0 1px 3px rgba(0, 0, 0, 0.01);
    border-radius:10px;
    background: #fff;
    margin-bottom: 0.5rem;
}
.order-item .desc{
    padding-bottom: 0.7rem;
    font-weight: 500;
    color: #111;
}
.order-item .imgs{
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
}
.order-item .imgs img{
    vertical-align: middle;
    height: 60px;
    width: 60px;
    padding: 4px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.03);
    margin-right: 10px;
}
.order-item .imgs .name{
    display: inline-block;
    width: calc(100% - 80px);
    height: 60px;
    padding-top: 4px;
    vertical-align: middle;
    vertical-align: middle;
    white-space: normal;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.7);
}
.order-item .bottom{
    padding-top: 0.5rem;
    font-size: 13px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    margin-top: 0.5rem;
}
.order-item .spec{
    font-size: 12px;
}

.order-item .bottom .btns{
    text-align: right;
}
.order-item .bottom .btns .md-button{
    width: auto;
    height: 26px;
    line-height: 26px;
    display: inline-block;
}
.order-item .bottom .btns .md-button+.md-button{
    margin-left: 10px;
}


.groupon-item{

}
.groupon-item .avatars{
    margin-bottom: 0.5rem;
}

.groupon-item .countdown-box{
  font-size: 12px;
}

.groupon-item .info{
  padding: 0;
  font-size: 12px;
  color: #111;
}
.groupon-item .avatars span{
    display: inline-block;
    background: rgba(0,0,0,0.06);
    border-radius: 50%;
    height: 35px;
    width: 35px;
    vertical-align: middle;
    line-height: 35px;
    margin-right: 3px;
    text-align: center;
}
.groupon-item .avatars span img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: block;
    border: 1px solid rgba(0,0,0,0.06);
}
.groupon-item .avatars span .wait{
    display: inline-block;
    vertical-align: middle;
}
.groupon-item .desc{
    font-size: 12px;
    color: rgba(0,0,0,0.7);
}
.groupon-item .spec{
    color: rgba(0,0,0,0.7);
    font-size: 12px;
    margin-top: 0.2rem;
    margin-bottom: 0.4rem;
}

</style>
