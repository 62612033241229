<template>
    <div class="block-item">
        <div class="title" @click="showComments">
            <span
            >评价
            <span style="font-weight: normal; font-size: 12px" >{{ comments.total }}条</span></span>
            <span class="more-view" >
                <span>好评{{rateInfo.good_per}}%</span>
                <md-icon name="arrow-right" size="small"></md-icon>
            </span>
        </div>
        <div class="text-center" v-if="comments.total <= 0">暂无评价</div>
        <div v-else>
            <div class="comment-item single" v-if="comments.total > 0">
                <div class="fixed">
                <div class="pull-left avatar">
                    <img :src="$store.state.assetUrl + comments.data[0].user.avatar" alt="" /> {{comments.data[0].user.nickname}}
                </div>
                <div class="pull-right date">
                        <span v-for="item in comments.data[0].rate" :key="item">
                            <svg viewBox="64 64 896 896" data-icon="star" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z"></path></svg>
                        </span>
                </div>
                </div>
                <div class="content text-ellipsis-2">
                {{comments.data[0].content}}
                </div>
                <div class="sku-desc fixed">
                    <div class="pull-left">{{comments.data[0].spec_desc}}</div>
                    <div class="pull-right">{{comments.data[0].created_at}}</div>
                </div>
            </div>

            <div class="text-center">
                <md-button plain round class="md" @click="showComments">查看更多评价</md-button>
            </div>
        </div>
        <md-popup v-model="commentsVisible" position="bottom" class="comments-pop">
            <div class="block-title">
                <span>评价列表</span>
                <a @click="commentsVisible = false" class="close"><md-icon name="close"></md-icon></a>
            </div>
            
            <div class="comments-panel" @scroll="commentEndReached">
                <div class="rate-stat-box">
                    <div class="title fixed">
                        <span class="pull-left">共{{comments.total}}条评价</span>
                        <span class="pull-right">好评率{{rateInfo.good_per}}%</span>
                    </div>
                    <div class="rate-per">
                        <md-tag shape="circle" type="ghost" font-color="rgba(249, 96, 98, 1)">好评 {{rateInfo.rete5_count + rateInfo.rete4_count}}</md-tag>
                        <md-tag shape="circle" type="ghost" font-color="rgba(249, 96, 98, 0.8)">中评 {{rateInfo.rete3_count}}</md-tag>
                        <md-tag shape="circle" type="ghost" font-color="rgba(249, 96, 98, 0.6)">差评 {{rateInfo.rete1_count + rateInfo.rete2_count}}</md-tag>
                    </div>
                </div>
                <div class="comment-item" v-for="item in comments.data" :key="item.id">
                    <div class="fixed">
                    <div class="pull-left avatar">
                        <img :src="$store.state.assetUrl + item.user.avatar" alt="" /> {{item.user.nickname}}
                    </div>
                    <div class="pull-right date">
                            <span v-for="item in item.rate" :key="item">
                                <svg viewBox="64 64 896 896" data-icon="star" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z"></path></svg>
                            </span>
                    </div>
                    </div>
                    <div class="content">
                    {{item.content}}
                    </div>
                    <div class="sku-desc fixed">
                        <div class="pull-left">{{item.spec_desc}}</div>
                        <div class="pull-right">{{item.created_at}}</div>
                    </div>
                </div>
            <div class="load-more" v-if="isLoading">正在努力加载中...</div>
            <div class="load-more" v-if="isNoMore">全部加载完了</div>
        </div>
        </md-popup>
    </div>
</template>

<script>
let self;
export default {
    name: "comments",
    components: {},
    props:{
        pid:{
            type: Number,
            default:0
        }
    },
    data() {
        return {
           comments:{},
           isNoMore:false,
           isLoading:false,
           page:1,
           commentsVisible:false,
           productId:0,
           rateInfo:{}
        };
    },
    watch:{
        pid(val){
            this.productId = val
            this.init();
        }
    },
    mounted(){
        self = this;
        this.productId = this.pid
        this.init();
    },
    beforeDestroy(){
        
    },
    methods:{
        init(){
            if(this.productId){
                this.getComments()
                this.getRateInfo()
            }
        },
        showComments(){
            if(this.comments.total > 0){
                this.commentsVisible = true;
            }
        },
        getRateInfo(){
            this.$http.get("getRateInfo", { params: {product_id:this.productId}, showLoading:false }).then((res) => {
                let rateInfo = res.data;
                rateInfo.good_per = parseInt(rateInfo.rete5_per) + parseInt(rateInfo.rete4_per);
                self.rateInfo = rateInfo;
            })
            
        },
        //获取评论
        getComments() {
            var params = {
                page: this.page,
                id: this.pid,
            };
            this.$http.get("listProductComments", { params: params, showLoading:false }).then((res) => {
                if (self.page == 1) {
                    self.comments = res.data;
                } else if(res.data.data.length){
                    self.comments.data = self.comments.data.concat(res.data.data);
                }
            }).finally(()=>{
                
                self.isLoading = false;
            });
        },
        commentEndReached(e){
            let top = e.target.scrollTop;
            //触底
            if(top + e.target.offsetHeight  > e.target.scrollHeight - 50){
                if(this.isLoading){
                    return;
                }
                if(this.page >= this.comments.last_page ){
                    self.isNoMore = true;
                    return;
                }
                this.page++;
                this.isLoading = true;
                this.getComments();
            }
        },
    }
};
</script>

<style scoped lang='less' >
@import "../../assets/reset.less";
.block-item {
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.04), 0 5px 10px rgba(0, 0, 0, 0.02);
  margin: 1rem 0.7rem;
  padding: 1rem 0.7rem;
  background: #fff;
}
.block-item .title {
  position: relative;
  margin-bottom: 1rem;
}
.block-item .title::before {
  content: "";
  display: block;
  position: absolute;
  height: 10px;
  width: 3px;
  background: @primary-color;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.01);
  left: -6px;
  top: 5px;
  border-radius: 2px;
}
.block-item .title > span:first-child {
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 15px;
}
.block-item .title .more-view {
  position: absolute;
  right: -4px;
  top: 0;
  font-size: 12px;
  color: #999;
  display: flex;
  line-height: 20px;
}
.block-item .title .more-view .md-icon {
  font-size: 14px;
  line-height: 20px;
}

.comments-panel {
  max-height: calc(100% - 40px);
  overflow-y: auto;
  overflow-x: hidden;
  margin:0 -1rem;
  padding: 0 1rem;
}
.comment-item {
  margin-bottom: 1rem;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05), 0 5px 10px rgba(0, 0, 0, 0.02), 0 1px 3px rgba(0, 0, 0, 0.01);
  border-radius:10px;
  padding:1rem 0.7rem
}
.comment-item.single{
  box-shadow:none;
  padding:0
}
.comment-item:last-child, .comment-item.single{
    border-bottom: none;
}
.comment-item .avatar {
  font-size: 12px;
}
.comment-item .avatar img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  vertical-align: middle;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.03);
  margin-right: 0.5rem;
}
.comment-item .date {
  font-size: 12px;
  color: @primary-color;
  line-height: 40px;
}
.comment-item .content {
  font-size: 13px;
  margin: 0.3rem 0;
  font-weight:500;
}
.comment-item .sku-desc {
  font-size: 12px;
  color: #999;
}


.rate-stat-box{
    padding-bottom: 1rem;
}
.rate-stat-box .title{
    margin-bottom: 1rem;
}
.rate-stat-box .rate-per{
    margin-right: -16px;
}
.rate-stat-box .rate-per .md-tag{
    margin:5px 10px 5px 0
}
</style>
