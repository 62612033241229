<template>
    <div class="page-wrap">
        <div class="block-item addr" @click="showAddress" v-if="form.product_type == 1">
            <template v-if="form.address.consignee">
                <div>{{form.address.consignee}} {{form.address.mobile}}</div>
                <div class="color-gray small">{{form.address.province}} {{form.address.city}} {{form.address.area}} {{form.address.address}}</div>
            </template>
            <template v-else>
                <div class="small color-gray">填写收货信息</div>
            </template>
            <a class="more-view"><md-icon name="arrow-right"></md-icon></a>
        </div>
        <div class="block-item addr" @click="showAccount" v-if="form.product_type == 0">
            <template v-if="form.account.first_name">
                <div>{{form.account.first_name}} {{form.account.last_name}}</div>
                <div class="color-gray small">{{form.account.email}}</div>
            </template>
            <template v-else>
                <div class="small color-gray">填写授权信息</div>
            </template>
            <a class="more-view"><md-icon name="arrow-right"></md-icon></a>
        </div>
        <div class="container">
            <div class="block-item">
                <div class="title"><md-icon name="order"></md-icon> 订单详情</div>
                <div class="order-product-list">
                <template v-for="item in form.products" >
                    <div class="order-product-item" :key="item.id">
                        <img :src="$store.state.assetUrl + item.product.img" alt="">
                        <div class="info">
                            <div class="name text-ellipsis">{{item.product.name}}</div>
                            <div class="spec-desc">{{item.spec_desc}}</div>
                            
                        </div>   
                    </div>
                </template>
                </div>
            </div>
            <div class="block-item auction-box">
                <div>拍卖信息</div>
                <div>1、每个人出价次数没有限制，中拍后请在3天内完成支付，逾期未支付视为放弃本次拍卖。</div>
                <div>2、竞拍结束后释放保证金，可在个人中心自助申请退款。</div>
                <div>3、请注意！中拍后没有完成支付，押金将不会退还。</div>
            </div>

            <div>
                <md-agree v-model="isRead" >
                    <span class="small color-gray">
                    我已阅读并同意 <a @click="$router.push('/article/service')">《服务条款》</a>和<a @click="$router.push('/article/privacy')">《隐私政策》</a>
                    </span>
                </md-agree>
            </div>
            
            <div class="bottom-box">
                <div class="total">
                    <div class="price"><small>￥</small>{{form.totalAmount}}</div>
                    <div>竞拍出价</div>
                </div>
                <md-button type="primary" class="md" round @click="saveOrder" :disabled="loading || !isRead">提交订单</md-button>
            </div>

            

            <md-popup
            v-model="accountVisible"
            position="bottom"
            class="account-pop"
            >
                <div class="account">
                    <div class="block-title">
                        <span>授权信息</span>
                        <a @click="accountVisible = false" class="close"><md-icon name="close"></md-icon></a>
                    </div>
                    <md-input-item
                        preview-type="text"
                        v-model="accountForm.last_name"
                        title="名字"
                        placeholder="授权名字"
                        is-title-latent
                    >
                    <span slot="right">请输入英文字母</span>
                    </md-input-item>
                    <md-input-item
                        preview-type="text"
                        v-model="accountForm.first_name"
                        title="姓氏"
                        placeholder="授权姓氏"
                        is-title-latent
                    >
                        <span slot="right">请输入英文字母</span>
                        </md-input-item>
                    <md-input-item
                        preview-type="text"
                        v-model="accountForm.email"
                        title="邮箱"
                        placeholder="授权邮箱"
                        is-title-latent
                        ></md-input-item>

                    <div class="mt-1">
                        <md-button type="primary" round class="md" @click="saveAccount">保存</md-button>
                    </div>
                </div>
            </md-popup>

            <md-popup
            v-model="addressVisible"
            position="bottom"
            class="address-pop"
            >
                <div class="block-title">
                    <span>收件地址</span>
                    <a @click="addressVisible = false" class="close"><md-icon name="close" ></md-icon></a>
                </div>
                <div class="address-panel">
                    <md-input-item
                        title="收件人"
                        placeholder="填写收件人姓名"
                        v-model="addressForm.consignee"
                    ></md-input-item>
                    <md-input-item
                        title="联系电话"
                        placeholder="填写手机号码"
                        v-model="addressForm.mobile"
                    ></md-input-item>
                    <md-input-item
                        title="省份"
                        placeholder="填写省份"
                        v-model="addressForm.province"
                    ></md-input-item>
                    <md-input-item
                        title="城市"
                        placeholder="填写城市"
                        v-model="addressForm.city"
                    ></md-input-item>
                    <md-input-item
                        title="区域"
                        placeholder="填写区域"
                        v-model="addressForm.area"
                    ></md-input-item>
                    <md-input-item
                        title="地址"
                        placeholder="填写详细地址"
                        v-model="addressForm.address"
                    ></md-input-item>
                    <md-input-item
                        title="邮编"
                        placeholder="填写邮政编码"
                        v-model="addressForm.zipcode"
                    ></md-input-item>
                    
                </div>
                <div class="btns">
                    <md-button type="primary" round class="md" @click="saveAddress">保存</md-button>
                </div>
            </md-popup>
        </div>
  </div>
</template>

<script>
let self;
export default {
    name: 'checkout',
    components: {
        
    },
    data(){
        return {
            id:0,
            issue:0,
            isRead:true,
            invoiceVisible:false,
            accountVisible:false,
            order:{},
            form:{
                account:{},
                id:0,
                issue:0,
                auctionPrice:0
            },
            accountForm:{},
            invoiceForm:{},
            addressForm:{},
            addressVisible:false,
            errorVisible:false,
            loading:false,
            groupon:{},
            error:''
        }
    },
    mounted(){
        self = this;

        document.title="确认拍卖";
        this.$store.dispatch('hideBottomBar');
        this.id = this.$route.params.id;
        this.issue = this.$route.params.issue;
        this.form.auctionPrice = this.$route.params.price;
        this.prepareOrder();
    },
    methods:{
        prepareOrder(){
            this.form.id = this.id;
            this.form.issue = this.issue;
            this.$http.post('user/prepareAuctionOrder',this.form).then((res)=>{
                let form = res.data.order;
                form.account = form.account || {};
                self.form = {...form};
                self.auction = res.data.auction;
            }).catch((res)=>{
                if(res.ret == 5000){
                    setTimeout(()=>{
                        self.$router.back()
                    },1000)
                }
            }).finally(()=>{
                
            });
        },
        saveOrder(){
            this.form.id = this.id;
            this.form.issue = this.issue;
            this.$http.post('user/saveAuctionOrder',this.form).then(()=>{
                self.$helper.goPage('/my-auctions', true, true);
            }).catch((res)=>{
                if(res.ret == 5000){
                    setTimeout(()=>{
                        self.$router.back()
                    },1000)
                }
            }).finally(()=>{
                
            });
        },
        
        showAccount(){
            this.accountForm = {...this.form.account};
            this.accountVisible = true;
        },
        saveAccount(){
            this.form.account = {...this.accountForm};
            this.accountVisible = false;
        },
         showAddress(){
            this.addressForm = {...this.form.address};
            this.addressVisible = true;
        },
        saveAddress(){
            this.form.address = {...this.addressForm};
            this.addressVisible = false;
        },
    }
}
</script>
<style scoped lang='less'>
@import "../assets/reset.less";
.page-wrap{
    padding-bottom: 60px;
    background: rgba(0, 0, 0, 0.01);
}
.block-item{
    padding: 1rem 0.7rem;
    background: #fff;
    border-radius: 10px;
    position: relative;
    margin-bottom: 0.7rem;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.04), 0 5px 10px rgba(0, 0, 0, 0.03), 0 1px 3px rgba(0, 0, 0, 0.01);
}
.block-item.addr{
    padding: 1.2rem;
    border-radius: 0;
    margin-bottom: 0;
    background: #fff url(../assets/bg_addr.svg) repeat-x left bottom;
    background-size: 86px 5px;
    font-size: 22px;
    color: #111;
}
.block-item .more-view{
    display: block;
    position: absolute;
    right: 0.5rem;
    top: calc(50% - 1rem);
    height: 1rem;
}
.block-item .more-view .md-icon.icon-font{
    font-size: 1rem;
    vertical-align: top;
}
.block-item .title{
    font-weight: bold;
    padding-bottom: 1rem;
}

.order-item{
    display: flex;
    position: relative;
    padding: 0.7rem 0;
}
.order-item .label{
    font-size: 13px;
    flex: 1;
}
.order-item .content{
    font-size: 12px;
    flex: 1;
    text-align: right;
    padding-right: 1rem;
    font-weight: 500;
}
.amount .order-item .content{
    padding-right: 0;
    
}
.order-item .more-view{
    display: block;
    position: absolute;
    right: -0.2rem;
    top: calc(50% - 0.7rem);
    height: 1rem;
}
.order-item .more-view .md-icon.icon-font{
    font-size: 1rem;
    vertical-align: top;
}
.container{
    padding: 1rem 5px 95px 5px;
}
.order-product-item{
    display: flex;
    padding-top: 1rem;
}
.order-product-item:first-child{
    padding-top:0;
}
.order-product-item img{
    height: 50px;
    width: 50px;
}
.order-product-item .info{
    padding-left: 0.5rem;
    font-size: 12px;
    width: calc(100% - 50px);
}
.order-product-item .spec-desc{
    color: rgba(0, 0, 0, 0.4);
}
.order-product-item .price{
    overflow: hidden;
    font-size: 12px;
    display: flex;
    font-weight: 500;
}
.order-product-item .price .text-left,.order-product-item .price .text-right{
    flex: 1;
}
.order-product-combos-list{
    background: rgba(0, 0, 0, 0.01);
    margin-top: 10px;
}
.order-product-item.combos{    
    padding: 10px;
    border-radius: 4px;
}

.order-product-item.combos img{
    height: 40px;
    width: 40px;
}
.order-product-item .name{
    font-size: 14px;
}

.order-product-item.combos .info{
    padding-left: 0.5rem;
    font-size: 12px;
    width: calc(100% - 40px);
}

.sub-price{
    text-align: right;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 1rem;
}


.selected-coupon-item{
    display: inline-block;
    position: relative;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
}
.selected-coupon-item .md-icon-fail{
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    color: #fc3f53;
}


.bottom-box{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding: 1rem;
    background: #fff;
    display: flex;
    z-index: 5;

}
.bottom-box .total{
    flex: 3;
    padding-right: 1rem;
    text-align: left;
    font-size: 12px;

}
.bottom-box .total .price{
    color: #fc3f53;
    font-size: 20px;
    font-weight: bold;
    line-height: 21px;
}
.bottom-box .md-button{
    flex: 1;
}
.md-agree-icon{
    width:  1.3rem;
    height: 1rem;
    padding-top: 0.3rem;
    margin-right: 0.5rem;
}
.md-agree-content{
    font-size: 12px;
}
.md-agree-icon .md-icon.icon-font{
    font-size: 18px;
}


.md-cell-item-left{
    margin-right: 1rem;
}

.auction-box{
    background: linear-gradient(90deg,#ff7b00,#ff585a 50%,#dd65dc);
    color: #fff;
    font-size: 13px;
}

.invoice-panel{
    max-height: calc(100% - 90px);
    overflow-y: auto;
    position: relative;
    padding-bottom: 8px;
}
.invoice-panel .btns{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.address-panel{
    max-height: calc(100% - 90px);
    overflow-y: auto;
    position: relative;
    padding-bottom: 8px;
}
.address-panel .btns{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}


</style>
