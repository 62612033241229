import axios from 'axios';
import app from '../main'
 // axios.defaults.withCredentials=true

// 创建 axios 实例
const http = axios.create({
    baseURL: process.env.VUE_APP_API_HOST + '/api/', 
    timeout: 5000 // 请求超时时间
})

// 错误处理函数
const err = (error) => {
    if (error.response) {
        const data = error.response.data
        app.$toast.failed(data.msg || '网络异常，请重试');
    }
    return Promise.reject(error)
}

// request interceptor(请求拦截器)
http.interceptors.request.use(config => {
    let showLoading = "showLoading" in config ? config.showLoading : true
    if(showLoading){
        app.$store.commit('showLoading');
    }
    const token = app.$store.state.token;

    config.headers['auth-token'] = token || '';
    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    config.headers['currency'] = app.$helper.getStorage("currency") || "";
    config.headers['buy-cookie'] = app.$helper.getCookie("buy-cookie");

    
    return config
}, err)

// response interceptor（接收拦截器）
http.interceptors.response.use((response) => {
    app.$store.commit('hideLoading');
    const res = response.data
    if (res.ret !== 0) { 
        if (res.ret === 403) {
            // 401:未登录;
            app.$store.dispatch('setUserInfo',false);
            app.$helper.goPage('/login', false, true);
        }else if(res.ret == 302){
            window.location.href = res.data.url;
        }else{
            app.$toast.failed(res.msg);
        }
        return Promise.reject(res)
    } else {
        return res
    }
}, err)

export default http;