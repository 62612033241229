<template>
    <div class="page-wrap">
        <div class="ad-item" v-if="ad.id > 0">
            <a :href="ad.url"><img :src="$store.state.assetUrl + ad.img" alt="" /></a>
        </div>
        <div class="groupon-item" v-for="item in groupons" :key="item.id" @click="$helper.goPage('/item/'+item.product.slug)"
         :style="{background: 'linear-gradient(to right, '+item.bg1+', '+item.bg2+')', opacity: item.visible?1:0}" >

            <div class="title-box">
                <div class="text-ellipsis name">{{item.product.name}}</div>
                <div class="count">
                    <md-tag shape="circle" type="fill" :fill-color="item.bg3" font-color="#fff">{{item.need_count}}人成团</md-tag>
                </div>
            </div>
            <div class="img">
                <div class="price-box">
                    <div class="value"><span class="small">{{item.currency.code}}</span><span class="price">{{item.currency.amount}}</span></div>
                    <div class="label">团购价</div>
                </div>
                <div class="img-box">
                    <img :src="$store.state.assetUrl + item.product.img" alt="">
                </div>
            </div>
            <div class="info">
                <div class="desc">已有{{item.count}}人参与</div>
                <div class="text-right">马上去抢 <md-icon name="arrow-right"></md-icon></div>
            </div>
        </div>
        <div class="load-more" v-if="isLoading">正在努力加载中...</div>
        <div class="load-more" v-if="isNoMore">全部加载完了</div>
    </div>
</template>

<script>
let self;
export default {
    name: "groupons",
    components: {},
    data() {
        return {
           page:1,
           groupons:[],
           isLoading:false,
           isNoMore:false,
           ad:{}
        };
    },
    mounted(){
        self = this;
        document.title = '团购中心';
        this.$store.dispatch('showBottomBar',2);
        this.getGroupons();
        this.getAd();
    },
    beforeDestroy(){
        
    },
    methods:{
        getAd(){
            this.$http.get('getAds',{params:{ad:"wap_groupon", size:1}, showLoading:false}).then((res)=>{
                self.ad = res.data || {};
            });
        },
        getGroupons(){
            var param = {
                page : this.page
            };
            this.$http.get('listGroupons',{params:param}).then((res)=>{
                let groupons = res.data || [];

                groupons.map((v)=>{
                    v.bg1 = "rgba(255,255,255,1)"
                    v.bg2 = "rgba(255,255,255,1)"
                    v.bg3 = `rgba(255,255,255,1)`
                    v.visible = false
                    self.$helper.getRGBFromImg(self.$store.state.assetUrl + v.product.img +"?x-oss-process=image/resize,h_100,m_lfit", (color)=>{
                        v.bg1 = `rgba(${[...color]}, 0.07)`
                        v.bg2 = `rgba(${[...color]}, 0.15)`
                        v.bg3 = `rgba(${[...color]}, 0.9)`
                        v.visible = true
                    })
                });

                self.groupons = [...groupons]
               
            }).finally(()=>{
                self.isLoading = false;
            });
        },
        endReached(){
            let top = document.documentElement.scrollTop || document.body.scrollTop;
            //触底
            if(top + window.innerHeight  > document.getElementsByClassName('page-wrap')[0].offsetHeight - 90){
                if(this.isLoading){
                    return;
                }
                if(this.page >= this.groupons.last_page ){
                    self.isNoMore = true;
                    return;
                }
                this.page++;
                this.isLoading = true;
                this.getGroupons();
            }
        },
    }
};
</script>

<style scoped lang='less' >
@import "../assets/reset.less";
.ad-item{
    padding: 0;
}
.ad-item img{
    border-radius: 0;
}
.page-wrap{
    background: rgba(0, 0, 0, 0.01);
    padding-bottom: 70px;
}
.groupon-item{
    background: linear-gradient(to right, #fff, #fff);
    border-left: 0;
    margin: 1rem 0.7rem;
    border-radius: 10px;
    
    position: relative;
    padding: 0.7rem 1rem;
    transition: all 0.2s;
    opacity: 0;
}
.groupon-item .title-box{
    padding-left: 2px;
}
.groupon-item .name{
    color:#000;
    font-weight: 500;
    margin-bottom: 5px;
}

.groupon-item .info{
    padding-left: 2px;
    display: flex;
    font-size: 12px;
    font-weight: 500;
}
.groupon-item .info>div{
    flex: 1;
}
.groupon-item .img {
    padding: 6px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    margin: 1rem 0 0.7rem 0;
    display: flex;
}
.groupon-item .img>div{
    flex: 1;
}
.groupon-item .price-box{
    text-align: center;
    padding-top: 7px;
}
.groupon-item .price-box .label{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
}
.groupon-item .price-box .small{
    font-weight: normal;
}
.groupon-item .price-box .value{
    font-size: 22px;
    font-weight: bold;
}
.groupon-item .img-box{
    text-align: right;
    padding-right: 20px;
}
.groupon-item .img img{
    height: 70px;
    width: 70px;
    vertical-align: middle;
}

</style>
