<template>
  <div class="page-wrap">
        <div class="member-bg">
            <div class='vip-card'>
                <div class="level"><strong>VIP</strong> {{$store.state.userInfo.level}}</div>
                <div><span class="total-points">累计积分 {{$store.state.userInfo.total_points}}</span></div>
                <span class="icon">
                <svg viewBox="64 64 896 896" data-icon="crown" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M899.6 276.5L705 396.4 518.4 147.5a8.06 8.06 0 0 0-12.9 0L319 396.4 124.3 276.5c-5.7-3.5-13.1 1.2-12.2 7.9L188.5 865c1.1 7.9 7.9 14 16 14h615.1c8 0 14.9-6 15.9-14l76.4-580.6c.8-6.7-6.5-11.4-12.3-7.9zM512 734.2c-62.1 0-112.6-50.5-112.6-112.6S449.9 509 512 509s112.6 50.5 112.6 112.6S574.1 734.2 512 734.2zm0-160.9c-26.6 0-48.2 21.6-48.2 48.3 0 26.6 21.6 48.3 48.2 48.3s48.2-21.6 48.2-48.3c0-26.6-21.6-48.3-48.2-48.3z"></path></svg>
                </span>
            </div>
        </div>
        <div class="level-title">晋升体系</div>
        <div class="block-item">
            <div class="level-name">普通会员</div>
            <div class="desc">注册即可</div>
            <div class="gift">
                无福利
            </div>
        </div>
        <div class="block-item">
            <div class="level-name">白银会员</div>
            <div class="desc">消费满1元</div>
            <div class="gift">
                每月赠送1*10元优惠劵
            </div>
        </div>
        <div class="block-item">
            <div class="level-name">黄金会员</div>
            <div class="desc">消费满1500元</div>
            <div class="gift">
                每月赠送3*15元优惠劵
            </div>
        </div>
        <div class="block-item">
            <div class="level-name">钻石会员</div>
            <div class="desc">消费满10000元</div>
            <div class="gift">
                每月赠送3*20元优惠劵
            </div>
        </div>

        <div class="block-item rule">
            <p>用户须知：</p>
            <p>1、等级晋升所需消费金额以用户完成订单并评价后为准。</p>
            <p>2、会员福利在每月1号23:59:59后发放，发放可能会有延迟，但一般不会超过24小时。</p>
            <p>3、会员福利可能会发生变更，最终已当前页面展示为准。</p>
            <p>4、如发现用户有作弊行为，本网站有权在不告知用户的情况下取消相关福利。</p>
        </div>
  </div>
</template>

<script>

export default {
    name: "vip",
    components: {},
    data() {
        return {
           
        };
    },
    mounted(){
        this.$store.dispatch('hideBottomBar');
    },
    beforeDestroy(){
        
    },
    methods:{

    }
};
</script>

<style scoped lang='less' >
.page-wrap{
    background:  rgba(21, 21, 21, 0.95);
    padding-bottom: 1rem;
}
.member-bg{
    
    color: #fff;
    text-align: center;
    padding: 1rem;
}
.member-bg .title{
    font-size: 26px;

}
.vip-card{
    background: linear-gradient(to right ,rgba(172,132,86,1),rgba(223,185,145,1));
    padding: 25px;
    width: 100%;
    border-radius: 5px;
    color: #674623;
    display: inline-block;
    text-align: left;
    position: relative;
}
.vip-card .icon{
    position: absolute;
    right: 30px;
    top: calc(50% - 20px);
    font-size: 36px;
}
.vip-card .total-points{
    display: inline-block;
    font-size: 12px;
    color: #caa782;
    background: #674623;
    padding: 1px 12px;
    border-radius: 10px;
    min-width: 100px;
    text-align: center;
}
.vip-card .level{
    padding-left: 8px;
    margin-bottom: 3px;
}
.block-item{
    margin: 1rem;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 15px;
    color: #fff;
}
.block-item .level-name{
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0.5rem;
}
.block-item .level-name::after{
    display: block;
    width: 20px;
    height: 2px;
    background: #c4a375;
    content: '';
    margin-top: 3px;
}
.block-item .desc{
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
}
.block-item .gift{
    background: linear-gradient(to right, #b89264, #d1ae88);
    margin: 30px 10px 0 10px;
    text-align: center;
    padding: 10px;
    color: #674623;
    border-radius: 5px;
}
.level-title{
    color: #fff;
    text-align: center;
}
.rule{
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
}
</style>
