import app from '../main'
const helper = {
    setCookie(cname,cvalue,exdays){
        var d = new Date();
        d.setTime(d.getTime()+(exdays*24*60*60*1000));
        var expires = "expires="+d.toGMTString();
        document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/";
    },
    getCookie(cname)
    {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for(var i=0; i<ca.length; i++) 
        {
            var c = ca[i].trim();
            if (c.indexOf(name)==0){
                return c.substring(name.length,c.length)
            }
        }
        return "";
    },
    selectCurrency(type){
        this.setStorage("currency", type);
        window.location.href = "/"
    },
    setStorage(name,value){
        if(!name){
            return false;
        }
        let data={};
        if(typeof value === 'object'){
            data.type='object'
        }else{
            data.type='string';
        }
        data.value=value;
        window.localStorage.setItem(name,JSON.stringify(data));
    },
    getStorage(name){
        let data=window.localStorage.getItem(name);
        if(!data){
            return null;
        }
        data=JSON.parse(data);
        return data.value;
    },
    scrollTop(top){
        document.body.scrollTop = top || 0;
        document.documentElement.scrollTop = top || 0;
    },
    goPage(route,needAuth,replace){
        if(needAuth && !app.$store.state.isLogin){
            app.$router.push("/login?referer="+route);
            return;
        }
        
        if(route.indexOf('login') > -1){
            if(route.indexOf('?') > -1){
                route += '&referer=' + app.$route.path;
            }else{
                route += '?referer=' + app.$route.path;
            }
        }

        if(replace){
            app.$router.replace(route);
        }else{
            app.$router.push(route);
        }
        
    },
    inArray(value, array){
        let item = array.find((v)=>{
            return v==value;
        });
        return !item ? false:true;
    },
    nullToEmpty(arr){
        for(let i in arr){
            if(arr[i] == null){
                arr[i]  = ""
            }
        }
    },
    getRGBFromImg(src, callback) {
        let img = new Image()
        let canvas = document.getElementById('canvas')
        let ctx = canvas.getContext('2d')
        img.onload = function () {
           
            canvas.width = img.width
            canvas.height = img.height
            ctx.drawImage(img, 0, 0)
            img.style.display = 'none'
            //ImageData.data	类型为Uint8ClampedArray的一维数组，每四个数组元素代表了一个像素点的RGBA信息，每个元素数值介于0~255
            let data = (ctx.getImageData(0, 0, img.width, img.height)).data
            let countMap = {}
            
            for (let i = 0; i < data.length; i += 4) {
                let alpha = data[i + 3]
                // skip FULLY transparent pixels
                if (alpha === 0) continue
            
                let rgbComponents = Array.from(data.subarray(i, i + 3))
            
                // skip undefined data
                if (rgbComponents.indexOf(undefined) !== -1) continue

                if(rgbComponents[0] >= 220 && rgbComponents[1] >= 220 && rgbComponents[2] >= 220){
                    continue
                }
                if(rgbComponents[0] < 20 && rgbComponents[1] < 20 && rgbComponents[2] < 20){
                    continue
                }
            
                let color = `rgb(${rgbComponents.join(',')})`
                
                if (countMap[color]) {
                    countMap[color].count++
                } else {
                    countMap[color] = { color:rgbComponents, count: 1 }
                }
            }
            let counts = Object.values(countMap)
            counts.sort((a, b) => b.count - a.count)
            callback && callback(counts[1].color)
        }
        img.src = src
        img.crossOrigin = "anonymous"
    },
    copyText(text) {
        var input = document.createElement("input");
        input.value = text; // 修改文本框的内容
        input.id = 'copy-input';
        document.body.append(input);
        input.select(); // 选中文本
        document.execCommand("copy"); // 执行浏览器复制命令
        document.getElementById('copy-input').remove();
        app.$toast.succeed('复制成功');
    },
    isWeChat(){
        var ua = window.navigator.userAgent.toLowerCase();
        if(ua.match(/MicroMessenger/i) == 'micromessenger'){
            return true;
        }else{
            return false;
        }
    },
    orderToPay(orderSn, type){
        let route = '/order-pay/'+orderSn;
        if(type == 'groupon'){
            route = '/order-pay-groupon/'+orderSn;
        }
        if(type == 'deposit'){
            route = "/order-pay-deposit";
        }

        //微信中跳转页面以获取openid
        if(this.isWeChat()){
            window.location.replace(route);
            
        }else{
            
            this.goPage(route,true,true);
        }
    }
}
export default helper;