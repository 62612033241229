<template>
    <div class="block-item">
        <div class="title" @click="showCombos">
            <span>推荐搭配 <span style="font-weight: normal; font-size: 12px" >精选搭配多款产品</span></span>
            <span class="more-view" >
                <span>{{product.combos.length}}款产品</span>
                <md-icon name="arrow-right" size="small"></md-icon>
            </span>
        </div>
        <div class="combo-desc">
            <div class="item-info product">
                <img :src="$store.state.assetUrl + product.img" alt="">
            </div>
            <div></div>
            <div class="item-info">
                <div class="label">
                   <strong>{{product.combos.length}}</strong>款产品搭配，最高可享<strong>{{maxDiscount}}</strong>折
                </div>
                <div class="">
                    <md-tag shape="circle"  font-color="#ff5257" size="middle">一起买更优惠</md-tag>
                </div>
                <div class="desc">
                    进入选配页面可以选择不同搭配
                </div>
            </div>
        </div>
        <div class="mt-1">
            <md-button type="warning" round class="md" @click="showCombos">马上去选配</md-button>
        </div>
        
        <md-popup v-model="combosVisible" position="bottom" class="combos-pop">
            <div class="block-title">
                <span>搭配产品</span>
                <a @click="combosVisible = false" class="close"><md-icon name="close"></md-icon></a>
            </div>
            
            <div class="combos-panel">
                <div class="step-box">
                    <div class="item"><span>选择商品和规格</span></div>
                    <div class="item"><span>加入购物车</span></div>
                    <div class="item"><span>下单付款</span></div>
                </div>
                <div class="combo-item">
                    <div class="info">
                        <img :src="$store.state.assetUrl + product.img" alt="">
                        <div class="name-box">
                            <div class="name text-ellipsis">{{product.name}}</div>
                            <div class="desc">{{product.sku.spec_desc}}</div>
                            <div class="desc">{{product.sku.currency.code}}<strong>{{product.sku.currency.amount}}</strong></div>
                        </div>
                    </div>
                    <div>
                        <md-button class="md" plain round @click.stop="showSkus(product)">选择规格</md-button>
                    </div>
                </div>
                <div class="combo-item other" v-for="item in combos" :key="item.id" @click="selectCombo(item)">
                    <div class="check">
                        <md-icon name="check" v-if="!item.active" color="#25c6fc"></md-icon>
                        <md-icon name="checked" v-else color="#25c6fc"></md-icon>
                    </div>
                    <div class="p-info">
                        <div class="info">
                            <img :src="$store.state.assetUrl + item.img" alt="">
                            <div class="name-box">
                                <div class="name text-ellipsis">{{item.name}}</div>
                                <div class="desc">{{item.sku.spec_desc}}</div>
                                <div class="desc">
                                    优惠价:{{item.sku.promote_currency.code}}<strong>{{item.sku.promote_currency.amount}}</strong> 
                                    <del style="margin-left:10px">原价:{{item.sku.currency.code}}{{item.sku.currency.amount}}</del></div>
                            </div>
                        </div>
                        <div>
                            <md-button class="md" plain round @click.stop="showSkus(item)">选择规格</md-button>
                        </div>
                    </div>
                </div>

                
            </div>

            <div class="bottom">
                    <div class="state_box">
                        <div class="item default">
                            <div class="value"><span class="small">{{product.currency.code}}</span>{{product.sku.currency.amount}}</div>
                            <div class="label">默认</div>
                        </div>
                        <div class="item count">
                            <div class="value"><span class="small">{{product.currency.code}}</span>{{selectedCombos.amount}}</div>
                            <div class="label">{{selectedCombos.count}}件商品</div>
                        </div>
                        <div class="item dicount">
                            <div class="value"><span class="small">{{product.currency.code}}</span>{{selectedCombos.discount}}</div>
                            <div class="label">优惠</div>
                        </div>
                        <div class="item">
                            <div class="value"><span class="small">{{product.currency.code}}</span>{{totalAmount}}</div>
                            <div class="label">总计</div>
                        </div>
                    </div>
                    <md-button class="md" round type="warning" @click="addToCart">加入购物车</md-button>
                </div>
            
        </md-popup>

        <md-popup v-model="skusVisible" position="bottom" class="support-pop" >
            <div class="support">
                <div class="block-title">
                    <span>选择规格</span>
                    <a @click="skusVisible = false" class="close"><md-icon name="close"></md-icon></a>
                </div>
                <div class="skus-pop">
                    <md-radio-list :options="skus" v-model="selectSkuId" @change="selectSku"></md-radio-list>
                </div>
            </div>
        </md-popup>
    </div>
</template>

<script>
let self;
export default {
    name: "comments",
    components: {},
    props:{
        info:{
            type:Object,
            default:()=>{}
        }
    },
    data() {
        return {
           product:{
               combos:[],
               sku:{
                   currency:{}
               },
               currency:{}
           },
           combos:[],
           combosVisible:false,
           maxDiscount:0,
           selectedCombos:{
               items:[]
           },
           totalAmount:0,
           skus:[],
           selectInfo:{},
           selectSkuId:0,
           skusVisible:false,
           steps: [
                {
                name: '选择商品',
                },
                {
                name: '选择规格',
                },
                {
                name: '加入购物车',
                },
                {
                name: '下单付款',
                },
            ],
            currentStep:4
        };
    },
    watch:{
        info(val){
            this.product = val
            this.init();
        }
    },
    mounted(){
        self = this;
        this.product = this.info
        this.init();
    },
    beforeDestroy(){
        
    },
    methods:{
        init(){
            if(this.product && this.product.combos && this.product.combos.length > 0){
                self.calcDiscount()
                self.initCombos();
            }
        },
        showCombos(){
            this.combosVisible = true;
        },
        calcDiscount(){
            let discounts = [];
            this.product.combos.map((v)=>{
                discounts.push(v.promote_rate)
            });
            discounts.sort((a,b)=>a-b)
            this.maxDiscount = discounts[0]/10
        },
        //初始化组合商品
        initCombos(){
            if(!this.product.combos){
                return;
            }
            this.product.sku = this.product.skus[0];

            this.product.combos.map((v)=>{
                v.sku = v.skus[0];
                v.active = true;
                self.combos.push(v);
                self.selectedCombos.items.push(v);
            })
            this.calcCombosAmount();
        },
        //计算组合优惠金额
        calcCombosAmount(){
            
            var promote_amount = 0;
            var amount = 0;
            var count = 0;
            var basePrice = this.product.sku.currency.amount;

            this.selectedCombos.items.map((v)=>{
                var price = parseFloat(v.sku.currency.amount) * 100;

                amount += price;
                count += 1;
                promote_amount +=  price * v.promote_rate /100 ;
            })
            if(count == 0){
                this.selectedCombos.count = 0;
                //优惠后总金额
                this.selectedCombos.promote_amount = 0;
                //原价总金额
                this.selectedCombos.amount =  0;
                //已优惠总金额
                this.selectedCombos.discount = 0;
                //商品+组合后总金额
                this.totalAmount = basePrice;
            }else{
                this.selectedCombos.count = count;
                //优惠后总金额
                this.selectedCombos.promote_amount = promote_amount/100;
                //原价总金额
                this.selectedCombos.amount =  amount/100;
                //已优惠总金额
                this.selectedCombos.discount = ((amount - promote_amount) /100).toFixed(2);
                //商品+组合后总金额
                this.totalAmount = (( promote_amount + parseFloat(basePrice)*100) / 100).toFixed(2);
            }
            
        },
        //选择组合商品
        selectCombo(item){
            var selectedCombos = {...this.selectedCombos};
            selectedCombos.items = [];
            this.combos.map((v)=>{
                if(v.id == item.id){
                    v.active = !v.active;
                }
                if(v.active){
                    selectedCombos.items.push(v);
                }
            })

            this.combos = [...this.combos];

            this.selectedCombos = {...selectedCombos};
            this.calcCombosAmount();
            
        },
        selectSku(item){
            
            if(this.selectInfo.id == this.product.id){
                this.product.skus.map((v)=>{
                    if(v.id == item.value){
                        self.product.sku = v
                    }
                })
            }else{
                this.combos.map((v)=>{
                    if(v.id == this.selectInfo.id ){
                        v.skus.map((s)=>{
                            if(s.id == item.value){
                                v.sku = s
                            }
                        })
                    }
                })
            }
            
            this.calcCombosAmount();
            this.skusVisible = false;
        },
        showSkus(info){
            self.skus = [];

            info.skus.map((v)=>{
                let currency = info.id == self.product.id ? v.currency : v.promote_currency
                self.skus.push({
                    value: v.id,
                    text: v.spec_desc,
                    brief: currency.code + currency.amount,
                })
            })
            this.selectInfo = info
            this.selectSkuId = info.sku.id
            this.skusVisible = true
        },
        addToCart(){
            if(this.selectedCombos.count < 1){
                this.$dialog.alert({
                    title: '提示',
                    content: '请选择搭配购买的商品',
                    confirmText: '确定',
                    onConfirm: () => {},
                })
                return;
            }
            let combos = [];
            this.selectedCombos.items.map((v)=>{
                combos.push(v.sku);
            });
            this.product.sku.count = 1;
            this.$emit("addToCart", this.product.sku, combos)
            this.combosVisible = false;
        }
    }
};
</script>
<style>
.md-tag .size-middle{
    font-size: 12px;
}
</style>
<style scoped lang='less' >
@import "../../assets/reset.less";
.block-item {
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.04), 0 5px 10px rgba(0, 0, 0, 0.02);
  margin: 1rem 0.7rem;
  padding: 1rem 0.7rem;
  background: #fff;
}
.block-item .title {
  position: relative;
  margin-bottom: 1rem;
}
.block-item .title::before {
  content: "";
  display: block;
  position: absolute;
  height: 10px;
  width: 3px;
  background: @primary-color;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.01);
  left: -6px;
  top: 5px;
  border-radius: 2px;
}
.block-item .title > span:first-child {
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 15px;
}
.block-item .title .more-view {
  position: absolute;
  right: -4px;
  top: 0;
  font-size: 12px;
  color: #999;
  display: flex;
  line-height: 20px;
}
.block-item .title .more-view .md-icon {
  font-size: 14px;
  line-height: 20px;
}

.skus-pop{
    max-height: calc(100% - 42px);
}
.combos-panel {
  max-height: calc(100% - 140px);
  overflow-y: auto;
  overflow-x: hidden;
  margin:0 -1rem;
  padding: 0 1rem;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background: rgba(0, 0, 0, 0.01);
}

.combos-pop .bottom{
    background: #fff;
    padding: 10px 1rem 0 1rem;
    
}
.combos-pop .state_box{
    display: flex;
    margin-bottom: 7px;
}
.combos-pop .state_box .item{
    flex: 1;
    text-align: center;
    position: relative;
}
.combos-pop .state_box .item::after{
    position: absolute;
    top: 10px;
    right: -5px;
}
.combos-pop .state_box .item.default::after{
    content: "+";
}
.combos-pop .state_box .item.count::after{
    content: "-";
}
.combos-pop .state_box .item.dicount::after{
    content: "=";
}


.combos-pop .state_box .item .label{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
}

.combos-pop .state_box .item .value{
    font-weight: 500;
}

.price{
    font-size: 20px;
    font-weight: 500;

}

.combo-desc{
    display: flex;
    background: rgba(255, 0, 0, 0.04);
    border-radius: 5px;
    padding: 10px;
}
.combo-desc .item-info .label{
    font-weight: 400;
    margin-bottom: 3px;
}
.combo-desc .item-info .desc{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.4);
    margin-top: 5px;
}
.combo-desc .item-info img{
    height: 70px;
}
.combo-desc .product{
    
    padding-top: 2px;
    border-radius: 5px;
    position: relative;
    margin-right: 50px;
    text-align: center;
}
.combo-desc .product::after{
    content: "+";
    font-size: 30px;
    line-height: 30px;
    font-weight: 500;
    position: absolute;
    right: -36px;
    top: calc(50% - 15px);
    color: rgba(0, 0, 0, 0.4);
}
.combo-item{
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    margin: 1rem 0 ;
    position: relative;
    margin-bottom: 2rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}
.combo-item.other{
    display: flex;
}
.combo-item.other>.check{
    width: 25px;
    line-height: 100px;
}
.combo-item.other .p-info{
    width: calc(100% - 25px);
}

.combo-item::after{
    content:"+";
    color: rgba(0, 0, 0, 0.5);
    display: block;
    text-align: center;
    position: absolute;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    bottom: -30px;
}
.combo-item:last-child{
    margin-bottom: 1rem;
}
.combo-item:last-child::after{
    display: none;
}
.combo-item .info{
    display: flex;
    margin-bottom: 10px;
    width: 100%;
}
.combo-item .info img{
    height: 55px;
    border-radius: 5px;
}
.combo-item .info .name-box{
    padding-left: 10px;
    width: calc(100% - 55px);
}
.combo-item .info .name-box .desc{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
}
.step-box{
    display: flex;
    margin-top: 1rem;
}
.step-box .item{
    flex: 1;
    text-align: center;
    font-size: 12px;
    position: relative;
}
.step-box .item::after{
    content: "·";
    color: rgba(255, 82, 87, 1);
    position: absolute;
    top: -1px;
    right: -7px;
}
.step-box .item:last-child::after{
    content: "";
}
.step-box .item span{
    border: 1px solid rgba(255, 82, 87, 0.4);
    color: rgba(255, 82, 87, 1);
    border-radius: 15px;
    padding: 2px 6px;
}
</style>
