import Vue from 'vue';
import Vuex from 'vuex'
import helper from './utils/helper'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        pageTitle:'页面标题',
        menus: [],
        userInfo:{},
        token:'',
        isLogin:false,
        assetUrl: process.env.VUE_APP_ASSET_URL+'/',
        bottomBarCurrent: 1,
        bottomBarVisible: true,
        loading:false,
        showLoading:true,//是否在请求时展示loading
        loadingCount:0,
        pageLoad:{},
    },
    mutations: {
        setPageTitle(state, title){
            state.pageTitle = title;
        },
        hideBottomBar(state){
            state.bottomBarVisible = false;
        },
        showBottomBar(state,index){
            state.bottomBarVisible = true;
            state.bottomBarCurrent = index;
        },
        setUserInfo(state,info){
           
            if(!info){
                state.isLogin = false;
                state.userInfo = {};
                state.token = '';
                helper.setStorage('userInfo', '');
            }else{
                
                helper.setStorage('userInfo', info);
                state.token = info.auth_token;
                state.isLogin = true;
                state.userInfo = {...info};
            }
            
        },
        //开启loading
        ableShowLoading(state){
            state.showLoading = true;
        },
        //禁用loading
        disableShowLoading(state){
            state.showLoading = false;
        },
        //显示loading
        showLoading(state){
            if(state.showLoading){
                state.loadingCount++;
                if(state.loadingCount == 1){
                    state.loading = true;
                }
            }
        },
        //隐藏loading
        hideLoading(state){
            state.loadingCount--;
            if(state.loadingCount <= 0){
                state.loadingCount = 0;
                state.loading = false;
            }
        },
        setPageLoad(state, paylod){

            state.pageLoad = {...state.pageLoad,...paylod};
        },
        setNeedLoginType(state, paylod){
            state.needLoginType = paylod;
        }
    },
    actions: {
        showBottomBar (context,index) {
            context.commit('showBottomBar',index);
        },
        hideBottomBar (context) {
            context.commit('hideBottomBar');
        },
        setUserInfo (context, info) {
            context.commit('setUserInfo', info);
        }
    }
  })

  export default store;