<template>
  <div class="page-wrap">
    <div class="empty"  v-if="carts.count == 0">
        <p><svg t="1631180042906" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16472" width="64" height="64"><path d="M855.6 427.2H168.5c-12.7 0-24.4 6.9-30.6 18L4.4 684.7C1.5 689.9 0 695.8 0 701.8v287.1c0 19.4 15.7 35.1 35.1 35.1H989c19.4 0 35.1-15.7 35.1-35.1V701.8c0-6-1.5-11.8-4.4-17.1L886.2 445.2c-6.2-11.1-17.9-18-30.6-18zM673.4 695.6c-16.5 0-30.8 11.5-34.3 27.7-12.7 58.5-64.8 102.3-127.2 102.3s-114.5-43.8-127.2-102.3c-3.5-16.1-17.8-27.7-34.3-27.7H119c-26.4 0-43.3-28-31.1-51.4l81.7-155.8c6.1-11.6 18-18.8 31.1-18.8h622.4c13 0 25 7.2 31.1 18.8l81.7 155.8c12.2 23.4-4.7 51.4-31.1 51.4H673.4zM819.9 209.5c-1-1.8-2.1-3.7-3.2-5.5-9.8-16.6-31.1-22.2-47.8-12.6L648.5 261c-17 9.8-22.7 31.6-12.6 48.4 0.9 1.4 1.7 2.9 2.5 4.4 9.5 17 31.2 22.8 48 13L807 257.3c16.7-9.7 22.4-31 12.9-47.8zM375.4 261.1L255 191.6c-16.7-9.6-38-4-47.8 12.6-1.1 1.8-2.1 3.6-3.2 5.5-9.5 16.8-3.8 38.1 12.9 47.8L337.3 327c16.9 9.7 38.6 4 48-13.1 0.8-1.5 1.7-2.9 2.5-4.4 10.2-16.8 4.5-38.6-12.4-48.4zM512 239.3h2.5c19.5 0.3 35.5-15.5 35.5-35.1v-139c0-19.3-15.6-34.9-34.8-35.1h-6.4C489.6 30.3 474 46 474 65.2v139c0 19.5 15.9 35.4 35.5 35.1h2.5z" p-id="16473" fill="#bfbfbf"></path></svg></p>
        <p>暂无商品</p>
        <div class="d-flex">
            <md-button  type="default" class="md" round @click="$helper.goPage('/search')">去购物</md-button>
        </div>
    </div>
    <div v-else >
        <div class="cart-title">
            <span class="pull-left">选择</span>
            <span class="pull-right" @click="action = 'edit'" v-if="action == 'list'">编辑</span>
            <span class="pull-right" @click="action = 'list'" v-if="action == 'edit'">完成</span>
        </div>
        <div class="cart-item" v-for="item in carts.list" :key="item.id">
            <div class="item-content">
                <div class="check" @click="selectCartItem(item.id)" v-if="action == 'list'">
                    <md-icon name="check" v-if="!item.status" color="#25c6fc"></md-icon>
                    <md-icon name="checked" v-else color="#25c6fc"></md-icon>
                </div>
                <div class="check"  v-if="action == 'edit'">
                    <md-icon name="delete" @click="delCartItem(item.id)" color="#f96062"></md-icon>
                </div>
                <img :src="$store.state.assetUrl + item.product.img" alt="">
                <div class="info" >
                    <div class="name text-ellipsis" @click="$helper.goPage('/item/' + item.product.slug)">{{item.product.name}}</div>
                    <div class="spec-desc">{{item.sku_info.spec_desc}}</div>
                    <div class="error">{{carts.error[item.id]}}</div>
                    <div class="price-box">
                        <div class="price"><span class="small">{{item.currency.code}}</span>{{item.currency.amount}}</div>
                        <div class="count">
                            <md-stepper slot="right" v-model="item.count" @change="changeCartItemCount(item)" :step="1" :min="1" is-integer ></md-stepper>
                        </div>
                    </div>
                </div>
            </div>
            <div class="combos-list">
                <div class="combos-item" v-for="gift in item.gift_products" :key="gift.id">
                    <img :src="$store.state.assetUrl + gift.img" alt="">
                    <div class="info" >
                        <div class="name text-ellipsis" @click="$helper.goPage('/item/' + gift.slug)">
                            <md-tag size="middle"  shape="circle"  fill-color="#37e492" font-color="#37e492">赠品</md-tag>
                            {{gift.name}}
                        </div>
                        <div class="spec-desc">{{gift.spec_desc}} x{{ item.count }}</div>
                        <div class="error">{{carts.error[gift.id]}}</div>
                    </div>
                </div>
            </div>
            <div class="combos-list">
                <div class="combos-item" v-for="combo in item.combos" :key="combo.id">
                    <img :src="$store.state.assetUrl + combo.product.img" alt="">
                    <div class="info" >
                        <div class="name text-ellipsis" @click="$helper.goPage('/item/' + combo.product.slug)">
                            <md-tag size="middle"  shape="circle"  fill-color="#fff7e6" font-color="#fa8c16">组合</md-tag>
                            {{combo.product.name}}
                        </div>
                        <div class="spec-desc">{{combo.sku_info.spec_desc}}</div>
                        <div class="error">{{carts.error[combo.id]}}</div>
                        <div class="price-box">
                            <div class="price"><span class="small">{{combo.currency.code}}</span>{{combo.currency.amount}}</div>
                            <div class="count">x{{ combo.count }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-1 container" v-if="action == 'list'">
        <guess-likes></guess-likes>
    </div>

    <div class="bottom-box" v-if="action == 'list'">
        <div class="stat">
            <md-icon name="check"  v-if="carts.count != carts.select_count" color="#25c6fc"  @click="selectCartItem('all')"></md-icon>
            <md-icon name="checked"  v-else color="#25c6fc" ></md-icon>
            <span style="margin:0 0.5rem;" @click="selectCartItem('all')">全选</span>
            <span><strong>总价：</strong><span class="price"><span class="small">{{carts.select_currency.code}}</span>{{carts.select_currency.amount}}</span></span>
        </div>
        <div class="btns">
            <md-button class="md" type="primary" round  @click="$helper.goPage('checkout',true)" :disabled="carts.select_count < 1" >去结算</md-button>
        </div>
    </div>
  </div>
</template>

<script>
import guessLikes from './product/guessLikes.vue';

let self;
export default {
    name: 'carts',
    components: {guessLikes},
    data(){
        return {
            loading:true,
            carts:{
                count:0,
                select_currency:{}
            },
            action:"list",//edit
        }
    },
    mounted(){
            self = this;
            document.title="购物车";
            this.getCarts();
            this.$store.dispatch('showBottomBar', 4);
    },
    methods:{
        getCarts(){
            self.carts = self.$store.state.pageLoad.carts || {count:0,select_currency:{}};
            this.$http.get('carts', {showLoading:false}).then((res)=>{
                self.carts = res.data;
                if(res.data.error['all']){
                    self.$toast.error(res.data.error['all']);
                }
                //如果商品为空默认是列表模式
                if(res.data.count == 0){
                    self.action = 'list';
                }
                self.$store.commit('setPageLoad', {carts:res.data});
            });
        },
        
        changeCartItemCount(item){
            
            this.$http.post('changeCartItemCount',{id:item.id,count:item.count}).then(()=>{

                self.getCarts();
            });
        },
        selectCartItem(id){
            
            this.$http.post('selectCartItem',{id:id}).then(()=>{
                self.getCarts();
            });
        },
        delCartItem(id){
            
            this.$http.post('delCartItem',{id:id}).then(()=>{
                
                self.getCarts();
            });
        }
    }
}
</script>
<style>

</style>
<style lang='less' scoped>
.page-wrap{
    padding-bottom: 120px;
}
.empty{
    padding: 3rem;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
}
.empty .md-button{
    width: 50%;
    display: inline-block;
    margin-top: 2rem;
}
.cart-title{
    padding: 1rem 1.5rem;
    font-weight: 500;
    color: #111;
    overflow: hidden;
}
.cart-item{
    
    margin: 0 0.7rem 0.5rem 0.7rem;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);
    padding: 1rem;
}
.cart-item .item-content{
    display: flex;
}
.cart-item .check{
    width: 20px;
    display: inline-block;
    line-height: 80px;
}
.cart-item .check .md-icon.icon-font{
    font-size: 20px;
}
.cart-item img{
    width: 80px;
    height: 80px;
    margin-left: 0.7rem;
    background: rgba(0, 0, 0, 0.02);
    padding: 2px;
    border-radius: 5px;
}
.cart-item .info{
    padding-left: 0.5rem;
    font-size: 13px;
    width: calc(100% - 100px);
}
.cart-item .info .name{
    font-size: 14px;
    font-weight: 500;
}
.cart-item .spec-desc{
    line-height: 20px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
}
.cart-item .price-box{
    display: flex;
    height: 25px;
    line-height: 25px;
}
.cart-item .price-box .price{
    text-align: left;
    flex: 1;
    color: #f96062;
    font-size: 16px;
    font-weight: 500;
}
.cart-item .price-box .count{
    flex: 1;
    text-align: right;
}
.cart-item .error{
    font-size: 12px;
    color: #f96062;
}

.bottom-box{
    position: fixed;
    bottom: 67px;
    left: 0;
    width: 100%;
    background: #fff;
    font-size: 12px;
    display: flex;
    padding: 0.4rem 1rem;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.bottom-box .stat{
    flex: 2;
    text-align: left;
    line-height: 38px;
}
.bottom-box .stat .md-icon.icon-font{
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
}
.bottom-box .stat .price{
    font-size: 16px;
    color: #f96062;
    font-weight: 500;
}
.bottom-box .btns{
    flex: 1;
    text-align: right;
}
.combos-list{
    margin-top: 5px; 
}
.combos-item{
    display: flex;
    border-top: 1px dashed rgba(0, 0, 0, 0.05);
    padding: 5px 0;
}
.combos-item img{
    height: 60px;
    width: 60px;
    margin-left: 50px;
}
.combos-item .info {
    width: calc(100% - 110px);
}
.combos-item .info .name{
    font-size: 12px;
}
.combos-item .info .price-box{
    height: 20px;
    line-height: 20px;
}
.combos-item .info .price-box .price{
    font-weight: normal;
    font-size: 12px;
}
</style>
