import { render, staticRenderFns } from "./commission.vue?vue&type=template&id=acf22c9e&scoped=true&"
import script from "./commission.vue?vue&type=script&lang=js&"
export * from "./commission.vue?vue&type=script&lang=js&"
import style0 from "./commission.vue?vue&type=style&index=0&id=acf22c9e&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acf22c9e",
  null
  
)

export default component.exports