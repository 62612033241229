<template>
    <div class="page-wrap">
        <div class="top-box">
            <div class="search-box">
                <div class="back-icon" @click="$helper.goPage('my-account')">
                    <md-icon name="arrow-left" size="lg"></md-icon>
                </div>
                <div class="search-input">
                    <md-icon name="search" class="search-icon"></md-icon>
                    <input type="search" placeholder="请输入订单编号" v-model="orderSn">
                </div>
                <a class="search-btn" @click="search">搜索</a>
            </div>
        </div>
        <div class="log-item" v-for="item in orders.data" :key="item.id">
            <div class="status">
                <div>#{{item.order_sn}}</div>
                <div class="text-right">
                    <md-tag shape="circle" type="fill" fill-color="#37e492" font-color="#fff">已支付</md-tag>
                </div>
            </div>
            <div class="info">
                {{item.desc}}
            </div>
            <div class="amount-box">
                <div class="amount"><span class="small">{{item.currency.code}}</span>{{item.currency.amount}}</div>
                <div class="date">{{item.paid_at}}</div>
            </div>
        </div>
        <div class="load-more" v-if="isLoading">正在努力加载中...</div>
        <div class="load-more" v-if="isNoMore">全部加载完了</div>
    </div>
</template>

<script>
let self;
export default {
    name: "collect-orders",
    components: {},
    data() {
        return {
            orderSn:'',
            orders: [],
            page:1,
            isLoading:false,
            isNoMore:false,
        };
    },
    mounted(){
        self = this;
        this.getOrders();
        this.$store.dispatch('hideBottomBar');
        document.title = '我的账单'
        window.onscroll = ()=>{
            if(self.$route.path == '/orders'){
                self.endReached();
            }
        }
    },
    beforeDestroy(){
        
    },
    methods:{
        search(){
            this.page = 1;
            this.getOrders();
        },
        getOrders(){
            let page = this.page;
            this.$http.get('user/listCollectOrders',{params:{page: page, order_sn:this.orderSn}}).then((res)=>{
                
                if (page == 1) {
                    self.orders = res.data;
                } else if(res.data.data.length){
                    self.orders.data = self.orders.data.concat(res.data.data);
                }
            }).finally(()=>{
                self.isLoading = false;
            });
        },
        endReached(){
            let top = document.documentElement.scrollTop || document.body.scrollTop;
            //触底
            if(top + window.innerHeight  > document.getElementsByClassName('page-wrap')[0].offsetHeight - 90){
                if(this.isLoading){
                    return;
                }
                if(this.page >= this.points.last_page ){
                    self.isNoMore = true;
                    return;
                }
                this.page++;
                this.isLoading = true;
                this.getOrders();
            }
        },
    }
};
</script>

<style scoped lang='less' >
@import "../assets/reset.less";
.page-wrap{
    padding-top: 60px;
    padding-bottom: 0;
    background: rgba(0, 0, 0, 0.01);
}

.top-box{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
}
.search-box,.cates-box{
    background: #fff;
    padding: 0.7rem;
    display: flex;
    font-size: 13px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    position: relative;
}
.search-box .back-icon{
    width: 30px;
    text-align: left;
    height: 34px;
    line-height: 38px;
}
.search-box .search-input{
    position: relative;
    width: calc(100% - 70px);
}
.search-box .search-icon{
    position: absolute;
    left: 0.5rem;
    top: 0.6rem;
}
.search-box input{
    -webkit-appearance: none;
    -webkit-box-shadow: none;
    outline: none;
    width: 100%;
    background: rgba(0, 0, 0, 0.03);
    height: 34px;
    line-height: 34px;
    padding-left: 30px;
    border-radius: 25px;
    border: 1px solid rgba(37, 198, 252, 0);
    transition: all 0.2s;
}
.search-box input:focus{
    border: 1px solid rgba(37, 198, 252, 0.8);
    background: #fff;
}
.search-box .search-btn{
    width: 40px;
    display: inline-block;
    text-align: right;
     line-height: 34px;
}
.log-item{
    background: #fff;
    padding: 0.7rem;
    border-radius: 10px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.05), 0 5px 10px rgba(0, 0, 0, 0.02), 0 1px 3px rgba(0, 0, 0, 0.01);
    margin: 0.5rem 0.7rem;
}
.log-item .status{
    display: flex;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.06);
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
}
.log-item .status div{
    flex: 1;
}
.log-item .date{
    color: rgba(0, 0, 0, 0.6);
    text-align: right;
    flex: 1;
}
.log-item .amount-box{
    font-size: 13px;
    display: flex;
    border-top: 1px dashed rgba(0, 0, 0, 0.06);
    padding-top: 0.5rem;
    margin-top: 0.5rem;
}
.log-item .info{
    white-space: pre-wrap;
    color: #000;
}
</style>
