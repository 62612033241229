<template>
    <div class="page-wrap">
        <div class="block-item info" @click="orderVisible = true">
            <div>
                <i class="icon">
                    <svg viewBox="64 64 896 896" data-icon="barcode" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M120 160H72c-4.4 0-8 3.6-8 8v688c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V168c0-4.4-3.6-8-8-8zm833 0h-48c-4.4 0-8 3.6-8 8v688c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V168c0-4.4-3.6-8-8-8zM200 736h112c4.4 0 8-3.6 8-8V168c0-4.4-3.6-8-8-8H200c-4.4 0-8 3.6-8 8v560c0 4.4 3.6 8 8 8zm321 0h48c4.4 0 8-3.6 8-8V168c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v560c0 4.4 3.6 8 8 8zm126 0h178c4.4 0 8-3.6 8-8V168c0-4.4-3.6-8-8-8H647c-4.4 0-8 3.6-8 8v560c0 4.4 3.6 8 8 8zm-255 0h48c4.4 0 8-3.6 8-8V168c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v560c0 4.4 3.6 8 8 8zm-79 64H201c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h112c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm257 0h-48c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm256 0H648c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h178c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm-385 0h-48c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"></path></svg>    
                </i>
            {{order.order_sn}}</div>
            <div class="color-gray name" v-if="order.product_type == 0">{{order.first_name}} {{order.last_name}}</div>
            <div class="color-gray name" v-else>{{order.address.consignee}} {{order.address.mobile}}</div>
            <a class="more-view"><md-icon name="arrow-right" ></md-icon></a>
        </div>
        <div class="container">
            <div class="amount">
                <small>{{order.total_currency.code}}</small> {{order.total_currency.amount}}
            
                <div class="countdown">
                    <template v-if="deadline >0">
                        剩余：
                        <span class="count-item">{{countdown.days[0]}}</span> <span class="count-item">{{countdown.days[1]}}</span> 天
                        <span class="count-item">{{countdown.hours[0]}}</span> <span class="count-item">{{countdown.hours[1]}}</span> 时
                        <span class="count-item">{{countdown.mins[0]}}</span> <span class="count-item">{{countdown.mins[1]}}</span> 分
                        <span class="count-item">{{countdown.secs[0]}}</span> <span class="count-item">{{countdown.secs[1]}}</span> 秒
                    </template>
                </div>
            </div>

            
            <div class="block-item payments">
                <div class="title">选择支付方式</div>

                
                <div class="payment-item" @click="setPayment('alipay')" v-if="!isWechat">
                    <div class="label"><img height="24" src="../assets/alipay.svg" alt=""></div>
                    <div class="check">
                        <md-radio name="alipay" v-model="payment" />
                    </div>
                </div>
                <div class="payment-item" @click="setPayment('wechat')">
                    <div class="label"><img height="25" src="../assets/wechat.svg" alt=""></div>
                    <div class="check">
                        <md-radio name="wechat" v-model="payment" />
                    </div>
                </div>

                <div class="payment-item" @click="setPayment('paypal')" v-if="order.total_amount > 10">
                    <div class="label"><img height="22" src="../assets/paypal.svg" alt=""></div>
                    <div class="check">
                        <md-radio name="paypal" v-model="payment" />
                    </div>
                </div>

                <div class="payment-item"  @click="setPayment('stripe')" v-if="order.total_amount > 10">
                    <div class="label" >
                        <img height="22" src="../assets/credit.svg" alt="" style="vertical-align: top;">
                        <md-icon name="info" @click.stop="creditVisible = true"></md-icon>
                    </div>
                    <div class="check">
                        <md-radio name="stripe" v-model="payment" />
                    </div>
                </div>
            </div>
            <div class="bottom-box">
                <!-- paypal -->
                <div :class="{hide:payment != 'paypal'}" class="pay-btn" id='paypal-button-container'></div>
                <!-- paypal -->
                <!-- strip-pay -->
                <form id="payment-form" :class="{hide:payment != 'stripe'}">
                    <div id="card-element"><!--Stripe.js injects the Card Element--></div>
                    <div class="stripe-error">{{stripe_error}}</div>
                    <button type="submit" class="md-button primary active block round md">确认支付</button>
                </form>
                <!-- strip-pay -->
                <md-button type="primary" class="md" round v-if="payment != 'paypal' && payment != 'stripe'" @click="createPay">确认支付</md-button>
            </div>

            <md-popup
            v-model="orderVisible"
            position="bottom"
            class="order-pop"
            >
                <div class="order">
                    <div class="block-title">
                        <span>订单信息</span>
                    </div>
                    <div class="order-item" v-for="item in order.subjects" :key="item.id">
                        <div class="label">{{item.desc}}</div>
                        <div class="content">{{item.type == 'decr' ? '-':''}} <small>{{item.currency.code}}</small> {{item.currency.amount}}</div>
                    </div>
                    <div class="order-item">
                        <div class="label">订单总价</div>
                        <div class="content color-danger"><small>{{order.total_currency.code}}</small>{{order.total_currency.amount}}</div>
                    </div>
                    <div class="order-item">
                        <div class="label">订单编号</div>
                        <div class="content">{{order.order_sn}}</div>
                    </div>
                    <div class="order-item">
                        <div class="label">下单时间</div>
                        <div class="content">{{order.created_at}}</div>
                    </div>

                    <md-button round type="primary" class="mt-2 md" @click="$helper.goPage('/order/'+ order.order_sn,true, true)">查看更多详情</md-button>
                </div>
            </md-popup>

            <md-popup
            v-model="creditVisible"
            position="bottom"
            class="credit-pop"
            >
                <div class="credit">
                    <div class="block-title">
                        <span>信用卡支付说明</span>
                    </div>
                    <div>
                        支持当前流行的各种信用卡：Visa，Mastercard，American Express，Discover，Diners Club，JCB等。
                        若您的卡开通了 3D Secure 验证，请按操作说明进行下一步，单击 "COMPLETE AUTHENTICATION" 验证你的卡片。
                    </div>
                </div>
            </md-popup>

        </div>
    </div>
</template>

<script>
let self;
export default {
    name: "orderPay",
    components: {},
    data() {
        return {
            orderSn:'',
            isRead:true,
            orderVisible:false,
            creditVisible:false,
            order:{
                address:{},
                total_currency:{}
            },
            countDate:{
                min1:0,
                min2:0,
                sec1:0,
                sec2:0
            },
            deadline : 0,
            countdown:{
                days:0,
                hours:0,
                mins:0,
                secs:0
            },
            timer:null,
            checkTimer:null,
            payment:'alipay',
            pay_url:'',
            scanVisible:false,
            webVisible :false,
            payExpireVisible:false,
            pay_qrcode : '',
            step:1,
            stripe_error:'',
            isWechat:false,
            wechatJsPayParams:{},
            initedPaypal:false,
            initedStripe:false
        };
    },
    mounted(){
        document.title = '支付订单';

        self = this;
        this.$store.dispatch('hideBottomBar');
        this.orderSn = this.$route.params.orderSn;
        this.isWechat = this.$helper.isWeChat();
        if(this.isWechat){
            this.payment = 'wechat';
        }

        this.getOrder();
    },
    beforeDestroy(){
        clearInterval(this.timer);
    },
    methods:{
        getOrder(){
            this.$http.get('user/getOrder?order_sn='+ this.orderSn).then((res)=>{
                self.order = res.data;
                if(self.order.status > 0){
                    self.$dialog.alert({
                        title: '提示',
                        content: '您的订单已支付过了，请勿重复支付',
                        confirmText: '确定',
                        onConfirm: () => {
                            self.$helper.goPage('/order/'+self.order.order_sn, true, true);
                        }
                    })
                }
                if(self.order.status < 0){
                    self.$dialog.alert({
                        title: '提示',
                        content: '您的订单已过期，请重新下单',
                        confirmText: '确定',
                        onConfirm: () => {
                            self.$helper.goPage('/order/'+self.order.order_sn, true, true);
                        }
                    })
                }
                //过期时间戳
                self.deadline = parseInt((self.order.pay_expire_time * 1000 - Date.now())/1000);

                self.calcCountdown()
                self.timer = setInterval(()=>{
                    self.calcCountdown()
                },1000);
                
            }).finally(()=>{
                
            })
        },
        setPayment(payment){
            this.payment = payment;

            if(this.payment == 'paypal' && this.initedPaypal == false){
                this.initedPaypal = true;
                this.initPaypal();
            }
            if(this.payment == 'stripe' && this.initedStripe == false){
                this.initedStripe = true;
                this.initStripe();
            }
        },
        initPaypal(){
            let self = this;
            // eslint-disable-next-line no-undef
            paypal.Buttons({
                // eslint-disable-next-line no-undef
                fundingSource:paypal.FUNDING.PAYPAL,
                style:{
                    height: 38,
                    color:'gold',
                    shape:'pill'
                },
                createOrder: function() {
                    return self.$http.post('user/create/paypal/wap',{order_sn:self.order.order_sn}).then((res)=>{
                        return res;
                    }).then((res)=>{
                        return res.data.result.id
                    });
                },
                onApprove: function() {
                    self.$helper.goPage('/pay-callback/paypal/'+ self.order.order_sn,false,true);
                    return true;
                }
            }).render('#paypal-button-container');
        },
        initStripe(){
            var self = this;
            
            // eslint-disable-next-line no-undef
            var stripe = Stripe("pk_live_sb3YeRMdoizkQ6Jqiu49GjVk");
            var elements = stripe.elements();
            var style = {
                base: {
                    color: "#244583",
                    fontFamily: 'Arial, sans-serif',
                    fontSmoothing: "antialiased",
                    fontSize: "16px",
                    "::placeholder": {
                        color: "#333"
                    }
                },
                invalid: {
                    fontFamily: 'Arial, sans-serif',
                    color: "#fa755a",
                    iconColor: "#fa755a"
                }
            };
            var card = elements.create("card", { style: style });
            card.mount("#card-element");
            card.on("change", function (event) {
                self.stripe_error = event.error ? event.error.message : "";
            });
            var form = document.getElementById("payment-form");
            form.addEventListener("submit", function(event) {
                event.preventDefault();
                
                
                self.$http.post('user/create/stripe/wap',{order_sn:self.order.order_sn}).then((res)=>{
                    stripe.confirmCardPayment(res.data.client_secret, {
                        payment_method: {
                            card: card
                        }
                    }).then(function(result) {
                        
                        if (result.error) {
                           
                            self.$toast.error(result.error.message);
                        } else {
                            self.$helper.goPage('/pay-callback/stripe/'+ self.order.order_sn,false,true);
                        }
                    }).finally(()=>{
                        self.$store.commit('hideLoading');
                    });
                }).finally(()=>{
                    self.$store.commit('showLoading');
                });
            });
            
        },
        calcCountdown(){
            this.deadline--;
            
            let count = parseInt(this.deadline);
            if(count <= 0){
                clearInterval(this.timer);
                return;
            }
            
            //天数
            let days = Math.floor(count/(24*3600));
            count -= days * 24*3600;
            this.countdown.days = this.formatDate(days);
            //小时
            let hours = Math.floor(count/3600);
            count -= hours * 3600;
            this.countdown.hours = this.formatDate(hours);
            //分钟
            let mins = Math.floor(count/60);
            count -= mins * 60;
            this.countdown.mins = this.formatDate(mins);
            //秒
            this.countdown.secs = this.formatDate(count);
        },
        formatDate(count){
            return count.toString().length > 1 ? count.toString() : '0'+count;
        },
        onBridgeReady() {
            // eslint-disable-next-line no-undef
            WeixinJSBridge.invoke('getBrandWCPayRequest', self.wechatJsPayParams, function(res){
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                    self.$helper.goPage('/pay-callback/wechat/'+ self.order.order_sn, false, true);
                }else{
                    self.$toast.failed('支付已取消');
                }
            });
        },
        createPay(){
            let self = this;
            //微信环境中支付
            if(this.isWechat && this.payment == 'wechat'){
                self.$http.post('user/create/'+this.payment+'/mp',{order_sn:this.order.order_sn}).then((res)=>{
                    self.wechatJsPayParams = res.data.jspay_params;
                    // eslint-disable-next-line no-undef
                    if (typeof WeixinJSBridge == "undefined") {
                        if (document.addEventListener) {
                            document.addEventListener('WeixinJSBridgeReady', self.onBridgeReady, false);
                        } else if (document.attachEvent) {
                            document.attachEvent('WeixinJSBridgeReady', self.onBridgeReady);
                            document.attachEvent('onWeixinJSBridgeReady', self.onBridgeReady);
                        }
                    } else {
                        self.onBridgeReady();
                    }
                });
            }else{
                self.$http.post('user/create/'+this.payment+'/wap',{order_sn:this.order.order_sn}).then((res)=>{
                    if(self.payment == 'alipay'){
                        document.write(res.data.form)
                    }else{
                        window.location.replace(res.data.url);
                    }
                    
                });
            }
            
        }
    }
};
</script>
<style>
#paypal-button-container>div{
    vertical-align: middle;
}
.payment-item  .check .md-icon.icon-font{
    font-size: 20px;
}
</style>
<style scoped lang='less'>
@import "../assets/reset.less";
.page-wrap{
    
    background: rgba(0, 0, 0, 0.01);
}
.block-item{
    padding: 0.7rem 1rem;
    background: #fff;
    border-radius: 10px;
    position: relative;
    margin-bottom: 1rem;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.04), 0 5px 10px rgba(0, 0, 0, 0.02);
}
.block-item.info{
    border-radius: 0;
    margin-bottom: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    font-size: 16px;
    color: #111;
}
.block-item.info .icon{
    vertical-align: middle;
    display: inline-block;
}
.block-item.info .name{
    font-size: 14px;
}
.block-item .more-view{
    display: block;
    position: absolute;
    right: 0.7rem;
    top: calc(50% - 1rem);
    height: 1rem;
}
.block-item .more-view .md-icon.icon-font{
    font-size: 1rem;
    vertical-align: top;
}

.bottom-box{
    position: fixed;
    bottom: 0;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    left: 0;
    width: 100%;
    padding: 1rem;
    z-index: 10;
}
.bottom-box .md-button.md{
    height: 38px;
    line-height: 38px;
}
.amount{
    padding: 1rem;
    text-align: center;
    font-size: 40px;
    color: @info-color;
}
.amount small{
    margin-right: -10px;
    font-size: 18px;
}

.payments .title{
    position: relative;
    margin-bottom: 1rem;
}
.payments .title::before{
    content: '';
    display: block;
    height: 0.7rem;
    width: 3px;
    border-radius: 3px;
    background: @primary-color;
    position: absolute;
    left: -0.5rem;
    top: 0.3rem;
}


.payment-item{
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.02);
    padding: 0.7rem 0;
}
.payment-item:last-child{
    margin-bottom: 0;
    
    border-bottom: none;
}
.payment-item .label{
    text-align: left;
    flex: 1;
}
.payment-item .check{
    text-align: right;
}
.payment-item .md-radio{
    margin: 0;
}



.countdown{
    font-size: 13px;
    color: #111;
    text-align: center;
}
.order-item{
    display: flex;
    margin-bottom: 1rem;
}
.order-item .lable{
    flex: 1;
    text-align: left;
}
.order-item .content{
    text-align: right;
    flex: 1;
}
.StripeElement{
    padding: 1rem;
    background: rgba(0, 0, 0, 0.02);
    
}
.stripe-error{
    margin-bottom: 1rem;
    font-size: 12px;
    text-align: center;
    color: #f96062;
    margin-top: 0.5rem;
}
</style>
