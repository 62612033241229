<template>
    <div class="page-wrap">
        <div class="top-box">
            <div class="head-box">
                <div class="title">热销软件排行榜</div>
                <div  class="desc">根据销量每日统计并更新</div>
            </div>
            <div class="cates-box">
                <a class="item" :class="{active:0 == ranking_type_id}" @click="selectStatus(0)">总榜</a>
                <a class="item" :class="{active:item.id == ranking_type_id}" v-for="item in listRankingType" :key="item.id"  @click="selectStatus(item.id)">{{item.name}}</a>
            </div>
        </div>
        <div class="emtpy" v-if="products.total <= 0">
            <p><svg t="1631180042906" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16472" width="64" height="64"><path d="M855.6 427.2H168.5c-12.7 0-24.4 6.9-30.6 18L4.4 684.7C1.5 689.9 0 695.8 0 701.8v287.1c0 19.4 15.7 35.1 35.1 35.1H989c19.4 0 35.1-15.7 35.1-35.1V701.8c0-6-1.5-11.8-4.4-17.1L886.2 445.2c-6.2-11.1-17.9-18-30.6-18zM673.4 695.6c-16.5 0-30.8 11.5-34.3 27.7-12.7 58.5-64.8 102.3-127.2 102.3s-114.5-43.8-127.2-102.3c-3.5-16.1-17.8-27.7-34.3-27.7H119c-26.4 0-43.3-28-31.1-51.4l81.7-155.8c6.1-11.6 18-18.8 31.1-18.8h622.4c13 0 25 7.2 31.1 18.8l81.7 155.8c12.2 23.4-4.7 51.4-31.1 51.4H673.4zM819.9 209.5c-1-1.8-2.1-3.7-3.2-5.5-9.8-16.6-31.1-22.2-47.8-12.6L648.5 261c-17 9.8-22.7 31.6-12.6 48.4 0.9 1.4 1.7 2.9 2.5 4.4 9.5 17 31.2 22.8 48 13L807 257.3c16.7-9.7 22.4-31 12.9-47.8zM375.4 261.1L255 191.6c-16.7-9.6-38-4-47.8 12.6-1.1 1.8-2.1 3.6-3.2 5.5-9.5 16.8-3.8 38.1 12.9 47.8L337.3 327c16.9 9.7 38.6 4 48-13.1 0.8-1.5 1.7-2.9 2.5-4.4 10.2-16.8 4.5-38.6-12.4-48.4zM512 239.3h2.5c19.5 0.3 35.5-15.5 35.5-35.1v-139c0-19.3-15.6-34.9-34.8-35.1h-6.4C489.6 30.3 474 46 474 65.2v139c0 19.5 15.9 35.4 35.5 35.1h2.5z" p-id="16473" fill="#bfbfbf"></path></svg></p>
            <p>暂无排行榜记录</p>
        </div>
        <div class="p-list" v-else>
            <div class="p-item" v-for="(item, index) in products.data" :key="index" @click="$helper.goPage('/item/' + item.product.slug)">
                <img :src="$store.state.assetUrl + item.product.img" alt="">
                <div class="info">
                    <div class="name text-ellipsis-2">{{item.product.name}}</div>
                    <div class="rate"><md-tag  shape="circle" type="ghost" font-color="#555">评分 {{ item.product.rate }}星</md-tag></div>
                    <div class="fixed">
                        <div class="price pull-left">
                            <span class="small">{{item.product.currency.code}}</span>{{item.product.currency.amount}}
                        </div>
                        <div class="pull-right">
                           <svg t="1630491127273" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11755" width="1em" height="1em"  fill="currentColor"><path d="M246.4 912a2.1 2.1 0 1 0 134.4 0 2.1 2.1 0 1 0-134.4 0Z" p-id="11756"></path><path d="M716.8 912a2.1 2.1 0 1 0 134.4 0 2.1 2.1 0 1 0-134.4 0Z" p-id="11757"></path><path d="M905.6 764.8l-537.6 0c-28.8 0-57.6-25.6-64-54.4l-96-566.4c-9.6-54.4-60.8-96-115.2-96l-22.4 0c-12.8 0-25.6 12.8-25.6 25.6 0 12.8 12.8 25.6 25.6 25.6l22.4 0c28.8 0 57.6 25.6 64 54.4l96 566.4c9.6 54.4 60.8 96 115.2 96l537.6 0c12.8 0 25.6-12.8 25.6-25.6C931.2 777.6 921.6 764.8 905.6 764.8z" p-id="11758"></path><path d="M880 179.2l-572.8 0c-12.8 0-25.6 12.8-25.6 25.6 0 12.8 12.8 25.6 25.6 25.6l572.8 0c25.6 0 38.4 16 32 41.6l-70.4 281.6c-6.4 32-41.6 57.6-73.6 60.8l-396.8 28.8c-12.8 0-25.6 12.8-22.4 28.8 0 12.8 12.8 25.6 28.8 22.4l396.8-28.8c54.4-3.2 105.6-48 118.4-99.2l70.4-281.6C976 230.4 937.6 179.2 880 179.2z" p-id="11759"></path></svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
    </div>
</template>

<script>

let self;
export default {
    name: 'topic',
    components: {
        
    },
    data(){
        return {
            products:{
                total: 0,
                data:[]
            },
            ranking_type_id: 0,
            listRankingType:[],
        }
    },
    mounted(){
        self = this;
        document.title="排行榜";
        this.$store.dispatch('hideBottomBar');
        this.getRankingType();
        this.getRanking();
    },
    methods:{
        getRankingType(){
            this.$http.get('listRankingType').then((res)=>{
                self.listRankingType = res.data;
            })
        },
        getRanking(){
            var param = {
              ranking_type_id : this.ranking_type_id,
            };
            this.$http.get('listRanking',{params:param}).then((res)=>{
                console.log(res.data);
                self.products = {...res.data};
            });
        },
        selectStatus(id){
            this.ranking_type_id = id;
            this.getRanking();
        }
    }
}
</script>

<style scoped lang='less'>
.emtpy{
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    padding: 100px 0;
}
.page-wrap{
    background: rgba(0, 0, 0, 0.01);
    padding-bottom: 0;
    padding-top: 230px;
}
.head-box{
    background: url(../assets/bg.png) no-repeat center;
    background-size: cover;
    height: 163px;
    margin-bottom: 10px;
    text-align: center;
    padding-top:40px;
}
.head-box .desc{
    display: inline-block;
    background: #fff;
    border-radius: 25px;
    padding: 2px 10px;
    font-size: 14px;
    margin-top: 10px;
    color: #ff5257;
}
.head-box .title{
    font-size: 28px;
    color: #fff;
}

.top-box{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    background: #fff;
}
.cates-box{
    background: #fff;
    padding: 0.7rem;
    display: flex;
    font-size: 13px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    position: relative;
}
.cates-box{
    white-space: nowrap;
    overflow-x: auto;
}
.cates-box .item{
    display: inline-block;
    padding: 4px 10px;
    border-radius: 20px;
    color: rgba(0, 0, 0, 1 );
    background: rgba(0, 0, 0, 0.03);
    border: 1px solid rgba(0, 0, 0, 0);
    margin-right: 8px;
}
.cates-box .item.active{
    background: rgba(255, 88, 90, 0.1);
    color: #ff585a;
    border: 1px solid rgba(255, 88, 90, 1);
}

.p-item{
    padding: 1rem 0.7rem;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.03), 0 5px 10px rgba(0, 0, 0, 0.02), 0 1px 3px rgba(0, 0, 0, 0.01);
    border-radius:10px;
    background: #fff;
    margin: 0.7rem;
    display: flex;
}
.p-item img{
    height: 95px;
}
.p-item .info{
    padding-left: 10px;
    width: calc(100% - 95px);
}
.p-item .info .name{
    line-height: 20px;
    margin-bottom: 5px;
    height: 40px;
}
.p-item .info .rate{
    margin-bottom: 5px;
}
.p-item .price{

    font-size: 16px;
    font-weight: 500;
}
.p-item .price .small{
    font-weight: normal;
}
.p-item .info .pull-right{
    font-size: 18px;
    line-height: 22px;
    padding-top: 3px;
    color: #ff585a;
}
</style>
