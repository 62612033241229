<template>
    <div class="page-wrap">
        <div class="block-item info">
            缴纳保证金后即可参与竞拍！
        </div>
        <div class="container">
            <div class="amount">
                <small>{{order.total_currency.code}}</small> {{order.total_currency.amount}}
            </div>
            <div class="block-item payments">
                <div class="title">选择支付方式</div>

                
                <div class="payment-item" @click="setPayment('alipay')" v-if="!isWechat">
                    <div class="label"><img height="24" src="../assets/alipay.svg" alt=""></div>
                    <div class="check">
                        <md-radio name="alipay" v-model="payment" />
                    </div>
                </div>
                <div class="payment-item" @click="setPayment('wechat')">
                    <div class="label"><img height="25" src="../assets/wechat.svg" alt=""></div>
                    <div class="check">
                        <md-radio name="wechat" v-model="payment" />
                    </div>
                </div>
            </div>
            <div class="bottom-box">
                <md-button type="primary" class="md" round v-if="payment != 'paypal' && payment != 'stripe'" @click="createPay">确认支付</md-button>
            </div>
        </div>
    </div>
</template>

<script>
let self;
export default {
    name: "orderPay",
    components: {},
    data() {
        return {
            orderSn:'',
            isRead:true,
            orderVisible:false,
           
            order:{
                account:{},
                total_currency:{}
            },
            countDate:{
                min1:0,
                min2:0,
                sec1:0,
                sec2:0
            },
            deadline : 0,
            countdown:{
                days:0,
                hours:0,
                mins:0,
                secs:0
            },
            timer:null,
            checkTimer:null,
            payment:'alipay',
            pay_url:'',
            scanVisible:false,
            webVisible :false,
            payExpireVisible:false,
            pay_qrcode : '',
            step:1,
            stripe_error:'',
            isWechat:false,
            wechatJsPayParams:{},
        };
    },
    mounted(){
        document.title = '支付订单';

        self = this;
        this.$store.dispatch('hideBottomBar');

        this.isWechat = this.$helper.isWeChat();
        if(this.isWechat){
            this.payment = 'wechat';
        }

        this.getOrder();
    },
    beforeDestroy(){
        clearInterval(this.timer);
    },
    methods:{
        getOrder(){
            this.$http.get('user/getAuctionDepositOrder').then((res)=>{
                self.order = res.data;
                if(self.order.status == 1 || (self.order.status == 2 && self.order.refund_status == 0)){
                    self.$toast.failed('当前无法缴纳保证金');
                    setTimeout(()=>{
                        self.$router.back();
                    },1000)
                }
            }).finally(()=>{
                
            })
        },
        setPayment(payment){
            this.payment = payment;
        },
        
        onBridgeReady() {
            // eslint-disable-next-line no-undef
            WeixinJSBridge.invoke('getBrandWCPayRequest', self.wechatJsPayParams, function(res){
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                    self.$helper.goPage('/pay-callback/wechat/'+ 'deposit_'+self.order.order_sn, false, true);
                }else{
                    self.$toast.failed('支付已取消');
                }
            });
        },
        createPay(){
            let self = this;
            //微信环境中支付
            if(this.isWechat && this.payment == 'wechat'){
                self.$http.post('user/create/'+this.payment+'/mp',{order_sn:this.order.order_sn,type:200}).then((res)=>{
                    self.wechatJsPayParams = res.data.jspay_params;
                    // eslint-disable-next-line no-undef
                    if (typeof WeixinJSBridge == "undefined") {
                        if (document.addEventListener) {
                            document.addEventListener('WeixinJSBridgeReady', self.onBridgeReady, false);
                        } else if (document.attachEvent) {
                            document.attachEvent('WeixinJSBridgeReady', self.onBridgeReady);
                            document.attachEvent('onWeixinJSBridgeReady', self.onBridgeReady);
                        }
                    } else {
                        self.onBridgeReady();
                    }
                });
            }else{
                self.$http.post('user/create/'+this.payment+'/wap',{order_sn:this.order.order_sn,type:200}).then((res)=>{
                    if(self.payment == 'alipay'){
                        document.write(res.data.form)
                    }else{
                        window.location.replace(res.data.url);
                    }
                });
            }
            
        }
    }
};
</script>
<style>
#paypal-button-container>div{
    vertical-align: middle;
}
</style>
<style scoped lang='less'>
@import "../assets/reset.less";
.page-wrap{
    
    background: rgba(0, 0, 0, 0.01);
}
.block-item{
    padding: 0.7rem 1rem;
    background: #fff;
    border-radius: 10px;
    position: relative;
    margin-bottom: 1rem;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.04), 0 5px 10px rgba(0, 0, 0, 0.02);
}
.block-item.info{
    border-radius: 0;
    margin-bottom: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    font-size: 16px;
    color: #111;
}
.block-item.info .icon{
    vertical-align: middle;
    display: inline-block;
}
.block-item.info .name{
    font-size: 14px;
}
.block-item .more-view{
    display: block;
    position: absolute;
    right: 0.7rem;
    top: calc(50% - 1rem);
    height: 1rem;
}
.block-item .more-view .md-icon.icon-font{
    font-size: 1rem;
    vertical-align: top;
}

.bottom-box{
    position: fixed;
    bottom: 0;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    left: 0;
    width: 100%;
    padding: 1rem;
    z-index: 10;
}
.bottom-box .md-button.md{
    height: 38px;
    line-height: 38px;
}
.amount{
    padding: 1rem;
    text-align: center;
    font-size: 40px;
    color: @info-color;
}
.amount small{
    margin-right: -10px;
    font-size: 18px;
}

.payments .title{
    position: relative;
    margin-bottom: 1rem;
}
.payments .title::before{
    content: '';
    display: block;
    height: 0.7rem;
    width: 3px;
    border-radius: 3px;
    background: @primary-color;
    position: absolute;
    left: -0.5rem;
    top: 0.3rem;
}


.payment-item{
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.02);
    padding: 0.7rem 0;
}
.payment-item:last-child{
    margin-bottom: 0;
    
    border-bottom: none;
}
.payment-item .label{
    text-align: left;
    flex: 1;
}
.payment-item .check{
    text-align: right;
}
.payment-item .md-radio{
    margin: 0;
}
.payment-item  .check .md-icon.icon-font{
    font-size: 22px;
}
.md-radio.is-checked .md-radio-icon{
    color: @primary-color;
}


.countdown{
    font-size: 13px;
    color: #111;
    text-align: center;
}
.order-item{
    display: flex;
    margin-bottom: 1rem;
}
.order-item .lable{
    flex: 1;
    text-align: left;
}
.order-item .content{
    text-align: right;
    flex: 1;
}
.StripeElement{
    padding: 1rem;
    background: rgba(0, 0, 0, 0.02);
    
}
.stripe-error{
    margin-bottom: 1rem;
    font-size: 12px;
    text-align: center;
    color: #f96062;
    margin-top: 0.5rem;
}
</style>
