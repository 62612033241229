<template>
    <div class="block-item">
        <div class="title"  @click="showQuestions">
            <span>问答</span>
            <span class="more-view">
            {{ questions.total }}条
            <md-icon name="arrow-right" size="small"></md-icon>
            </span>
        </div>
        <div v-if="questions.total <= 0" class="text-center">暂无问答</div>
        <div v-else>
            <div class="qa-item single" >
                <div class="q text-ellipsis"><span class="label">问</span> {{questions.data[0].content}}</div>
                <div class="a text-ellipsis">
                    <span class="label">答</span>
                    <span v-if="questions.data[0].answer">{{questions.data[0].answer.content_desc}}</span>
                </div>
            </div>
            <div class="qa-btns">
                <md-button plain round class="md" @click="showQuestionForm">我要提问</md-button>
                <md-button plain round class="md" @click="showQuestions">查看更多问答</md-button>
            </div>
        </div>

        <md-popup v-model="questionsVisible" position="bottom" class="questions-pop">
        <div class="block-title">
            <span>问答列表</span>
            <a @click="questionsVisible = false" class="close"><md-icon name="close"></md-icon></a>
        </div>
        
        <div class="questions-panel">
            <div class="qa-item" v-for="item in questions.data" :key="item.id">
                <a class="more-view"><md-icon name="arrow-right"></md-icon></a>
                <div class="like-box" @click="likeQuestion(item.id)">
                    <span class="icon">
                    <svg t="1631517914571" viewBox="0 0 1024 1024" fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2165" width="22" height="22"><path d="M871.9 402.4c0-120.2-97.4-217.6-217.6-217.6-55.8 0-106.6 21.2-145.1 55.7-38.5-34.5-89.3-55.7-145.1-55.7-120.2 0-217.6 97.4-217.6 217.6 0 44.2 13.3 85.2 35.9 119.6h-0.4c75.3 127 181 233.7 306.7 311 6.2 2.9 13.2 4.6 20.5 4.6 7.4 0 14.3-1.7 20.5-4.6 125.7-77.3 231.4-184 306.7-311h-0.4c22.6-34.3 35.9-75.4 35.9-119.6z" p-id="2166" ></path></svg>
                    </span>
                    {{item.like_count}}
                </div>
                <div class="info" @click="showQuestion(item)">
                    <div class="q text-ellipsis"><span class="label">问</span> {{item.content}}</div>
                    <div class="a text-ellipsis">
                        <span class="label">答</span>
                        <span v-if="item.answer">{{item.answer.content_desc}} </span>
                    </div>
                    
                </div>
            </div>
      </div>
    </md-popup>

    <md-landscape v-model="questionVisible" full-screen>
        <div class="question-detail">
            <div class="title text-center">{{question.content}}</div>
            <div class="content" v-if="question.answer" v-html="question.answer.content"></div>
        </div>
    </md-landscape>
    <md-popup v-model="questionFormVisible" position="bottom">
        <div class="question-form">
            <div class="block-title">
                <span>我要提问</span>
                <a @click="questionFormVisible = false" class="close"><md-icon name="close"></md-icon></a>
            </div>
            <md-textarea-item
                ref="demo2"
                class="demo2"
                :autosize="true"
                v-model="questionForm.content"
                :max-length="120"
                :max-height="150"
                placeholder="问题内容在120字以内"
            >
                <template slot="footer">
                    <div class="text-right" >{{ questionForm.content.length }}/120</div>
                </template>
            </md-textarea-item>

            <div class="mt-1">
                <md-button type="primary" round  class="mt-2 md" @click="submitQuestion">提交</md-button>
            </div>
        </div>
    </md-popup>
    </div>
</template>

<script>
let self;
export default {
    name: "comments",
    components: {},
    props:{
        pid:{
            type: Number,
            default:0
        },
        rateInfo:{
            type:Object,
            default:null
        }
    },
    data() {
        return {
            questionVisible:false,
            questionsVisible:false,
            questionFormVisible: false,
            question:{
                answer:{}
            },
            questionForm: {
                content:''
            },
            questions: {
                data:[{content:'',answer:{}}],
                
            },
            questionPage: 1,
            isLoading: false,
            isNoMore: false,
        };
    },
    watch:{
        pid(val){
            this.productId = val
            this.getQuestions()
        }
    },
    mounted(){
        self = this;
        this.productId = this.pid
        this.getQuestions();
    },
    beforeDestroy(){
        
    },
    methods:{
        showQuestions(){
            this.questionsVisible = true;
        },
        showQuestion(item){
            this.question = item;
            this.questionVisible = true;
        },
        getQuestions() {
            if(!this.productId){
                return
            }
            this.$http.get("listQuestions", { params: { id: this.productId }, showLoading:false }).then((res) => {
                self.questions = res.data;
            });
        },
        showQuestionForm() {
            if (!this.$store.state.isLogin) {
                this.$router.push("/login");
                return;
            }
            this.questionForm = {
                product_id: this.productId,
                content:''
            };
            this.questionFormVisible = true;
        },
        submitQuestion() {
            this.$http.post("user/question", this.questionForm).then((res) => {
                this.$toast.succeed(res.msg);
                self.questionForm = {
                    content:''
                };
                self.questionFormVisible = false;
            });
        },
        likeQuestion(id) {
            this.$http.post("user/likeQuestion", { id: id }).then((res) => {
                self.questions.data.map((v) => {
                if (v.id == id) {
                    v.like_count++;
                }
                });
                self.$toast.succeed(res.msg);
            });
        },
    }
};
</script>

<style scoped lang='less' >
@import "../../assets/reset.less";
.block-item {
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.04), 0 5px 10px rgba(0, 0, 0, 0.02);
  margin: 1rem 0.7rem;
  padding: 1rem 0.7rem;
  background: #fff;
}
.block-item>.title {
  position: relative;
  margin-bottom: 1rem;
}
.block-item>.title::before {
  content: "";
  display: block;
  position: absolute;
  height: 10px;
  width: 3px;
  background: @primary-color;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.01);
  left: -6px;
  top: 5px;
  border-radius: 2px;
}
.block-item>.title > span:first-child {
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 15px;
}
.block-item>.title .more-view {
  position: absolute;
  right: -4px;
  top: 0;
  font-size: 12px;
  color: #999;
  display: flex;
  line-height: 20px;
}
.block-item>.title .more-view .md-icon {
  font-size: 14px;
  line-height: 20px;
}

.question-detail{
    padding: 3rem 1rem 1rem 1rem;
    font-size: 13px;
}
.question-detail .title{
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    font-weight: bold;
}
.question-detail .content{
    margin-bottom: 1rem;
    margin-top: 0.5rem;
}
.questions-panel {
  max-height: calc(100% - 40px);
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 130px;
  margin:0 -1rem;
  padding: 1rem;
}
.qa-item {
  font-size: 13px;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  position: relative;
  display: flex;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05), 0 5px 10px rgba(0, 0, 0, 0.02), 0 1px 3px rgba(0, 0, 0, 0.01);
  border-radius:10px;
  padding: 1rem 0.7rem;
}
.qa-item .like-box{
    width: 30px;
    text-align: center;
    border-radius: 5px;
    line-height: 10px;
    padding-top: 9px;
}
.qa-item .like-box .icon{
    color: @error-color;
}
.qa-item .info{
    width: calc(100% - 50px);
    padding-left: 10px;
    font-size:13px
}
.qa-item .more-view{
    position: absolute;
    right: 0.3rem;
    top: 2rem;
}
.qa-btns{
    display: flex;
}
.qa-btns .md-button:first-child{
    margin-right: 5px;
}
.qa-item.single{
    display: block;
    box-shadow:none;
    padding:0;
}
.qa-item.single{
    padding-bottom: 0;
}
.qa-item .label {
  height: 18px;
  width: 18px;
  font-size: 12px;
  color: #fff;
  display: inline-block;
  text-align: center;
  line-height: 18px;
  margin-right: 0.5rem;
}
.qa-item .q {
  margin-bottom: 0.7rem;
}
.qa-item .q .label {
  background: url(../../assets/q-bg.png) no-repeat center / cover;
}
.qa-item .a .label {
  background: url(../../assets/q-bg1.png) no-repeat center / cover;
}
</style>
