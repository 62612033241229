<template>
  <div >
    <div class="item-box"  @click="couponsVisible = true">
      <div class="label">优惠</div>
      <div class="content">
        <i class="icon-coupon">
          <svg
            t="1630548869440"
            class="icon"
            viewBox="0 0 1024 1024"
            fill="currentColor"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="16451"
            width="14"
            height="14"
          >
            <path
              d="M923.648 234.2912L169.3696 92.5696c-49.152-9.216-96.8704 23.3472-106.0864 72.4992L48.128 245.76h905.6256c-9.0112-5.5296-19.2512-9.4208-30.1056-11.4688zM169.3696 57.7536l593.1008 111.4112L206.2336 3.4816c-41.5744-12.4928-85.8112 11.4688-98.304 53.0432l-4.096 13.7216c18.8416-11.8784 41.984-16.9984 65.536-12.4928zM1024 534.528v-162.2016c0-53.8624-44.032-97.8944-97.8944-97.8944H97.8944C44.032 274.432 0 318.464 0 372.3264V534.528h2.048c63.2832 0 114.688 51.4048 114.688 114.688s-51.4048 114.688-114.688 114.688H0v162.2016C0 979.968 44.032 1024 97.8944 1024h828.0064c53.8624 0 97.8944-44.032 97.8944-97.8944V763.904c-63.2832 0-114.688-51.4048-114.688-114.688 0.2048-63.2832 51.6096-114.688 114.8928-114.688z m-323.584 67.584c0 11.264-9.0112 20.2752-20.2752 20.2752h-83.5584c-11.264 0-20.2752 9.0112-20.2752 20.2752v25.8048c0 11.264 9.0112 20.2752 20.2752 20.2752h83.5584c11.264 0 20.2752 9.0112 20.2752 20.2752v4.3008c0 11.264-9.0112 20.2752-20.2752 20.2752h-83.5584c-11.264 0-20.2752 9.0112-20.2752 20.2752v63.6928c0 11.264-9.0112 20.2752-20.2752 20.2752h-45.2608c-11.264 0-20.2752-9.0112-20.2752-20.2752v-63.6928c0-11.264-9.0112-20.2752-20.2752-20.2752h-84.1728c-11.264 0-20.2752-9.0112-20.2752-20.2752v-4.3008c0-11.264 9.0112-20.2752 20.2752-20.2752h84.1728c11.264 0 20.2752-9.0112 20.2752-20.2752v-25.8048c0-11.264-9.0112-20.2752-20.2752-20.2752h-84.1728c-11.264 0-20.2752-9.0112-20.2752-20.2752v-4.3008c0-11.264 9.0112-20.2752 20.2752-20.2752h37.6832c16.384 0 26.0096-18.6368 16.5888-31.9488l-32.768-46.08c-9.6256-13.312 0-31.9488 16.5888-31.9488h36.0448c6.5536 0 12.6976 3.072 16.5888 8.3968l40.3456 56.5248c8.192 11.264 24.9856 11.264 32.9728 0l39.7312-56.32c3.8912-5.3248 10.0352-8.6016 16.5888-8.6016h35.84c16.5888 0 26.0096 18.6368 16.384 32.1536l-32.9728 45.8752c-9.6256 13.312 0 32.1536 16.384 32.1536h38.0928c11.264 0 20.2752 9.0112 20.2752 20.2752v4.096z"
              p-id="16452"
            ></path>
          </svg>
        </i>
        领券购买更优惠 · 当前可领{{ coupons.length }}张
      </div>
      <md-icon name="arrow-right" size="small"></md-icon>
    </div>

    <md-popup v-model="couponsVisible" position="bottom" class="coupon-pop">
      <div class="block-title">
        <span>领券中心</span>
        <a @click="couponsVisible = false" class="close"><md-icon name="close"></md-icon></a>
      </div>
      <div class="coupons-panel">
        <div class="coupon-code-box" style="margin-bottom: 2rem">
          <md-input-item
            v-model="couponCode"
            title="输入优惠券码"
            placeholder="优惠券码"
            size="small"
            is-title-latent
          >
            <div class="receive-btn" slot="right" @click="receiveCouponCode">
              <md-button type="blue" round class="small">领取</md-button>
            </div>
          </md-input-item>
        </div>
        <div class="coupon-item" v-for="item in coupons" :key="item.id" @click="showCoupon(item)">
            <div class="top">
                <div class="money">
                    <span class="value">{{ item.value }}</span>
                    <span class="small" v-if="item.value_type == 0">￥</span>
                    <span class="small" v-if="item.value_type == 1">%</span>
                    <div>
                        <span class="small" v-if="item.type == 0">代金券</span>
                        <span class="small" v-if="item.type == 1">满{{ item.value_limit }}元可用</span>
                    </div>
                </div>

                <div class="info">
                    <div class="title text-ellipsis">{{ item.title }}</div>
                    <div class="limit">
                        <div class="small" v-if="item.use_range > 0">部分商品可用</div>
                        <div class="small" v-if="item.use_range == 0">所有商品可用</div>
                        <div class="small" v-if="item.price_points > 0">兑换需要{{item.price_points}}积分</div>
                        <div class="small" v-if="item.price_points == 0">免费领取</div>
                    </div>
                    <img src="https://www.aiviy.com/assets/imgs/discount-coupon.svg" class="tip-icon">
                </div>
            </div>
            <div class="bottom">
                <div>
                    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z">
                        </path>
                    </svg>
                    <span v-if="item.receive_count_limit > 0">每人限量x{{ item.receive_count_limit }}</span>
                    <span v-else>不限量</span>
                </div>
                <div>
                    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                    <span>{{item.end_at}}</span>
                </div>
            </div>
        </div>
      </div>
    </md-popup>

    <md-landscape v-model="couponVisible">
      <div class="coupon-info">
            <div class="money">
            <span class="value">{{ coupon.value }}</span>
            <span class="small" v-if="coupon.value_type == 0">￥</span>
            <span class="small" v-if="coupon.value_type == 1">%</span>
            </div>
            <div class="text-center title">{{ coupon.title }}</div>
            <div class="bill-box with-head small">
                <div class="item">
                    <div class="label">使用条件</div>
                    <div class="value">
                        <span v-if="coupon.type == 0" >无条件</span>
                        <span v-if="coupon.type == 1" >满{{ coupon.value_limit }}元</span>
                    </div>
                </div>
                <div class="item">
                    <div class="label">使用范围</div>
                    <div class="value">
                        <span v-if="coupon.use_range == 0" >所有商品</span>
                        <span v-if="coupon.use_range == 1" >部分商品</span>
                    </div>
                </div>
                <div class="item">
                    <div class="label">限量</div>
                    <div class="value">
                        <span v-if="coupon.receive_count_limit > 0" >每人可领{{ coupon.receive_count_limit }}次</span>
                        <span v-else >无</span>
                    </div>
                </div>
                <div class="item">
                    <div class="label">售价</div>
                    <div class="value color-danger">
                        <span v-if="coupon.price_points == 0">免费领取</span>
                        <span v-else>{{coupon.price_points}}积分</span>
                    </div>
                </div>
            </div>
            <h5 style="margin: 1rem 0 0.5rem 0">使用规则</h5>
            <div style="line-height: 2" class="small">
                <p class="wrap-pre">{{ coupon.desc }}</p>
                <div v-if="coupon.use_range > 0">
                    <div v-if="coupon.products.length > 0">
                    <p>以下商品{{ coupon.use_range == 1 ? "可用" : "不可用" }}：</p>
                    <p v-for="record in coupon.products" :key="record.id">
                        《{{ record.product.name }}》
                    </p>
                    </div>
                    <div v-if="coupon.categories.length > 0">
                    <p>以下类目{{ coupon.use_range == 1 ? "可用" : "不可用" }}：</p>
                    <span v-for="record in coupon.categories" :key="record.id"
                        >《{{ record.category.name }}》</span
                    >
                    </div>
                </div>
                <p class="">领取时间：{{ coupon.start_at }} ~ {{ coupon.end_at }}</p>
            </div>
            <md-button type="primary" round @click="receiveCoupon(coupon.id)" class="mt-1 md" >确认领取</md-button>
        </div>
    </md-landscape>

  </div>
</template>

<script>
let self;
export default {
    name: "tpl",
    components: {},
    props:{
      pid:{
            type: Number,
            default:0
        },
    },
    data() {
        return {
            coupons:{},
            couponsVisible: false,
            couponVisible: false,
            coupon: {},
            couponCode: "",
        };
    },
    watch:{
      pid(val){
          this.productId = val
          this.getCoupons()
      }
    },
    mounted(){
      self = this;
      this.productId = this.pid
      self.getCoupons();
    },
    beforeDestroy(){
        
    },
    methods:{
      getCoupons(){
        if(!this.productId){
          return
        }
        this.$http.get("listCoupons", { params:{product_id:this.productId}, showLoading:false }).then((res) => {
          self.coupons = res.data
        });
      },
      showCoupon(item) {
          this.coupon = item;
          this.couponVisible = true;
          this.$http.get('getCoupon',{params:{id:item.id}}).then((res)=>{
              self.coupon = res.data;
          });
      },
      receiveCouponCode() {
          if(!this.$store.state.isLogin){
              this.$helper.goPage('/login');
              return;
          }
          this.$http.post("user/receiveCoupon", { key: this.couponCode, type: "code" }).then((res) => {
              self.$toast.succeed(res.msg);
              self.couponCode = "";
          });
      },
      receiveCoupon(id) {
          if(!this.$store.state.isLogin){
              this.$helper.goPage('/login');
              return;
          }
          this.$http.post("user/receiveCoupon", { key: id, type: "id" }).then((res) => {
              self.$toast.succeed(res.msg);
              self.couponVisible = false;
          });
      }, 
    }
};
</script>
<style>
.coupon-pop .md-popup-box{
    padding: 0
}
</style>
<style scoped lang='less' >
@import "../../assets/reset.less";

.item-box {
  font-size: 13px;
  display: flex;
  margin-bottom: 1.3rem;
  position: relative;
}
.item-box:last-child {
  margin-bottom: 0.5rem;
}
.item-box .label {
  color: rgba(0, 0, 0, 1);
  font-weight: 500;
  flex: 2;
  line-height: 16px;
}
.item-box .content {
  color: #111;
  flex: 12;

  line-height: 16px;
}
.item-box .md-icon {
  position: absolute;
  content: "";
  display: block;
  font-size: 12px;
  font-weight: bold;
  right: -4px;
  top: 2px;
  line-height: 14px;
}
.item-box.specs span {
  display: inline-block;
  background: @primary-color;
  color: #fff;
  border-radius: 3px;
  padding: 2px 5px;
}
.item-box.specs .content::after {
  display: none;
}

.item-box .icon-coupon {
  display: inline-block;
  vertical-align: middle;
  color: @error-color;
}
.coupons-pop .md-popup-box {
  width: 100%;
}



.coupon-code-box .receive-btn{
    width: 80px;
}
.coupon-item {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-left: 0;
  margin: 0.7rem 0;
  border-radius: 10px;
   box-shadow: 0 0 30px rgba(0, 0, 0, 0.05), 0 5px 10px rgba(0, 0, 0, 0.02), 0 1px 3px rgba(0, 0, 0, 0.01);
  overflow: hidden;
}
.coupon-pop .block-title{
    padding: 1rem;
    margin-bottom: 0;
}
.coupon-pop .block-title .close{
    right: 0.7rem;
    top: 10px;
}
.coupon-pop .coupons-panel{
    padding: 0 1rem 1rem 1rem;
}
.coupon-item .top{
    display: flex;
}
.coupon-item .bottom{
    background: @primary-color;
    color: #fff;
    display: flex;
    font-size: 12px;
    padding: 0.3rem 0.7rem;
}
.coupon-item .bottom>div{
    flex: 1;
    line-height: 20px;
}
.coupon-item .bottom>div span{
    vertical-align: middle;
    display: inline-block;
}
.coupon-item .bottom div:last-child{
    text-align: right;
}
.coupon-item .bottom svg{
    height: 20px;
    vertical-align: middle;
    margin-right: 5px;
}
.coupon-item .money {
  color: @primary-color;
  text-align: center;
  height: 70px;
  width: 120px;
  font-weight: 500;
  padding-top: 0.5rem;
}
.coupon-item .money .value {
  font-size: 32px;
  line-height: 1;
}
.coupon-item .info {
  padding: 0.5rem 1rem;
  width: calc(100% - 120px);
  font-size: 12px;
  position: relative;
}
.coupon-item .info .tip-icon{
    position: absolute;
    right: 0.7rem;
    top: 1rem;
    height: 40px;
}
.coupon-item .info .limit{
    color: rgba(0, 0, 0, 0.6);
    line-height: 1.4;
}
.coupon-item .info .limit-count{
    color: #999;
}
.coupon-item .info .title{
    font-size: 14px;
    color: #000;
}


.coupon-info {
  background: #fff;
  border-radius: 15px;
  padding: 1rem;
}
.coupon-info .title {
  margin-bottom: 1rem;
}
.coupon-info .money {
  font-size: 32px;
  text-align: center;
}
.coupon-info .md-tag {
  margin-right: 5px;
}

.coupons-panel {
  max-height: calc(100% - 40px);
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
