<template>
    <div class="page-wrap">
        <div class="coupon-code-box">
            <md-input-item
            v-model="couponCode"
            title="输入优惠券码"
            placeholder="我有优惠券码"
            size="small"
            is-title-latent
            >
            <div class="receive-btn" slot="right" @click="receiveCouponCode">
                <md-button type="blue" round class="small">领取</md-button>
            </div>
            </md-input-item>
        </div>
        <div class="container">
            
            
            <div class="coupon-item" v-for="item in coupons" :key="item.id" @click="showCoupon(item)">
                <div class="top">
                    <div class="money">
                        <span class="value">{{ item.value }}</span>
                        <span class="small" v-if="item.value_type == 0">￥</span>
                        <span class="small" v-if="item.value_type == 1">%</span>
                        <div>
                            <span class="small" v-if="item.type == 0">代金券</span>
                            <span class="small" v-if="item.type == 1">满{{ item.value_limit }}元可用</span>
                        </div>
                    </div>

                    <div class="info">
                        <div class="title text-ellipsis">{{ item.title }}</div>
                        <div class="limit">
                            <div class="small" v-if="item.use_range > 0">部分商品可用</div>
                            <div class="small" v-if="item.use_range == 0">所有商品可用</div>
                            <div class="small" v-if="item.price_points > 0">兑换需要{{item.price_points}}积分</div>
                            <div class="small" v-if="item.price_points == 0">免费领取</div>
                        </div>
                        <img src="https://www.aiviy.com/assets/imgs/discount-coupon.svg" class="tip-icon">
                    </div>
                </div>
                <div class="bottom">
                    <div>
                        <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" >
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z">
                            </path>
                        </svg>
                        <span v-if="item.receive_count_limit > 0">每人限量x{{ item.receive_count_limit }}</span>
                        <span v-else>不限量</span>
                    </div>
                    <div>
                        <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                        <span>{{item.end_at}}</span>
                    </div>
                </div>
            </div>
        </div>

        <md-landscape v-model="couponVisible">
            <div class="coupon-info">
                <div class="money">
                <span class="value">{{ coupon.value }}</span>
                <span class="small" v-if="coupon.value_type == 0">￥</span>
                <span class="small" v-if="coupon.value_type == 1">%</span>
                </div>
                <div class="text-center title">{{ coupon.title }}</div>
                <div class="bill-box with-head small">
                    <div class="item">
                        <div class="label">使用条件</div>
                        <div class="value">
                            <span v-if="coupon.type == 0" >无条件</span>
                            <span v-if="coupon.type == 1" >满{{ coupon.value_limit }}元</span>
                        </div>
                    </div>
                    <div class="item">
                        <div class="label">使用范围</div>
                        <div class="value">
                            <span v-if="coupon.use_range == 0" >所有商品</span>
                            <span v-if="coupon.use_range == 1" >部分商品</span>
                        </div>
                    </div>
                    <div class="item">
                        <div class="label">限量</div>
                        <div class="value">
                            <span v-if="coupon.receive_count_limit > 0" >每人可领{{ coupon.receive_count_limit }}次</span>
                            <span v-else >无</span>
                        </div>
                    </div>
                    <div class="item">
                        <div class="label">售价</div>
                        <div class="value color-danger">
                            免费领取
                        </div>
                    </div>
                </div>
                <h5 style="margin: 1rem 0 0.5rem 0">使用规则</h5>
                <div style="line-height: 2" class="small">
                    <p class="wrap-pre">{{ coupon.desc }}</p>
                    <div v-if="coupon.use_range > 0">
                        <div v-if="coupon.products.length > 0">
                        <p>以下商品{{ coupon.use_range == 1 ? "可用" : "不可用" }}：</p>
                        <p v-for="record in coupon.products" :key="record.id">
                            《{{ record.product.name }}》
                        </p>
                        </div>
                        <div v-if="coupon.categories.length > 0">
                        <p>以下类目{{ coupon.use_range == 1 ? "可用" : "不可用" }}：</p>
                        <span v-for="record in coupon.categories" :key="record.id"
                            >《{{ record.category.name }}》</span
                        >
                        </div>
                    </div>
                    <p class="">领取时间：{{ coupon.start_at }} ~ {{ coupon.end_at }}</p>
                </div>
                <md-button type="primary" round @click="receiveCoupon(coupon.id)" class="mt-1 md" >确认领取</md-button>
            </div>
        </md-landscape>
    </div>
</template>

<script>
let self;
export default {
    name: "coupons",
    components: {},
    data() {
        return {
            coupons: [],
            couponsVisible: false,
            couponVisible: false,
            coupon: {},
            couponCode: "",
            page:1
        };
    },
    mounted(){
        self = this;
        this.getCoupons();
        this.$store.dispatch('hideBottomBar');
        document.title = '领券中心'
    },
    beforeDestroy(){
        
    },
    methods:{
        getCoupons(){
           
            this.$http.get('listCoupons').then((res)=>{
                let coupons = [];
                res.data.map((v)=>{
                    if(v.price_points <= 0){
                        coupons.push(v);
                    }
                })
                self.coupons = coupons;
            });
        },
        showCoupon(item) {

            this.couponVisible = true;
           
            this.$http.get('getCoupon',{params:{id:item.id}}).then((res)=>{
                self.coupon = res.data;
            });
        },
        receiveCouponCode() {
            this.$http.post("user/receiveCoupon", { key: this.couponCode, type: "code" }).then((res) => {
                self.$toast.succeed(res.msg);
                self.couponCode = "";
            });
        },
        receiveCoupon(id) {
            if(!this.$store.state.isLogin){
                this.$helper.goPage('/login');
                return;
            }
            this.$http.post("user/receiveCoupon", { key: id, type: "id" }).then((res) => {
                self.$toast.succeed(res.msg);
                self.couponVisible = false;
            });
        },
    }
};
</script>

<style scoped lang='less' >
@import "../assets/reset.less";
.page-wrap{
    background: rgba(0, 0, 0, 0.01);
}
.coupon-code-box{
    background: #fff;
    padding: 0.7rem;
}
.coupon-code-box .receive-btn{
    width: 80px;
}
.coupon-item {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-left: 0;
  margin: 0.7rem 0;
  border-radius: 10px;
   box-shadow: 0 0 30px rgba(0, 0, 0, 0.05), 0 5px 10px rgba(0, 0, 0, 0.02), 0 1px 3px rgba(0, 0, 0, 0.01);
  overflow: hidden;
}
.coupon-pop .block-title{
    padding: 1rem;
    margin-bottom: 0;
}
.coupon-pop .block-title .close{
    right: 0.7rem;
    top: 10px;
}
.coupon-pop .coupons-panel{
    padding: 0 1rem 1rem 1rem;
}
.coupon-item .top{
    display: flex;
}
.coupon-item .bottom{
    background: @primary-color;
    color: #fff;
    display: flex;
    font-size: 12px;
    padding: 0.3rem 0.7rem;
}
.coupon-item .bottom>div{
    flex: 1;
    line-height: 20px;
}
.coupon-item .bottom>div span{
    vertical-align: middle;
    display: inline-block;
}
.coupon-item .bottom div:last-child{
    text-align: right;
}
.coupon-item .bottom svg{
    height: 20px;
    vertical-align: middle;
    margin-right: 5px;
}
.coupon-item .money {
  color: rgb(36, 69, 131)@primary-color;
  text-align: center;
  height: 70px;
  width: 120px;
  font-weight: 500;
  padding-top: 0.5rem;
}
.coupon-item .money .value {
  font-size: 32px;
  line-height: 1;
}
.coupon-item .info {
  padding: 0.5rem 1rem;
  width: calc(100% - 120px);
  font-size: 12px;
  position: relative;
}
.coupon-item .info .tip-icon{
    position: absolute;
    right: 0.7rem;
    top: 1rem;
    height: 40px;
}
.coupon-item .info .limit{
    color: rgba(0, 0, 0, 0.6);
    line-height: 1.4;
}
.coupon-item .info .limit-count{
    color: #999;
}
.coupon-item .info .title{
    font-size: 14px;
    color: #000;
}


.coupon-info {
  background: #fff;
  border-radius: 15px;
  padding: 1rem;
}
.coupon-info .title {
  margin-bottom: 1rem;
}
.coupon-info .money {
  font-size: 32px;
  text-align: center;
}
.coupon-info .md-tag {
  margin: 5px;
}
</style>
