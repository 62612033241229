<template>
  <div class="page-wrap">
    <div class="stat-box">
        <div class="valid-box">
            <div class="item">
                <div class="count">
                    {{user.commission}}
                </div>
                <div><span class="tag">现有佣金</span></div>
            </div>
            <div class="item">
                <div class="count">
                    {{user.frozen_commission}}
                </div>
                <div><span class="tag">提现中佣金</span></div>
            </div>
            <div class="item">
                <div class="count">
                    {{user.total_commission}}
                </div>
                <div><span class="tag">累计佣金</span></div>
            </div>
        </div>

    </div>

    <div class="log-item" v-for="item in logs.data" :key="item.id">
        <div class="date fixed">
            <div class="pull-left">{{item.created_at}}</div>
            <div class="pull-right">
                <md-tag  shape="circle" v-if="item.status == 1" type="ghost" font-color="#25c6fc">申请中</md-tag>
                <md-tag  shape="circle" v-if="item.status == -1" type="ghost" font-color="#ff5257">已拒绝</md-tag>
                <md-tag  shape="circle" v-if="item.status == 3" type="ghost" font-color="#37e492">已通过</md-tag>
            </div>
        </div>
        <div class="info">
            <div class="content">
                <div>账户类型：{{item.type == 1? '支付宝':'微信'}}</div>
                <div>真实姓名：{{item.real_name}}</div>
            </div>
            <div class="commission">
                <div class="value"><span class="small">￥</span>{{item.commission}}</div>
                <div class="label">提现金额</div>
            </div>
        </div>
        <div class="desc">
            {{item.remark}}
        </div>
    </div>
    <div class="load-more" v-if="isLoading">正在努力加载中...</div>
    <div class="load-more" v-if="isNoMore">全部加载完了</div>

    <md-popup v-model="wcVisible" position="bottom" class="wc-pop" >
        <div class="account">
            <div class="block-title">
                <span>{{wcForm.type == 1?'支付宝':'微信'}}设置</span>
                <a @click="wcVisible = false" class="close"><md-icon name="close"></md-icon></a>
            </div>
            <md-cell-item  addon="上传收款码" arrow @click="$refs.picker.click()">
                <img slot="left" :src="$store.state.assetUrl + wcForm.qr_code_url" class="qrcode-img" alt="">
                <input type="file" class="hide" @change="pickUp" ref="picker">
            </md-cell-item>
            <md-input-item
                preview-type="text"
                v-model="wcForm.name"
                title="真实姓名"
                placeholder="真实姓名"
                is-title-latent
            >
                <span slot="right"></span>
            </md-input-item>
            <div class="mt-1">
                <md-button type="primary" round class="md" @click="saveWc">保存</md-button>
            </div>
        </div>
    </md-popup>

    <md-popup v-model="visible" position="bottom" class="wc-pop" >
        <div class="account">
            <div class="block-title">
                <span>提现</span>
                <a @click="visible = false" class="close"><md-icon name="close"></md-icon></a>
            </div>
            <div class="form-label">提现金额：</div>
            <md-stepper class="price-stepper" v-model="form.commission" :step="1" :min="1" :max="user.commission"></md-stepper>
            <div class="form-tips">当前最多可提现:<span class="small">￥</span>{{user.commission}}</div>

            <div class="form-label">收款方式：</div>
            <md-radio-list :options="wcOptions" v-model="form.type" @change="changeType" ></md-radio-list>

            <div class="notify-tips">
                 注意：
                <p>1、请准确填写提现金额及收款二维码，如有误可能会被驳回。</p>
                <p>2、提现申请后将在1~3个工作日内到账，申请期间《提现金额》将会被冻结。</p>
                <p>3、提现成功或失败冻结金额都会解冻。</p>
            </div>
            <div class="mt-1">
                <md-button type="primary" round class="md" @click="withdrawal">提交申请</md-button>
            </div>
        </div>
    </md-popup>
    <div class="bottom-box">
        <md-button type="primary" round class="md" @click="showCommissionModal">我要提现</md-button>
        <md-button type="default" round class="md" @click="showCwModal(1)">支付宝设置</md-button>
        <md-button type="default" round class="md" @click="showCwModal(2)">微信设置</md-button>
    </div>

  </div>
</template>

<script>
let self;
export default {
    name: "commission",
    components: {},
    data() {
        return {
            logs: {},
            page:1,
            isLoading:false,
            isNoMore:false,
            form: {
                "type": 1,
                'qr_code_url': ''
            },
            visible: false,
            wcVisible:false,
            wcForm:{},
            wcInfo:{},
            wcOptions:[],
            user:{}
        };
    },
    mounted(){
        self = this;
        
        this.$store.dispatch('hideBottomBar');
        document.title = '我的佣金'
        window.onscroll = ()=>{
            if(self.$route.path == '/commission'){
                self.endReached();
            }
        }
        this.getUser();
        this.getLogs();
        this.getWc();
    },
    beforeDestroy(){
        
    },
    methods:{
        getLogs(){
            let page = this.page;
            this.$http.get('user/listWithdrawalCommission',{params:{page: page}}).then((res)=>{
                
                if (page == 1) {
                    self.logs = res.data;
                } else if(res.data.data.length){
                    self.logs.data = self.logs.data.concat(res.data.data);
                }
            }).finally(()=>{
                self.isLoading = false;
            });
        },
        endReached(){
            let top = document.documentElement.scrollTop || document.body.scrollTop;
            //触底
            if(top + window.innerHeight  > document.getElementsByClassName('page-wrap')[0].offsetHeight - 90){
                if(this.isLoading){
                    return;
                }
                if(this.page >= this.logs.last_page ){
                    self.isNoMore = true;
                    return;
                }
                this.page++;
                this.isLoading = true;
                this.getLogs();
            }
        },
        qrcodeUploaded(files){
            this.wcForm.qr_code_url = files[0]
        },
        showCommissionModal() {
            if(this.user.commission <= 0)
            {
                this.$toast.failed('没有可以提现的佣金');
                return;
            }
            if(!this.wcInfo.ali_code_url && !this.wcInfo.wx_code_url){
                this.$toast.failed('请先设置提现收款码');
                return;
            }

            this.form = {
                "type": 0,
                'qr_code_url': '',
                'real_name':"",
                "commission":1
            };
            //默认选择支付宝
            if(this.wcInfo.ali_code_url){
                this.selectedWcType(1);
            }else{
                this.selectedWcType(2);
            }
            this.visible = true;
        },
        withdrawal() {
            this.$http.post('user/withdrawal', this.form).then((res) => {
                self.visible = false;
                self.getLogs()
                self.getUser();
                self.$toast.succeed(res.msg);
            });
        },
        changeType(item){
            this.selectedWcType(item.value)
        },
        selectedWcType(type){
            
            if(type  == 1){
                this.form.type = 1;
                this.form.qr_code_url = this.wcInfo.ali_code_url;
                this.form.real_name = this.wcInfo.ali_name;
            }else{
                this.form.type = 2;
                this.form.qr_code_url = this.wcInfo.wx_code_url;
                this.form.real_name = this.wcInfo.wx_name;
            }
        },
        getUser() {
            this.$http.get('user/getUserInfo', {params: {page: this.page}}).then((res) => {
                self.user = res.data;
            });
        },
        getWc(){
            this.$http.get('user/wcGet').then((res) => {
                self.wcInfo = res.data;
                self.wcOptions.push({
                    value: 1,
                    text: "支付宝",
                    brief: self.wcInfo.ali_name,
                })
                self.wcOptions.push({
                    value: 2,
                    text: "微信",
                    brief: self.wcInfo.wx_name,
                })
            });
        },

        showCwModal(type){
            this.wcVisible = true
            if(type == 1){
                //支付宝
                this.wcForm = {
                    type:1,
                    name:this.wcInfo.ali_name,
                    qr_code_url :this.wcInfo.ali_code_url
                }
            }else{
                //微信
                this.wcForm = {
                    type:2,
                    name:this.wcInfo.wx_name,
                    qr_code_url :this.wcInfo.wx_code_url
                }
            }
        },
        saveWc(){
            this.$http.post('user/wcSet', this.wcForm).then((res) => {
                self.$toast.succeed(res.msg);
                self.wcVisible = false;
                this.getWc()
            });
        },
        pickUp(e){
            if(e.target.files.length == 0){
                return;
            }
            let file = e.target.files[0];
            self.upload(file)
            e.target.value='';
        },
        upload(file){
            const formData = new FormData();
            formData.append('file', file);
            this.$http.post('user/uploadImg?upload_type=qrcode',formData).then((res)=>{
                self.wcForm.qr_code_url = res.data.path
            });
        },
    }
};
</script>

<style scoped lang='less' >
@import "../assets/reset.less";
.page-wrap{
    padding-bottom: 80px;
    padding-top: 80px;
    background: rgba(0, 0, 0, 0.01);
}

.price-stepper{
    height: auto;
    padding: 14px 0;
}

.form-tips{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
}
.stat-box{
    padding: 0.7rem;
    display: flex;
    background: #fff;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;
    line-height: 1.2;
}
.stat-box .valid-box{
    flex: 2;
    display: flex;
}
.stat-box .valid-box .item{
    flex: 1;
    text-align: center;
    position: relative;
}
.stat-box .valid-box .item::after{
    content: '';
    display: block;
    position: absolute;
    right: 0;
    width: 1px;
    height: 20px;
    top: calc(50% - 10px);
    background: rgba(0, 0, 0, 0.06);
}
.stat-box .valid-box .count{
    font-size: 32px;
    color: rgb(36, 69, 131);
}
.stat-box .valid-box .tag{
    font-size: 12px;
    border-radius: 10px;
    padding: 0 8px;
    color: #fff;
    background: rgb(36, 69, 131);
}
.stat-box .valid-box .item:last-child .count{
    color: rgba(36, 69, 131,0.7);
}
.stat-box .valid-box .item:last-child .tag{
    color: rgb(36, 69, 131);
    background: rgba(36, 69, 131,0.05);
}
.stat-box .exchange-btn{
    flex: 1;
    text-align: center;
    vertical-align: text-bottom;
    padding-top: 1.1rem;
    position: relative;
}
.stat-box .exchange-btn span{
    vertical-align: middle;
    display: inline-block;
}

.stat-box .exchange-btn svg{
    height: 1rem;
    vertical-align: middle;
}

.log-item{
    background: #fff;
    padding: 0.7rem;
    border-radius: 10px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.05), 0 5px 10px rgba(0, 0, 0, 0.02), 0 1px 3px rgba(0, 0, 0, 0.01);
    margin: 0.5rem 0.7rem;
}
.log-item .date{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    border-bottom: 1px dashed rgba(0, 0, 0, 0.06);
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
}
.log-item .desc{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    border-top: 1px dashed rgba(0, 0, 0, 0.06);
    padding-top: 7px;
    margin-top: 7px;
}
.log-item .info{
    display: flex;
    line-height: 24px;
    font-size: 14px;
}
.log-item .commission{
    flex: 1;
    text-align: right;
}
.log-item .commission .value{
    font-size: 17px;
    font-weight: 500;
    color: @primary-color;
    line-height: 20px;
}
.log-item .content{
    flex: 2;
}
.bottom-box{
    display: flex;
    padding: 5px;
    position: fixed;
    left: 0;
    bottom: 10px;
    width: 100%;
}
.bottom-box .md-button{
    margin: 5px;
}

.qrcode-img{
    height: 50px;
}

.notify-tips{
        background: rgba(37, 198, 252, 0.1);
        padding: 10px;
        border-radius: 10px;
        font-size: 12px;
        margin-top: 14px;
    }
</style>
